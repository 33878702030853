<template>
    <div class="h-full w-full flex justify-content-center align-items-center">
        <div class="flex flex-column justify-content-center align-items-center text-500">
            <div class="mb-4">
                <i :class="icon" style="font-size: 5rem"></i>
            </div>
            <div class="w-25rem text-center">
                {{ textoNotFound }}
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        icon: {
            type: String
        },
        textoNotFound: {
            type: String
        },
    },
}
</script>
