export function tabelaReservaExpose(
    iniciarLoadingFuncionario,
    validarConfigFuncionario,
    getProcedimentosFromSoc,
    retirarFuncionarioDaTela,
    finalizarLoadingFuncionario
) {
    return {
        iniciarLoadingFuncionario,
        validarConfigFuncionario,
        getProcedimentosFromSoc,

        retirarFuncionarioDaTela,
        finalizarLoadingFuncionario
    };
}

export const tabelaReservaExamesPrestadorColumns = [
    {
        field: 'procedimentos',
        header: 'Procedimentos',
        style: 'width: 27%'
    },
    {
        field: 'prestador',
        header: 'Onde / Prestador',
        style: 'width: 28%'
    },
    {
        field: 'antecedencia',
        header: 'Antecedência',
        style: 'width: 10%'
    },
    {
        field: 'data',
        header: '<i class="pi pi-calendar font-semibold"></i> Data',
        style: 'width: 10%; min-width: 105px'
    },
    {
        field: 'hora',
        header: '<i class="pi pi-clock font-semibold"></i> Hora',
        style: 'width: 25%; min-width: 212px'
    }
];
