export function GestaoMedicaPrestador() {
    return [
        {
        id: 'step0',
        text:'<h3>Bem-vindo a tela de prestadores!</h3></br> A partir desse guia iremos mostrar as principais funções!',
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step6',
        text: `Aqui, você pode filtrar a busca por prestadores que se encontram com o contrato <b>ativo</b> ou <b>inativo</b>`,
        attachTo: { element: document.querySelector('.step6'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step7',
        text: `Filtre a busca a partir dos que se encontram com o depósito antecipado <b>ativo</b> ou <b>inativo</b>`,
        attachTo: { element: document.querySelector('.step7'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
    {
        id: 'step8',
        text: `Refine a pesquisa filtrando pelas cidades atendidas pelo prestador. `,
        attachTo: { element: document.querySelector('.step8'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step9',
        text: `Filtre a busca pelo prestador selecionando entre:<b>nome</b>, <b>exames</b>, <b>código</b> e <b>código SOC</b> `,
        attachTo: { element: document.querySelector('.step9'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step10',
        text: `Aqui você pode pesquisar por um <b>prestador</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
        attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step11',
        text: `Clique aqui para aplicar todos os filtros de busca selecionados anteriormente`,
        attachTo: { element: document.querySelector('.step11'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step3',
        text: `Para cadastrar um novo prestador, utilize o botão <b>+Novo</b>.`,
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: '<h5>Acompanhe todos os prestadores a partir da listagem.</h5> <p> Neste item, você encontrará a tabela de prestadores, identificados pelo nome da empresa cliente. <p> ',
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step5',
        text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de um prestador, nesta ação é possível:</p>
        <p><b>Alterar:</b> Alterar os dados do cadastro de um prestador.<p>
        <p><b>Excluir:</b> Excluir o cadastro de um prestador.<p>
        <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico. <p>
        <p><b>Horário de Atendimento:</b> Permite configurar o horário de atendimento de forma personalizada de acordo com a disponibilidade de cada prestador.<p>
        <p><b>Prestador Procedimento:</b> Tem a finalidade de informar qual exame o prestador atende, além dos respectivos valores do
        exame que será realizado.<p>
        <p><b>Prestador Procedimento Cliente:</b> Define o valor dos procedimentos para um cliente, funcionando como uma listagem de
        clientes atendidos pelo prestador em questão.<p>
        <p><b>Férias Coletiva Prestador:</b> Trata-se de um cadastro de férias para um período determinado pelo prestador. <p>
        <p><b>Não Conformidade:</b> Refere-se a uma situação em que o prestador de serviços não cumpre com
        determinados procedimentos ou padrões estabelecidos para o seu desempenho. <p>
        <p><b>Anexos:</b> Esta função visualiza os anexos na tela de prestador.<p>`,
        attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    }
    
]
}