<template>
    <div>
        <Button size="large" icon="pi pi-question-circle" class="p-button-rounded p-button-link custom-button"
            v-tooltip="computedTooltip" @click="openDialogPdfInfo" />
        <AppPdfInfoDialog ref="dialogPdfInfo">
        </AppPdfInfoDialog>
    </div>
</template>

<script>
import BaseService from '../services/BaseService';

export default {
    props: {
        tooltip: {
            type: String

        },
        nomeTelaDoManual: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            manualSistemaService: null
        }
    },
    created() {
        this.manualSistemaService = new BaseService('manuais-sistema');
    },
    computed: {
        computedTooltip() {
            return this.tooltip ? (this.nomeTelaDoManual ? `${this.tooltip}. Clique para ver o manual.` : this.tooltip) : '';
        }
    },
    methods: {
        openDialogPdfInfo() {
            if (!this.nomeTelaDoManual) {
                return;
            }

            this.$refs.dialogPdfInfo.open(this.nomeTelaDoManual);
        }
    }
};
</script>

<style>
.custom-button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;
    overflow: hidden;
}

.custom-button .pi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    width: 1.5rem;
    height: 1.5rem;
}
</style>
