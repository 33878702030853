import { getClientAreaMedica } from './http';

export default class AreaMedicaService {
    constructor(path) {
        this.path = path;
    }

    findAll({ page = 1, limit = 10, filter = '', sort = '', ...otherParams }) {
        return getClientAreaMedica().get(this.path, {
            params: {
                page,
                limit,
                filter,
                sort,
                ...otherParams
            }
        });
    }

    findById(id) {
        return getClientAreaMedica().get(`${this.path}/${id}`);
    }

    findCountFiltrosPainelAgendamentos(filtrosExtras) {
        return getClientAreaMedica().get(`${this.path}/count-filtros-painel-agendamentos`, {
            params: {
                ...filtrosExtras
            }
        });
    }

    findCountAgendamentosComObservacaoPrestador() {
        return getClientAreaMedica().get(`${this.path}/agendamentos-com/observacao-prestador`);
    }

    findDadosAgendamentosComObservacaoPrestador(parameters) {
        return getClientAreaMedica().get(`${this.path}/agendamentos-com/observacao-prestador/dados`, {
            params: {
                ...parameters
            }
        });
    }

    patchAceitarObservacaoPrestador(arrayIds) {
        return getClientAreaMedica().post(`${this.path}/agendamentos-com/observacao-prestador/aceitar`, { ids: arrayIds });
    }

    save(record) {
        if (record.id) {
            return getClientAreaMedica().patch(`${this.path}/${record.id}`, record);
        }
        return getClientAreaMedica().post(this.path, record);
    }

    remove(id) {
        return getClientAreaMedica().delete(`${this.path}/${id}`);
    }
}
