<template>
    <Calendar
        id="value"
        ref="calendar"
        v-model="value"
        :class="{ 'p-invalid': required && submitted && !value }"
        :disabledDays="disabledDays"
        :minDate="minDate"
        :maxDate="maxDate"
        :showIcon="true"
        autocomplete="off"
        dateFormat="dd/mm/yy"
        :showOnFocus="showOnFocus"
        placeholder="Range de datas"
        selectionMode="range"
        v-bind="$attrs"
        showButtonBar
        @date-select="verificarDatas"
        @focus="verificarPrestadorNaoPossuiHorario"
    />

    <small v-if="submitted && !value" class="p-error"> {{ requiredText }}</small>
</template>
<script>
import { getClientBase } from '@/services/http';
import dayjs from 'dayjs';
import { PrestadorAgendamento } from '../prestador/calendario-prestador-context/PrestadorAgendamento';
import { CalendarRangeSLA } from '../../pages/gestao-medica-v3/agendamentos/criar-agendamento/steps/utils/calendarRangeSLA';
import { verificaDiaNaoAtendidoEntre } from '../../utils/CalendarHorarioComercial';

export default {
    name: 'AppInputCalendarioRanged',
    props: {
        modelValue: {
            type: Array
        },
        required: {
            type: Boolean,
            default: false
        },
        submitted: {
            type: Boolean,
            default: false
        },
        idCidade: {
            type: Number
        },
        idPrestador: {
            type: Number
        },
        periodo: {
            type: Number
        },
        requiredText: {
            type: String,
            default: 'Preenchimento Obrigatório'
        },
        disabledDays: {
            type: Array,
            default: () => [0, 6]
        },
        disableManualInput: {
            type: Boolean,
            default: true
        },
        showOnFocus: {
            type: Boolean,
            default: false
        },
        minDate: {
            type: Date,
            default: null
        },
        maxDate: {
            type: Date,
            default: null
        },
        limiteDatas: {
            type: Number,
            default: null
        }
    },
    emits: ['update:modelValue', 'onGetPeriodoManhaVisible'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        value() {
            this.verificarDatas();
        },
        idCidade() {
            this.verificarDatas();
        },
        idPrestador() {
            this.verificarDatas();
        },
        periodo() {
            this.verificarDatas();
        }
    },
    methods: {
        async verificarDatas() {
            if (!this.value || !this.value[1]) return;
            return (
                (await this.verificarRangeDeDatasValido()) &&
                (await this.verificarFeriadoNacional()) &&
                (await this.verificarFeriadoMunicipal()) &&
                (await this.verificarFeriasColetiva()) &&
                (await this.verificarPeriodoDeAgendamento()) &&
                (await this.verificarPrestadorNaoPossuiHorario()) &&
                (await this.verificarNaoSelecionouDisabledDays())
            );
        },
        async verificarPeriodoDeAgendamento() {
            const dataAtual = dayjs();
            const dataInicial = dayjs(this.value[0]);
            const dataFinal = dayjs(this.value[1]);

            const dataInicialEHoje = dataInicial.isSame(dataAtual, 'day');
            const dataFinalEHoje = dataFinal.isSame(dataAtual, 'day');
            const periodoAtualETarde = dataAtual.format('HH:mm') > '12:00';

            if (dataInicialEHoje && dataFinalEHoje && periodoAtualETarde) {
                await this.$emit('onGetPeriodoManhaVisible', false);
            } else {
                await this.$emit('onGetPeriodoManhaVisible', true);
            }

            if (dataInicial.isBefore(dataAtual, 'day')) {
                this.$toast.add({
                    severity: 'warn',
                    summary: ' A data inicial não pode ser menor que a data atual!',
                    life: 3000
                });
                this.value = [];
                return false;
            }
            return true;
        },
        async verificarFeriadoMunicipal() {
            if (!this.idCidade || !this.value[1]) return true;
            const path = `/feriados/municipal/${this.idCidade}`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value[0],
                    dataFinal: this.value[1],
                    periodo: this.periodo
                }
            });
            const feriado = data;
            if (feriado.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, ' +
                        dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                        ' é feriado ' +
                        feriado[0].abrangencia.toLowerCase() +
                        ' em ' +
                        feriado[0].cidade.cidade +
                        '-' +
                        feriado[0].cidade.estado +
                        '!',
                    life: 5000
                });
                this.value = [];
                return false;
            }
            return true;
        },
        async verificarFeriadoNacional() {
            if (!this.value[1] || !this.periodo) return true;
            const path = `/feriados/nacional`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value[0],
                    dataFinal: this.value[1],
                    periodo: this.periodo
                }
            });
            const feriado = data;
            if (feriado.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, ' +
                        dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                        ' é feriado ' +
                        feriado[0].abrangencia.toLowerCase() +
                        '!',
                    life: 5000
                });
                this.value = [];
                return false;
            }
            return true;
        },
        async verificarFeriasColetiva() {
            if (!this.idPrestador || !this.periodo) return true;
            const path = `/ferias/prestador/verificar/${this.idPrestador}`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value[0],
                    dataFinal: this.value[1],
                    periodo: this.periodo
                }
            });
            const ferias = data;
            if (ferias.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, o Prestador entrará em Férias no dia ' +
                        dayjs(ferias[0].dataInicial).add(3, 'hour').format('DD/MM/YYYY') +
                        ' e retornará no dia ' +
                        dayjs(ferias[0].dataFinal).add(3, 'hour').format('DD/MM/YYYY') +
                        '.',
                    life: 6000
                });
                this.value = [];
                return false;
            }
            return true;
        },
        async verificarRangeDeDatasValido() {
            const rangeSLA = new CalendarRangeSLA(this.value, this.disabledDays);
            const mensagemErro = await rangeSLA.isRangeDeDatasInvalido(this.limiteDatas);

            if (mensagemErro) {
                this.$toast.add({
                    severity: 'warn',
                    summary: mensagemErro,
                    life: 5000
                });
                const anoExibido = this.$refs.calendar.currentYear;
                const mesExibido = this.$refs.calendar.currentMonth;
                this.value = [];

                this.$nextTick(() => {
                    this.$refs.calendar.currentYear = anoExibido;
                    this.$refs.calendar.currentMonth = mesExibido;
                });
                return false;
            }
            return true;
        },
        async verificarNaoSelecionouDisabledDays() {
            const [inicio, fim] = this.value;
            if (!inicio || !fim) return true;

            const nomeDiaNaoAtendido = verificaDiaNaoAtendidoEntre(inicio, fim, this.disabledDays, this.$primevue);

            if (nomeDiaNaoAtendido) {
                this.$toast.add({
                    severity: 'warn',
                    summary: `Por favor selecione outro período de datas, o prestador não atende ${nomeDiaNaoAtendido} !`,
                    life: 5000
                });
                this.value = [];
                return false;
            }
            return true;
        },
        async verificarPrestadorNaoPossuiHorario() {
            return new PrestadorAgendamento(this.$toast).verificarPrestadorNaoPossuiHorario(null, this.disabledDays);
        }
    }
};
</script>
