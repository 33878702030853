<template>
    <DialogIniciarAutoagendamento v-model="dialogIniciarAutoagendamento" :agendamento="slotProps" @loadAppointments="emit('loadAppointments')" />
    <div class="p-panel p-component">
        <div :style="panelHeaderStyle(slotProps)" class="p-panel-header">
            <span class="p-panel-title">Detalhes</span>
        </div>
        <div class="p-toggleable-content">
            <AppLoadingWrapper v-if="loading && loadingDadosProcedimentos" style="height: 200" />
            <div v-if="!loading" class="p-panel-content">
                <div class="grid">
                    <div class="col-5">
                        <div class="field">
                            <b>Unidade: </b>
                            {{ slotProps?.unidade?.name ? slotProps.unidade.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Setor: </b>
                            {{ slotProps?.setor?.name ? slotProps.setor.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Cargo: </b>
                            {{ slotProps?.cargo?.name ? slotProps.cargo.name : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Telefone do Funcionário: </b>
                            {{ $filters.phone(slotProps.telefoneFuncionario) }}
                        </div>
                    </div>
                    <div class="col-7">
                        <div v-if="slotProps.observacao" class="field">
                            <b>Observação: </b>
                            {{ slotProps.observacao }}
                        </div>

                        <div class="field">
                            <b>Data de sugestão: </b> entre {{ $filters.formatDateOnly(slotProps.dataSugestaoInicial) }} e
                            {{ $filters.formatDateOnly(slotProps.dataSugestaoFinal) }} no periodo
                            {{ getLabelPeriodo(slotProps.periodo).label }}
                        </div>
                        <div class="field">
                            <b>Riscos: </b>
                            {{ !dadosRiscos?.length ? 'Agendamento sem riscos' : dadosRiscos }}
                        </div>
                        <div class="field">
                            <b>Cliente/CNPJ: </b>
                            {{ slotProps?.cliente.name ?? 'Nome não informado' }} /
                            {{ slotProps?.cliente.inscricao == '' ? 'CNPJ não informado' : slotProps?.cliente.inscricao }}
                        </div>
                        <div class="field">
                            <b>Unidade/CNPJ: </b>
                            {{ slotProps?.unidade.name ?? 'Não informado' }} /
                            {{ slotProps?.unidade.tradeCode ?? 'CNPJ não informado' }}
                        </div>
                    </div>

                    <template v-if="dadosProcedimento">
                        <Divider align="center"> </Divider>
                        <DataTable
                            :value="dadosProcedimento"
                            dataKey="dadosProcedimento.id"
                            responsiveLayout="scroll"
                            style="width: 100%; padding: 1%"
                        >
                            <Column field="procedimentos.name" header="Procedimento"></Column>
                            <Column header="Data Agendamento">
                                <template #body="{ data }">
                                    {{ data.dataAgendamento ? $filters.formatDate(data.dataAgendamento) : 'Em processo de agendamento' }}
                                </template>
                            </Column>
                            <Column header="Prestador">
                                <template #body="{ data }">
                                    {{ data.prestador ? data.prestador.name : 'Em processo de agendamento' }}
                                </template>
                            </Column>
                            <Column header="Endereço do Prestador">
                                <template #body="{ data }">
                                    {{ !data.prestador ? 'Em processo de agendamento' : '' }}
                                    {{ data.prestador?.street ? 'Rua: ' + data.prestador.street : '' }}
                                    {{ data.prestador?.number ? ', ' + data.prestador.number : '' }}
                                    {{ data.prestador?.neighborhood ? ' Bairro: ' + data.prestador.neighborhood : '' }}
                                    <br />
                                    {{ data.prestador?.addressComplement ? ' Complemento: ' + data.prestador.addressComplement : '' }}
                                    {{ data.prestador?.zipCode ? ' CEP: ' + data.prestador?.zipCode : '' }}
                                    {{
                                        data.prestador?.city?.cidade && data.prestador?.city?.estado
                                            ? 'Cidade: ' + data.prestador.city.cidade + ' - ' + data.prestador?.city?.estado
                                            : ''
                                    }}
                                </template>
                            </Column>
                            <Column header="Recomendações">
                                <template #body="{ data }">
                                    {{ data.recommendation }}
                                </template>
                            </Column>
                        </DataTable>
                        <div class="field col">
                            <Button
                                v-if="slotProps.idStatus === enumStatusAgendamento.AUTOAGENDAMENTO"
                                v-tooltip.bottom="'Iniciar agendamento'"
                                class="p-button p-button-outlined mr-2"
                                icon="pi pi-play"
                                label="Iniciar"
                                @click="dialogIniciarAutoagendamento = true"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import { getClientBase } from '@/services/http';
import BaseService from '@/services/BaseService';
import DialogIniciarAutoagendamento from './DialogIniciarAutoagendamento';
import StatusAgendamento from '@/enums/StatusAgendamento';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['loadAppointments']);
const props = defineProps({
    slotProps: {
        type: Object,
        required: true
    },
    loading: {
        type: Boolean
    },
    hasError: {
        type: Boolean
    }
});

const $serviceDadosRiscos = new BaseService('/agendamento-riscos/agendamento');

const enumStatusAgendamento = StatusAgendamento;
const dialogIniciarAutoagendamento = ref(false);
const loadingDadosProcedimentos = ref(false);
const dadosProcedimento = ref(null);
const dadosRiscos = ref(null);
const toast = useToast();
const periodos = [
    { label: 'Manhã', value: 1 },
    { label: 'Tarde', value: 2 }
];

onMounted(() => {
    loadProcedimentos();
    loadRiscos();
});

async function loadProcedimentos() {
    loadingDadosProcedimentos.value = true;
    try {
        const { data } = await getClientBase().get(`/agendamento_procedimento/agendamento/${props.slotProps.id}`, {
            params: { origem: 'NOVAGESTAOMEDICA_AGENDAMENTOS_INDEX_INDEXEXPANSION_LOADPROCEDIMENTOS' }
        });
        dadosProcedimento.value = data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message,
            life: 3000
        });
    }
    loadingDadosProcedimentos.value = false;
}
async function loadRiscos() {
    try {
        const { data } = await $serviceDadosRiscos.findById(props.slotProps.id);
        dadosRiscos.value = data?.map((r) => r.risco.nome)?.toString();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message,
            life: 3000
        });
    }
}
function panelHeaderStyle(data) {
    return {
        'background-color': `var(--${changeColorTone(data.status.primevueColor, 100)})`,
        color: `var(--${changeColorTone(data.status.primevueColor, 900)})`
    };
}
function getLabelPeriodo(value) {
    const periodo = periodos.find((p) => p.value === value);
    return periodo || { label: 'Não informado' };
}
function changeColorTone(primevueColor, newTone) {
    if (!primevueColor) return;
    const [color, tone] = primevueColor.split('-');
    return color + '-' + (newTone || tone);
}
</script>
