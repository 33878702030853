<template>
    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
    <div :style="loading ? 'opacity: 30%;' : ''">
        <div class="card p-fluid w-full">
            <slot name="content"></slot>
        </div>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div class="formatarTamanho flex flex-column md:flex-row md:justify-content-end md:ml-auto">
                <Button
                    v-if="!editing"
                    :disabled="editDisabled"
                    label="Editar registro"
                    class="p-button p-button-primary mr-0 mt-3 mb-2 md:mr-3 md:mt-0 md:mb-0"
                    @click="habilitarEdicao"
                />
                <Button
                    v-else
                    label="Cancelar edição"
                    class="p-button p-button-danger mr-0 mt-3 mb-2 md:mr-3 md:mt-0 md:mb-0"
                    @click="cancelarEdicao"
                />
                <AppBackButton :path="backPath" class="mr-0 mt-3 mb-2 md:mr-3 md:mt-0 md:mb-0" />
                <Button
                    v-if="!loading"
                    label="Salvar"
                    :loading="loadingSalvar"
                    :disabled="disableSave"
                    icon="pi pi-check"
                    class="p-button p-button-primary"
                    @click="save"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits: ['onBeforeSave', 'onAfterSave', 'onLoadDataInsert', 'onLoadDataEdit', 'onError', 'onValidate', 'onHabilitarEdicao', 'onCancelarEdicao'],
    props: {
        service: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        },
        disableSave: {
            type: Boolean,
            required: false
        },
        backPath: {
            type: String
        },
        activateRecord: {
            type: Boolean,
            default: true
        },
        permission: {
            type: String
        },
        lastPage: {
            type: Boolean,
            default: false
        },
        isNested: {
            type: Boolean,
            default: false
        },
        origem: {
            type: String,
            required: false
        },
        personalBackPath: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            loadingSalvar: false,
            editing: false,
            record: null
        };
    },
    mounted() {
        this.$service = this.service;
        this.loadData();
    },
    computed: {
        editDisabled() {
            return !this.$route.params.id;
        }
    },
    methods: {
        async save() {
            try {
                this.loadingSalvar = true;
                await this.$emit('onBeforeSave');

                const isValid = await this.isValid();
                if (isValid === false) {
                    this.loadingSalvar = false;
                    return;
                }
                const response = await this.$service.save(this.form);
                this.loadingSalvar = false;
                await this.$emit('onAfterSave', response);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro salvo com sucesso!',
                    life: 3000
                });
                this.editing = false;
                this.pushToRoute(response.data.id);
            } catch (err) {
                this.loadingSalvar = false;
                await this.$emit('onError', err);
                const message = this.getErrorMessage(err);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar registro! ' + message,
                    life: 3000
                });
            }
        },
        pushToRoute(id) {
            if (this.personalBackPath) {
                this.$router.push(this.personalBackPath);
                return;
            }
            if (this.lastPage) {
                this.$router.push(this.backPath);
                return;
            }

            if (!this.isNested) {
                this.$router.push(`${this.backPath}/${id}`);
                return;
            }

            this.$router.push(`${this.backPath}/${this.form.idOrigem}`);
        },
        onClickActivateRecord() {
            this.onClickCloseActivateRecord();
            this.loadData();
        },
        onClickCloseActivateRecord() {
            this.showActivateRecordDialog = false;
        },
        onClickInactivateRecord() {
            this.onClickCloseInactivateRecord();
            this.loadData();
        },
        onClickCloseInactivateRecord() {
            this.showInactivateRecordDialog = false;
        },
        async isValid() {
            let valid = true;

            await this.$emit(
                'onValidate',
                () => {
                    valid = true;
                },
                () => {
                    valid = false;
                }
            );

            return valid;
        },
        async loadData() {
            if (this.$route.params.id) {
                this.loadDataEdit();
                return;
            }

            this.loadDataInsert();
        },

        async loadDataInsert() {
            await this.$emit('onLoadDataInsert');
        },

        async loadDataEdit() {
            try {
                this.loading = true;

                const params = {
                    origem: this.origem,
                    idOrigem: this.$route.params.id
                };
                const { data } = !this.isNested
                    ? await this.$service.findById(this.$route.params.id)
                    : await this.$service.findByOrigem({ filter: params }, this.origem);

                this.loading = false;
                this.record = JSON.parse(JSON.stringify(data));
                await this.$emit('onLoadDataEdit', data);
            } catch (error) {
                this.loading = false;
            }
        },
        getErrorMessage(err) {
            if (Array.isArray(err?.response?.data?.details?.response?.message)) {
                return err.response.data.details.response.message.join();
            }
            return err?.response?.data?.message;
        },
        habilitarEdicao() {
            this.editing = true;
            this.$emit('onHabilitarEdicao');
        },
        cancelarEdicao() {
            this.$emit('onCancelarEdicao', this.record);
            this.editing = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.formatarTamanho {
    height: 35px;
}
</style>
