<template>
    <Calendar
        v-bind="$attrs"
        id="value"
        @date-select="verificarDatas"
        v-model="value"
        dateFormat="dd/mm/yy"
        :showIcon="true"
        placeholder="Selecione uma data"
        :class="{ 'p-invalid': required && submitted && !value }"
        autocomplete="off"
        :disabledDays="[0, 6]"
    />
    <small class="p-error" v-if="submitted && !value"> {{ requiredText }}</small>
</template>
<script>
import { getClientBase } from '@/services/http';
import dayjs from 'dayjs';
import EnumPeriodo from '../../enums/EnumPeriodo';

export default {
    name: 'AppInputCalendarioSingle',
    props: {
        modelValue: {
            type: Date
        },
        required: {
            type: Boolean,
            default: false
        },
        submitted: {
            type: Boolean,
            default: false
        },
        idCidade: {
            type: Number
        },
        idPrestador: {
            type: Number
        },
        horarioAgendamento: {
            type: String
        },
        requiredText: {
            type: String,
            default: 'Preenchimento Obrigatório'
        }
    },
    emits: ['update:modelValue', 'onChange'],
    data() {
        return {
            periodo: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        value() {
            this.verificarDatas();
            this.$emit('onChange');
        },
        idCidade() {
            this.verificarDatas();
        },
        idPrestador() {
            this.verificarDatas();
        }
    },
    methods: {
        async calcularPeriodo() {
            const horaCortada = this.horarioAgendamento.split(':');
            const onlyHora = parseInt(horaCortada[0]);
            if (!onlyHora) return;
            const isManha = onlyHora < 12;
            if (isManha) {
                this.periodo = EnumPeriodo.MANHA;
                return true;
            }
            this.periodo = EnumPeriodo.TARDE;
            return true;
        },
        async verificarDatas() {
            if (!this.value) return;
            return (
                (await this.calcularPeriodo()) &&
                (await this.verificarFeriadoMunicipal()) &&
                (await this.verificarFeriadoNacional()) &&
                (await this.verificarFeriasColetiva()) &&
                (await this.verificarPeriodoDeAgendamento()) &&
                (await this.$emit('onChange'))
            );
        },
        async verificarPeriodoDeAgendamento() {
            const dataAtual = dayjs();
            const dataSelecionada = dayjs(this.value);
            const dataAtualSemHoras = dayjs(dataAtual).hour(0).minute(0).second(0).millisecond(0);
            if (dataSelecionada.isSame(dataAtualSemHoras) && dataAtual.$d.getHours() > 12) {
                this.disableMorning = false;
            }
            const menor = dataSelecionada.isBefore(dataAtual, 'day');
            if (menor) {
                this.$toast.add({ severity: 'warn', summary: ' A data inicial não pode ser menor que a data atual!', life: 3000 });
                this.value = null;
                return false;
            }
            return true;
        },
        async verificarFeriadoMunicipal() {
            if (!this.idCidade || !this.value) return true;
            const path = `/feriados/municipal/${this.idCidade}`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value,
                    dataFinal: this.value,
                    periodo: this.periodo
                }
            });
            const feriado = data;
            if (feriado.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, ' +
                        dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                        ' é feriado ' +
                        feriado[0].abrangencia.toLowerCase() +
                        ' em ' +
                        feriado[0].cidade.cidade +
                        '-' +
                        feriado[0].cidade.estado +
                        '!',
                    life: 5000
                });
                this.value = null;
                return false;
            }
            return true;
        },
        async verificarFeriadoNacional() {
            if (!this.value || !this.periodo) return true;
            const path = `/feriados/nacional`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value,
                    dataFinal: this.value,
                    periodo: this.periodo
                }
            });
            const feriado = data;
            if (feriado.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, ' +
                        dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                        ' é feriado ' +
                        feriado[0].abrangencia.toLowerCase() +
                        '!',
                    life: 5000
                });
                this.value = null;
                return false;
            }
            return true;
        },
        async verificarFeriasColetiva() {
            if (!this.idPrestador || !this.periodo) return true;
            const path = `/ferias/prestador/verificar/single/${this.idPrestador}`;
            const { data } = await getClientBase().get(path, {
                params: {
                    dataInicial: this.value,
                    periodo: this.periodo
                }
            });
            const ferias = data;
            if (ferias.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Por favor selecione outro período de datas, o Prestador entrará em Férias no dia ' +
                        dayjs(ferias[0].dataInicial).add(3, 'hour').format('DD/MM/YYYY') +
                        ' e retornará no dia ' +
                        dayjs(ferias[0].dataFinal).add(3, 'hour').format('DD/MM/YYYY') +
                        '.',
                    life: 6000
                });
                this.value = null;
                return false;
            }
            return true;
        }
    }
};
</script>
