<template>
    <div v-for="notificacao in notificacoesList" :key="notificacao.id" class="notificacao-bloco"
        :style="notificacaoBloco(notificacao.lida)">
        <div class="flex flex-row">
            <div>
                <div class="flex col flex-col p-0 m-0">
                    <div class="user-notificacao overflow-hidden" v-tooltip="notificacao.titulo">
                        {{ notificacao.titulo }}
                    </div>
                    <small class="data-nofiticacao">
                        {{ $filters.formatDate(notificacao.dataCriacao) }}
                    </small>
                    <Button icon="pi pi-times" class="p-button-rounded p-button-text btn-menu p-0 m-0"
                        @click.stop="excluirNotificacao(notificacao._id)" />
                </div>
                <div class="coment">{{ notificacao.dados.descricao }}</div>
            </div>
        </div>
    </div>
</template>
<script>

import { getClientMicroserviceNotificacao } from '@/services/http';

export default {
    props: {
        notificacoes: {
            type: Object
        },
    },
    emits: ['onExcluir', 'qntNotificacoes'],
    computed: {
        notificacoesList() {
            return this.notificacoes;
        }
    },
    methods: {
        async excluirNotificacao(id) {
            try {
                await getClientMicroserviceNotificacao().delete(`/notificacoes/${id}`);
                this.$emit('onExcluir', id);
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao excluir a notificação!', life: 3000 });
            }
        },
        notificacaoBloco(lida) {
            if (lida === false) {
                return {
                    'background': '#e9e9ff',
                    'border-radius': '10px',
                    'border-left': 'solid #373af8',
                    'border-width': '5px',
                    'cursor': 'pointer',
                }
            }

        },
    }
}
</script>
<style lang="scss" scoped>
.notificacao-bloco {
    background-color: #ffffff;
    border-radius: 10px;
    border-left: solid #f4f4f4;
    border-width: 5px;
    padding: 5px;
    margin-right: 3px;
    margin-bottom: 7px;
    cursor: pointer;
    transition-property: background-color, border-color, color;
    transition-duration: 500ms;
}

.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 36px;
    height: 36px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.notificacao-avatar {
    top: 10px;
}

.data-nofiticacao {
    font-size: 9px;
    display: inline-block;
    transition: 250ms linear all;
}

.user-notificacao {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    width: 105px;
    overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn-menu {
    color: #9aa5ae;
    position: relative;
    height: 12px !important;
    width: 18px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.coment {
    font-size: 11px;
}
</style>
import { getClientMicroserviceNotificacao } from '../../../services/http';
