export function BaseImportacaoCadastroEstrutura() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de importação cadastro estrutura!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por uma <b>importação de cadastro e estrutura</b> buscando pelo nome do cliente vinculado a importação e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }, 
         { 
            id: 'step3',
            text: `Para criar uma nova importação de cadastro e estrutura, utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        } ,
        {
            id: 'step4',
            text: `<h5>Acompanhe todos os processos de importação de cadastro e estrutura a partir da listagem.</h5>
            <p> Neste item, você encontrará uma tabela com as importações realizadas, contendo todas as informações relevantes a respeito do processo de sincronização dos cadastros. `,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar uma importação cadastrada, nesta ação é possível:</p>
            <p><b>Alterar:</b> Alterar os dados do cadastro de uma importação de cadastro e estrutura.<p>
            <p><b>Excluir:</b> Excluir o cadastro de uma importação.`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ]
}
