<template>
    <Dialog :visible="!!props.token" :closable="false" modal style="width: 400px">
        <div class="w-full flex flex-column align-items-center text-center px-4 mb-3">
            <div class="bg-primary w-min border-circle p-3 mb-4">
                <i class="pi pi-check" style="font-size: 40px; font-weight: bolder"></i>
            </div>
            <span class="font-medium text-xl text-600 mb-4">Seu link foi enviado com sucesso para o funcionário</span>
            <span class="font-medium text-xl text-600 mb-2">Reenviar link</span>
            <div class="border-2 border-200 border-round-md text-left w-full p-4">
                <label for="link"> Link para acesso </label>
                <div class="flex w-full mb-5">
                    <InputText :value="link" class="mt-2 w-full" id="link" readonly type="text" />
                    <i
                        class="pi pi-external-link bg-primary border-round-right px-2 -ml-1 mt-2 cursor-pointer"
                        style="font-size: 16px; font-weight: bolder; padding: 9px"
                        @click="abrirLink()"
                    />
                </div>
                <label for="token"> Token para acesso </label>
                <div class="flex w-full">
                    <InputText :value="props.token" class="mt-2 w-full" id="token" readonly type="text" />
                    <i
                        class="pi pi-link bg-primary border-round-right px-2 -ml-1 mt-2 cursor-pointer"
                        style="font-size: 16px; font-weight: bolder; padding: 9px"
                        @click="copiarParaAreaDeTranferencia()"
                    />
                </div>
            </div>

            <Button class="mt-6 px-8 py-3" label="Concluir" @click="$emit('update:token', '')" />
        </div>
    </Dialog>
</template>
<script setup>
import { defineProps } from 'vue';
import { useToast } from 'primevue/usetoast';
import { showError, showSuccess } from '@/utils/Toast';

const props = defineProps({
    token: {
        type: String,
        required: true
    }
});

const link = `${process.env.VUE_APP_WEB}#/autoagendamento`;
const toast = useToast();

function copiarParaAreaDeTranferencia() {
    navigator.clipboard.writeText(props.token).then(
        () => showSuccess(toast, `Token copiado!`),
        () => showError(toast, `Erro ao tentar copiar o token`)
    );
}
function abrirLink() {
    if (link) window.open(link);
}
</script>
