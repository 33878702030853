<template>
    <div>
        <div class="grid">
            <div class="col-12">
                <div class="card p-fluid w-full">
                    <AppLoadingWrapper v-if="loading" />
                    <div class="formgrid grid" v-if="!loading">
                        <div class="field col-6">
                            <label for="Prestador">Liberar atendimento do prestador</label>
                            <DropdownPrestador
                                :disabled="!!prestador || loadingLiberarPrestador"
                                v-model="form.supplier"
                                :idValue="form.idPrestador"
                                :filtrosExtras="{ agruparPrestadores: true }"
                            />
                        </div>
                        <div class="field col-6">
                            <label for="cliente">Cliente</label>
                            <DropdownCliente
                                id="cliente"
                                :loading="loadingCliente"
                                :disabled="!!cliente || loadingCliente"
                                class="w-full"
                                v-model="form.customer"
                                :idValue="form.idCliente"
                            />
                        </div>
                    </div>
                    <ProgressBar v-if="loadingValores" mode="indeterminate" style="height: 0.3em" />
                    <DataTable
                        v-if="!loading"
                        :value="exams"
                        dataKey="id"
                        :paginator="true"
                        :rows="10"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[5, 10, 25]"
                        currentPageReportTemplate="Mostrando {last} de {totalRecords} registros"
                        responsiveLayout="scroll"
                    >
                        <template #header>
                            <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                                <h5 class="mb-2 md:m-0 p-as-md-center">Preços referentes aos exames</h5>
                            </div>
                        </template>
                        <Column field="procedimento.name" header="Exame" :sortable="true" style="min-width: 12rem" />
                        <Column :sortable="true" field="valorCobrar" header="Valor cobrado">
                            <template #body="slotProps">
                                {{ $filters.formatCurrency(slotProps.data.valorCobrar) }}
                            </template>
                        </Column>
                    </DataTable>
                    <div v-if="!loading" class="flex mt-5" style="justify-content: right">
                        <Button icon="pi pi-check" class="submit p-button-success w-3" label="Liberar" @click="save" :disabled="btnEstaCarregando" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import AreaService from '../../services/AreaMedicaService';
import EventBus from '../../event-bus';
import DropdownCliente from '../clientes/components/DropdownCliente.vue';
import DropdownPrestador from '../prestadores/components/DropdownPrestador';
import { getClientBase } from '@/services/http';
import { showError } from '../../utils/Toast';

export default {
    components: {
        DropdownPrestador,
        DropdownCliente
    },
    props: ['prestador', 'cliente'],
    data() {
        return {
            idCliente: null,
            exams: [],
            form: {},
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 5,
            sort: null,
            btnEstaCarregando: false,
            loading: false,
            loadingLiberarPrestador: false,
            loadingCliente: false,
            loadingValores: false
        };
    },
    watch: {
        'form.idPrestador'() {
            if (this.form.idPrestador) {
                this.getPrices();
            }
        },
        'form.customer'() {
            this.form.idCliente = this.form?.customer?.id;
        },
        'form.supplier'() {
            this.form.idPrestador = this.form?.supplier?.id;
        }
    },
    async mounted() {
        this.form.idPrestador = this.prestador;
        this.form.idCliente = this.cliente;
        this.$supplierService = new BaseService('suppliers');
        this.$clientService = new BaseService('customers');
        this.$branchService = new BaseService('customer/branches');
        this.$supplierClientService = new AreaService('prestador-clientes');
    },
    methods: {
        async getPrices() {
            try {
                this.loadingValores = true;
                this.loadingLiberarPrestador = true;
                const path = `/prestador-valor/supplier/${this.form.idPrestador}`;
                const { data } = await getClientBase().get(path, {
                    params: {
                        limit: this.perPage,
                        page: this.page,
                        filter: this.filter,
                        sort: this.sort
                    }
                });
                this.exams = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loadingValores = false;
                this.loadingLiberarPrestador = false;
            } catch (error) {
                this.loadingValores = false;
                this.loadingLiberarPrestador = false;
                showError(this.$toast, error);
            }
        },
        async save() {
            try {
                this.btnEstaCarregando = true;
                await this.$supplierClientService.save(this.form);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Prestador liberado com sucesso',
                    life: 3000
                });
                if (this.$route.query.prestador) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Prestador liberado com sucesso',
                        life: 3000
                    });
                }
                EventBus.emit('prestador-liberado', this.form.idPrestador);
            } catch (err) {
                showError(this.$toast, err);
            } finally {
                this.btnEstaCarregando = false;
            }
        }
    }
};
</script>
