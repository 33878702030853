<template>
    <Dialog :style="{ width: '450px' }" class="p-fluid" header="Envio de WhatsApp" :closable="false" :modal="true">
        <div class="grid" v-if="showCampos">
            <div class="col-12">
                <label>Telefone</label>
                <InputMask id="telefone" v-model="telefone" type="text" mask="(99) 99999-9999"
                    placeholder="Informe o número do WhatsApp" :class="{ 'p-invalid': submitted && !telefone }" />
                <small class="p-error" v-if="submitted && !telefone">Campo obrigatório.</small>
            </div>
            <div class="col-12">
                <label>Corpo da mensagem</label>
                <Textarea type="mensagem" v-model="mensagem" placeholder="Informe a mensagem a ser enviada" rows="5"
                    :class="{ 'p-invalid': submitted && !mensagem }" />
                <small>Utilize * para destacar. </small>
                <small class="p-error" v-if="submitted && !mensagem">Campo obrigatório.</small>
            </div>
        </div>
        <div v-if="!showCampos"><p>Deseja enviar uma mensagem via WhatsApp?</p></div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
            <Button label="Enviar" icon="pi pi-whatsapp" class="p-button-outlined p-button-success"
                @click="direcionarWhatsApp()" />
        </template>
    </Dialog>
</template>
<script>

export default {
    props: {
        text: {
            type: String,
            required: true
        },
        phone: {
            type: String,
            required: false
        },
        showCampos: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            mensagem: null,
            telefone: null,
            submitted: false
        };
    },
    mounted() {
        this.mensagem = this.text;
        this.telefone = this.phone;
    },
    methods: {
        direcionarWhatsApp() {
            if (!this.telefone || !this.mensagem) {
                this.submitted = true;
                return;
            }
            this.telefone = this.telefone.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '');
            this.mensagem = this.mensagem.replace('&', 'e');
            window.open(`https://api.whatsapp.com/send/?phone=55${this.telefone}&text=${this.mensagem}`).focus();
        }
    }
}
</script>
