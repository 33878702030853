<template>
    <Dialog
        v-model:visible="open"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '60vw' }"
        class="p-fluid"
        :modal="true"
        header="Reserva de horários"
        :closable="!loadingConfirmar"
    >
        <div v-if="loadingConfirmar" class="div-loading">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <Message severity="info">Aguarde estamos inserindo os agendamentos no prestador. Esta operação pode demorar. Aguarde.</Message>
        </div>
        <div v-if="agendamentoForm?.funcionarios?.[0]?.possuiRisco === false">
            <Message :closable="false" severity="info">
                O funcionario do agendamento está sem riscos. Por favor, aplique os riscos no funcionário.</Message
            >
        </div>

        <div class="div-content" :style="loadingConfirmar ? 'opacity: 30%;' : ''">
            <AppLoadingWrapper v-if="loading" />

            <TabelaReserva
                v-if="agendamentoForm?.configFuncionarios?.[0] && configFuncionariosOriginal"
                ref="tabelaReservaEl"
                :formData="agendamentoForm"
                :configFuncionario="agendamentoForm?.configFuncionarios?.[0]"
                :configFuncionarioIndex="0"
                :configFuncionarioOriginal="configFuncionariosOriginal"
            />
        </div>
        <template #footer>
            <div>
                <Button
                    :disabled="loadingConfirmar"
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger"
                    @click="closeDialog"
                />
                <Button
                    label="Confirmar"
                    :loading="loadingConfirmar"
                    icon="pi pi-check"
                    class="p-button-outlined p-button-success"
                    @click="onClickConfirmar"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import TabelaReserva from './dialog-reserva-horario/components/TabelaReserva/TabelaReserva.vue';
import { ref, defineExpose, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';
import AreaMedicaService from '../../../services/AreaMedicaService';
import { clone } from 'ramda';
import { getClientBase } from '../../../services/http';
import AtualizadorFuncionarioExames from './dialog-reserva-horario/utils/AtualizadorFuncionarioExames';
import { tabelaReservaExpose } from './dialog-reserva-horario/components/TabelaReserva/TabelaReserva';
import { AgendamentoReservaHorarioDto } from './dialog-reserva-horario/utils/AgendamentoReservaHorarioDto';
import { verificarExameOcupacional } from '@/utils/VerificarExameOcupacional';
import TipoExame from '../../../enums/TipoExame';

const emit = defineEmits(['load']);

const open = ref(false);
const loadingConfirmar = ref(false);
const loading = ref(false);
const toast = useToast();

const tabelaReservaEl = ref(tabelaReservaExpose());
const agendamentoForm = ref(new AgendamentoReservaHorarioDto());

async function openDialog(agendmento) {
    open.value = true;
    agendamentoForm.value = null;
    await carregaAgendamento(clone(agendmento));
}

function closeDialog() {
    open.value = false;
}

function getTipoExame(agendamentoTipoExameId) {
    const isOcupacional = verificarExameOcupacional(agendamentoTipoExameId);
    return !isOcupacional ? TipoExame.PERIODICO : agendamentoTipoExameId;
}

async function carregaAgendamento(agendamentoReserva) {
    try {
        loading.value = true;

        const agendamento = new AgendamentoReservaHorarioDto(agendamentoReserva);
        const funcionarioResgatado = await new AreaMedicaService('/customer/employees').findById(agendamento.funcionario?.id);
        agendamento.funcionarios = [funcionarioResgatado.data];

        const atualizador = new AtualizadorFuncionarioExames(agendamento);
        const tipoExameFuncionarioOcupacional = getTipoExame(agendamento.tipoExame.id);
        await atualizador.atualizarFuncionarioDoAgendamento(tipoExameFuncionarioOcupacional);

        const agendamentoComConfig = await criarConfigFuncionariosReserva(agendamento);
        await new Promise((resolve) => setTimeout(resolve, 500));
        agendamentoForm.value = agendamentoComConfig;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

const configFuncionariosOriginal = ref([]);
const isLoadingDadosPrestador = ref(false);

async function criarConfigFuncionariosReserva(agendamentoReserva) {
    const agendamento = new AgendamentoReservaHorarioDto(agendamentoReserva);
    isLoadingDadosPrestador.value = true;
    const criarConfigFuncionarioDto = agendamento.funcionarios
        .filter((funcionario) => funcionario.possuiRisco)
        .map((funcionario) => {
            return {
                idFuncionario: funcionario.id,
                nomeFuncionario: funcionario.name,
                companyId: funcionario.customer.companyId,
                funcionarioProcedimentos: funcionario.procedimentos.map((procedimento) => {
                    return {
                        id: procedimento.id,
                        name: procedimento.name,
                        procedimentoDiagnosticoId: procedimento.procedimentoDiagnosticoId,
                        idSugestao: procedimento.idSugestao
                    };
                }),
                idPrestador: agendamento.prestador.id,
                cnpjPrestador: agendamento.prestador.cnpj,
                nomePrestador: agendamento.prestador.name
            };
        });
    try {
        const { data } = await getClientBase().post(`prestador-config-reserva/criar-config-funcionarios`, criarConfigFuncionarioDto);
        agendamento.configFuncionarios = data;
        configFuncionariosOriginal.value = clone(data);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.response?.data?.message || error.message,
            life: 3000
        });
    }
    isLoadingDadosPrestador.value = false;
    return agendamento;
}

async function onClickConfirmar() {
    try {
        loadingConfirmar.value = true;

        const funcionario = agendamentoForm.value?.configFuncionarios?.[0];
        if (!funcionario) throw new Error('Funcionario nao encontrado' + agendamentoForm.value);

        tabelaReservaEl.value.iniciarLoadingFuncionario(funcionario.idFuncionario);
        tabelaReservaEl.value.validarConfigFuncionario();
        const procedimentosFromSoc = await tabelaReservaEl.value.getProcedimentosFromSoc(funcionario.idFuncionario);

        tabelaReservaEl.value.retirarFuncionarioDaTela(!!procedimentosFromSoc, funcionario.idFuncionario);
        tabelaReservaEl.value.finalizarLoadingFuncionario(funcionario.idFuncionario);
        await getClientBase().post('agendamentos/reservas-horarios/' + agendamentoForm.value.id, procedimentosFromSoc);
        loadingConfirmar.value = false;
        emit('load');
        closeDialog();
    } catch (error) {
        loadingConfirmar.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

defineExpose({
    openDialog
});
</script>

<style lang="scss" scoped>
.div-loading {
    margin-top: -40px;
    margin-left: 200px;
    position: absolute;
    z-index: 9999;
}
</style>
