<template lang="">
    <Dialog :style="{ width: '60vw' }" :header="title" :modal="modal">
        <AppLoadingWrapper v-if="loading" />

        <DataTable
            v-if="!loading && processos.length"
            dataKey="id"
            :value="processos"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="ordem" header="Ordem"></Column>
            <Column field="tipo" header="Tipo"></Column>
            <Column field="operacao" header="Operacao"></Column>
            <Column field="status" header="Status"></Column>
            <Column field="msgErro" header="Erro"></Column>
            <Column field="createdAt" header="Data">
                <template #body="{ data }">
                    {{ $filters.formatDate(data.createdAt) }}
                </template>
            </Column>
            <Column bodyClass="text-left" headerStyle="width: 5rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-undo" class="p-button-text p-button-info" v-tooltip="'Reprocessar'" :loading="loadingReprocessar" @click="onClickReprocessar(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="$emit('onClickClose')" />
        </template>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';

export default {
    name: 'AppProcessoDialog',
    emits: ['onClickClose'],
    props: {
        modal: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        },
        origem: {
            type: [String, Number],
            required: true
        },
        origemId: {
            type: [String, Number],
            required: true
        },
        tipo: {
            type: [String, Number]
        },
        processarPath: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            processos: [],
            record: {},
            loading: false,
            loadingReprocessar: false
        };
    },
    async mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                const { data } = await getClientBase().get(`/processamento/get-by-origem-tipo/${this.origem}/${this.origemId}`);
                this.processos = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao listar: ${error.response.data.message}', life: 3000 });
                this.loading = false;
            }
        },
        async onClickReprocessar() {
            try {
                this.loadingReprocessar = true;
                const { data } = await getClientBase().post(`${this.processarPath}${this.origemId}`);
                this.processos = data;
                this.loadingReprocessar = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: `Erro ao reprocessar: ${error.response.data.message}`, life: 3000 });
                this.loadingReprocessar = false;
            }
        }
    }
};
</script>
