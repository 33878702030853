export function GestaoMedicaPerguntas() {
    return [
        {
        id: 'step0',
        text:'<h3>Bem-vindo a tela de perguntas!</h3></br> A partir desse guia iremos mostrar as principais funções!',
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step1',
        text: 'Aqui você pode pesquisar por uma <b>pergunta</b> buscando pela sua descrição e clicando na tecla <b>ENTER</b>.',
        attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
    {
        id: 'step3',
        text: `Para adicionar uma nova pergunta, utilize o botão <b>+Novo</b>.`,
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: '<h5>Acompanhe todas as perguntas a partir da listagem.</h5> <p> Neste item, você encontrará a tabela de perguntas, contendo todas as informações a respeito da sua descrição, tipo e opções. ',
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step5',
        text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de uma pergunta, nesta ação é possível:</p>
        <p><b>Alterar:</b> Alterar os dados do cadastro de uma pergunta.<p>
        <p><b>Excluir:</b> Excluir uma perguunta cadastrada.<p>
        <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico.  <p>`,
        attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    }
]
}
