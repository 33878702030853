<template>
    <div>
        <Dialog v-model:visible="open" header="Alterar periodo do agendamento" closable>
            <template v-if="form" #default>
                <p>Por favor, selecione o novo período do agendamento:</p>
                <div class="flex flex-column gap-2">
                    <label class="w-full" for="intervaloSugerido"> Sugerir intervalo de data<span class="p-error">*</span></label>
                    <AppInputCalendarioRangedV2
                        id="intervaloSugerido"
                        v-model="v$.intervaloSugerido.$model"
                        :class="{ 'p-invalid': v$.intervaloSugerido.$error && v$.intervaloSugerido.lazy.$dirty }"
                        :idPrestador="form.prestador?.id"
                        :idCidade="form.cidade?.id"
                        :disabled="!form.prestador"
                        :disabledDays="form.disabledDays"
                        :minDate="form.intervaloMinDate"
                        :maxDate="form.intervaloMaxDate"
                        :periodo="form.periodo"
                        @onGetPeriodoManhaVisible="setPeriodoManhaVisible"
                    />
                    <small v-if="v$.intervaloSugerido.$error && v$.intervaloSugerido.lazy.$dirty" class="p-error w-full">
                        {{ v$.intervaloSugerido.$errors[0].$message }}
                    </small>
                </div>
                <div class="flex flex-column gap-2">
                    <label class="w-full" for="periodo"> Período do dia<span class="p-error">*</span> </label>
                    <Dropdown
                        id="periodo"
                        v-model="v$.periodo.$model"
                        :class="{ 'p-invalid': v$.periodo.$error && v$.periodo.lazy.$dirty }"
                        :disabled="!form.intervaloSugerido || !form.intervaloSugerido[0]"
                        :options="periodos"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione..."
                    />
                    <small v-if="v$.periodo.$error && v$.periodo.lazy.$dirty" class="p-error w-full">
                        {{ v$.periodo.$errors[0].$message }}
                    </small>
                </div>
            </template>

            <template #footer>
                <Button label="Confirmar" class="p-button-plain md:ml-2 md:mr-0 col px-5" @click="alterarPeriodo" />
            </template>
        </Dialog>
        <Toast />
    </div>
</template>

<script setup>
import { ref, defineEmits, defineExpose } from 'vue';
import { clone } from 'ramda';
import AppInputCalendarioRangedV2 from '../../../../../../components/input/AppInputCalendarioRangedV2.vue';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import usePeriodo from '../composables/usePeriodo';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['periodoAlterado']);

const rules = {
    intervaloSugerido: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
    periodo: { required: helpers.withMessage('Campo obrigatório', required), lazy: true }
};

const open = ref(false);

const form = ref(null);

const v$ = useVuelidate(rules, form);

function openDialog(formData) {
    open.value = true;
    form.value = clone(formData);
}

function closeDialog() {
    open.value = false;
}

const { setPeriodoManhaVisible, periodos } = usePeriodo(form);

const toast = useToast();

async function alterarPeriodo() {
    const periodoValido = await validarPeriodo();
    if (!periodoValido) {
        return toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Preencha todos os campos corretamente',
            life: 3000
        });
    }

    emit('periodoAlterado', [form.value.intervaloSugerido, form.value.periodo]);
    closeDialog();
}

function validarPeriodo() {
    return v$.value.$validate();
}

defineExpose({
    openDialog
});
</script>
