<template>
    <PcdFuncionario
        v-for="(funcionario, index) in form.funcionarios"
        :key="index"
        :funcionario="funcionario"
        :submittedAnexo="submittedAnexo"
        @update="onUpdate"
    />
</template>
<script setup>
import PcdFuncionario from './PcdFuncionario.vue';
import { computed, defineEmits, defineProps, defineExpose, ref, onActivated } from 'vue';

const submittedAnexo = ref(false);
const emit = defineEmits(['update:formData']);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});
const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});
onActivated(() => {
    submittedAnexo.value = false;
});
function onUpdate(data) {
    form.value.funcionarios.forEach((funcionario) => {
        if (data.value.id !== funcionario.id) return;
        return data;
    });
}

function possuiLaudoPcdAnexo(anexos) {
    return anexos?.some((anexo) => anexo.tipo === 'LAUDO_PCD');
}

function validarCampos() {
    const funcionariosPCD = form.value.funcionarios.filter((funcionario) => funcionario.pcd);
    const faltaCampos = funcionariosPCD.some((funcionario) => !possuiLaudoPcdAnexo(funcionario.anexos));
    submittedAnexo.value = faltaCampos;
    return !faltaCampos;
}

defineExpose({
    validarFormulario() {
        return validarCampos();
    }
});
</script>

<style></style>
