const autoagendamento = {
    path: '/autoagendamento',
    redirect: '/autoagendamento/login',
    component: () => import('./Index.vue'),
    meta: { requerAutenticacaoAutoagendamento: true },
    children: [
        {
            path: '/autoagendamento/login',
            name: 'Login Autoagendamento',
            component: () => import('./steps/Login.vue')
        },
        {
            path: '/autoagendamento/confirmar-dados',
            name: 'Confirmar Dados do Funcionario',
            component: () => import('./steps/ConfirmarDados.vue')
        },
        {
            path: '/autoagendamento/local',
            name: 'Local da Consulta',
            component: () => import('./steps/Local.vue')
        },
        {
            path: '/autoagendamento/data',
            name: 'Data e Hora da Consulta',
            component: () => import('./steps/Data.vue')
        },
        {
            path: '/autoagendamento/confirmado',
            name: 'Agendamento Confirmado',
            component: () => import('./steps/Confirmado.vue')
        }
    ]
};

export default autoagendamento;
