<template>
    <Dialog :style="{ width: '450px' }" header="Confirmar" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="title">Tem certeza que deseja inativar <b>{{ title }}</b>?</span>
            <span v-else-if="customTitle">{{ customTitle }}</span>
        </div>
        <template #footer>
            <Button 
                :label="labelClose" 
                icon="pi pi-times" 
                class="p-button-text" 
                @click="$emit('onClose')" />
            <Button 
                :loading="loading"
                :label="labelConfirm" 
                icon="pi pi-ban" 
                class="p-button-outlined p-button-danger"
                @click="onClickConfirm" />
        </template>
    </Dialog>
</template>

<script>
import { getClientBase } from '../services/http';

export default {
    emits: ['onConfirm', 'onClose'],
    props: {
        service: {
            type: Object
        },
        title: {
            type: String
        },
        customTitle: {
            type: String,
            default: 'Tem certeza que deseja inativar este registro?'
        },
        labelClose: {
            type: String,
            default: 'Cancelar'
        },
        labelConfirm: {
            type: String,
            default: 'Inativar'
        },
        record: {
            type: Object
        },
        inactivatePath: {
            type: String
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async onClickConfirm() {
            try {
                this.setLoadingInactivate();
                const recordId = this.record.id || this.record._id;
                if (this.inactivatePath) {
                    await getClientBase().post(this.inactivatePath + this.record.id);
                } else {
                    await this.service.inactivate(recordId);
                }
                
                this.disableLoadingInactivate();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro inativado com sucesso!',
                    life: 3000
                });
                this.$emit('onConfirm', this.record);
            } catch (err) {
                this.disableLoadingInactivate();
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao inativar o registro!',
                    detail: message,
                    life: 3000
                });
            }
        },
        setLoadingInactivate() {
            this.loading = true;
        },
        disableLoadingInactivate() {
            this.loading = false;
        }
    }
}
</script>
