import BaseService from '../../../../../services/BaseService';
import { getClientBase } from '../../../../../services/http';
import { AgendamentoDto } from '../../../../../utils/dtos/AgendamentoDto';

export default class AtualizadorFuncionarioExames {
    agendamento = new AgendamentoDto({});
    erroSincronizacaoSoc = false;
    exameClinicoObrigatorio = true;

    constructor(agendamento) {
        this.agendamento = agendamento;
    }

    async atualizarFuncionarioDoAgendamento(tipoExameId) {
        const funcionariosAgrupados = this.agruparFuncionariosConformeLimiteRequisicaoSoc(this.agendamento.funcionarios);
        for (const funcionarios of funcionariosAgrupados) {
            await Promise.all(
                funcionarios.map(async (funcionario) => {
                    if (this.agendamento.funcionarios?.some((element) => element.id === funcionario.id)) {
                        await this.buscarRiscosDoFuncionario(funcionario);
                        await this.buscarExamesDoFuncionario(funcionario, tipoExameId);
                    }
                })
            );
        }
    }

    agruparFuncionariosConformeLimiteRequisicaoSoc(funcionarios) {
        const limiteSolicitacoesSimultaneasSOC = 5;
        return funcionarios.reduce((grupos, funcionario) => {
            if (grupos.at(-1)?.length < limiteSolicitacoesSimultaneasSOC - 1) {
                grupos.at(-1).push(funcionario);
            } else {
                grupos.push([funcionario]);
            }

            return grupos;
        }, []);
    }

    async buscarRiscosDoFuncionario(funcionario) {
        try {
            this.erroSincronizacaoSoc = false;

            const { data } = await new BaseService('/customer/employees/riscos-soc').findById(funcionario.id);
            funcionario.riscos = data;
            funcionario.possuiRisco = data.length !== 0;
        } catch (error) {
            this.erroSincronizacaoSoc = error;
            console.error(error);
        }
    }

    async buscarExamesDoFuncionario(funcionario, tipoExameId) {
        const { id, customerId } = funcionario;
        const tipoExameIdEscolhido = tipoExameId || this.agendamento?.tipoExame?.id;

        this.erroSincronizacaoSoc = false;
        try {
            const { data } = await getClientBase().get(
                `/importacao/procedimentos-soc/funcionario/${id}/customer/${customerId}/tipo-exame/${tipoExameIdEscolhido}`
            );
            if (!data) return;

            data.procedimentos.forEach((el) => {
                const exameEmDiaEncontrado = data.examesEmDiaList.find((item) => item.idExame === el.procedimento.id);
                el.dataRefazerExame = exameEmDiaEncontrado?.dataRefazerExame;
                el.aceitar = !!(el.procedimento.clinical && this.exameClinicoObrigatorio) || !exameEmDiaEncontrado;
            });

            this.atribuirExamesAoFuncionario(data, funcionario);
        } catch (error) {
            this.erroSincronizacaoSoc = error;
        }
    }

    atribuirExamesAoFuncionario(data, funcionario) {
        if (funcionario.exames && funcionario.procedimentos) {
            data.procedimentos.forEach((el) => {
                if (funcionario.exames.some((exameMarcado) => exameMarcado.procedimento.id === el.procedimento.id && exameMarcado.aceitar)) {
                    el.aceitar = true;
                }
            });
        } else {
            funcionario.exames = data.procedimentos;
            funcionario.procedimentos = data.procedimentos.filter((item) => item.aceitar).map((el) => ({ ...el.procedimento }));
            this.agendamento.examesEmDia ??= data.examesEmDia;
        }
    }
}
