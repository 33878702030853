<template>
    <slot :disabledDays="disabledDays" />
</template>
<script setup>
import { computed, defineProps, ref, watch } from 'vue';
import { PrestadorAgendamento } from './PrestadorAgendamento';
import { useToast } from 'primevue/usetoast';
import { usePrimeVue } from 'primevue/config';

const props = defineProps({
    prestadorId: {
        type: Number,
        required: true
    }
});

const toast = useToast();
const primeVue = usePrimeVue();

const gerenciaPrestador = ref(new PrestadorAgendamento(toast));

watch(
    () => props.prestadorId,
    (prestadorId) => {
        if (prestadorId) {
            gerenciaPrestador.value.carregarDados(prestadorId);
        }
    },
    { immediate: true }
);
const disabledDays = computed(() => {
    return gerenciaPrestador?.value?.getDiasNaoAtendidos?.(primeVue.config.locale.dayNamesShort);
});
</script>
