<template>
    <AppOnePageDropdown
        v-model="value"
        optionLabel="cidade"
        placeholder="Selecione a cidade"
        :service="$service"
        :itemSize="itemSize"
        :renderOption="(slotProps) => (concatenaCidadeComUf ? slotProps.option?.cidade + ' - ' + slotProps.option?.estado : slotProps.option?.cidade)"
        :renderValue="(slotProps) => (concatenaCidadeComUf ? slotProps.value?.cidade + ' - ' + slotProps.value?.estado : slotProps.value?.cidade)"
        @change="change"
        :loading="loading"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    name: 'DropdownCidade',
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 50
        },
        change: {
            type: Function
        },
        concatenaCidadeComUf: {
            type: Boolean,
            default: true
        },
        filtrosExtras: {
            type: Object
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null,
            loading: false
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/cidades');
    }
};
</script>
