<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        optionLabel="name"
        :filterFields="['name', 'code']"
        placeholder="Selecione o prestador..."
        :service="$service"
        :itemSize="itemSize"
        :autoLoad="autoLoad"
        :renderOption="customRenderOption"
        :filtrosExtras="filtrosExtras"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '../../../services/BaseService';
export default {
    name: 'DropdownPrestador',
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        filtrosExtras: {
            type: Object
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        customRenderOption(slotProps) {
            const nome =
                (slotProps.option?.code ? slotProps.option?.code + ' - ' : ' ') +
                slotProps.option?.name +
                ' | ' +
                slotProps.option?.city?.cidade +
                ' - ' +
                slotProps.option?.city?.estado;

            return nome;
        }
    },
    async created() {
        this.$service = new BaseService('/suppliers/combo');
    }
};
</script>
