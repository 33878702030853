<template>
    <Dialog v-model:visible="open" :closable="false" :modal="true" :style="{ width: '400px' }" class="p-fluid" header="Pré-solicitação">
        <div>
            <span>Deseja alterar o status para falta consulta?</span>
        </div>
        <template #footer>
            <Button class="p-button-outlined p-button-danger" icon="pi pi-times" label="Cancelar" @click="closeDialog" />
            <Button
                :disabled="loadingSave"
                :loading="loadingSave"
                class="p-button-outlined p-button-success"
                icon="pi pi-check"
                label="Salvar"
                @click="onClickAtualizar"
            />
        </template>
    </Dialog>
</template>

<script setup>
import StatusAgendamento from '@/enums/StatusAgendamento';
import BaseService from '@/services/BaseService';
import { defineProps, ref, defineExpose, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['onLoad']);

const props = defineProps({
    agendamento: {
        type: Object,
        required: true
    }
});

const open = ref(false);
const loadingSave = ref(false);
const cliente = ref({});
const serviceAgendamentoStatus = new BaseService('/agendamento_status');
const serviceCliente = new BaseService('customers');
const toast = useToast();

function openDialog() {
    open.value = true;
    getCliente();
}

function closeDialog() {
    open.value = false;
}

async function getCliente() {
    const { data } = await serviceCliente.findById(props.agendamento.idCliente);
    cliente.value = data;
}

async function onClickAtualizar() {
    try {
        loadingSave.value = true;

        const respostaStatus = 'Falta consulta';

        // TODO: verificar o que deveria ser feito aqui. Na antiga faz referência a respostaStatus, mas não é passado nada.
        await serviceAgendamentoStatus.save({
            descricao: `Pendência resolvida: ${respostaStatus}`,
            agendamento: props.agendamento,
            status: { id: StatusAgendamento.FALTA_CONSULTA }
        });
        loadingSave.value = false;
        emit('onLoad');
        closeDialog();
        if (cliente.value.limiteReagendamento && props.agendamento.quantidadeFaltaConsulta >= cliente.value.limiteReagendamento) {
            toast.add({
                severity: 'success',
                summary: `Registro atualizado com sucesso, o limite de
                        reagendamentos por falta consulta deste cliente foi
                        atingido, o mesmo não será reagendado novamente`,
                life: 3000
            });
        } else {
            toast.add({ severity: 'success', summary: 'Registro atualizado com sucesso', life: 3000 });
        }
    } catch (err) {
        toast.add({ severity: 'error', summary: 'Erro ao atualizar o agendamento!', life: 3000 });
    }
}

defineExpose({
    openDialog
});
</script>
