import BaseService from './BaseService';
import { getClientBase } from './http'

export default class FiltroService extends BaseService {
    constructor() {
        super('filtros')

        this.path = 'filtros';
    }

    removerFiltro(record) {
        return getClientBase().post(`${this.path}/remover-filtro`, record)
    }

    carregaFiltroTexto(params) {
        return getClientBase().get(`${this.path}/carregar-filtro/texto`, {
            params
        });
    }
}
