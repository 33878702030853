<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <Timeline v-if="!loading && logs" :value="logs">
            <template #marker="slotProps" class="customized-timeline">
                <span class="custom-marker shadow-2" :style="{ backgroundColor: slotProps.item.hexColor }">
                    <i :class="slotProps.item.iconName"></i>
                </span>
            </template>
            <template #content="slotProps">
                <Card class="mb-5">
                    <template #title>
                        <div class="flex flex-row">
                            <div class="font-semibold text-xl">
                                {{ slotProps.item.tipoEvento }} <i class="pi pi-clock"></i>
                            </div>
                            <div class="ml-auto">
                                <small class="font-light text-base">
                                    <i class="pi pi-clock"></i>
                                    {{ $filters.formatDate(slotProps.item.createdAt) }}
                                </small>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <div class="">
                                <small class="font-light text-base">
                                    {{ slotProps.item.classificacao == 'RESPOSTA' ? 'De: ' : 'Para: ' }} {{
            agendamento.telefoneFuncionario }} {{ agendamento.funcionario.name }}
                                </small>
                            </div>
                        </div>
                        <div v-if="slotProps.item.acao" class="flex flex-row pt-2">
                            <div class="font-light text-xl">
                                {{ slotProps.item.acao }}
                            </div>
                        </div>
                        <div v-if="slotProps.item.acao" v-html="slotProps.item.variaveis">
                        </div>
                        <div class="pt-4 flex flex-row">
                            <div class="font-light text-xl">
                                {{ slotProps.item.envio != 'SUCESSO' ? slotProps.item.envio : null }}
                            </div>
                        </div>
                    </template>

                    <template #content>
                        <div class="flex items-center">
                            <small class="font-light text-base mr-2">
                                by DSG Technology
                            </small>
                            <img src="/layout/images/whatsapp/mavi.png" alt="DSG Logo" class="logo invert-image">
                        </div>
                    </template>
                </Card>
            </template>
        </Timeline>
        <div v-if="!logs">
            <div class="h-30rem w-full flex justify-content-center align-items-center">
                <div class="flex flex-column justify-content-center align-items-center text-500">
                    <div class="mb-4">
                        <i class="pi pi-search" style="font-size: 5rem"></i>
                    </div>
                    <div>
                        Não foi encontrado nenhum registro de agendamento
                    </div>
                    <div>
                        com a whatsapp vinculada a esse agendamento
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';
import BaseService from '@/services/BaseService';

const props = defineProps({
    agendamento: {
        type: Object,
        required: true
    }
});

const logs = ref([]);
const loading = ref(false);

onBeforeMount(async () => {
    await load();
});

async function load() {
    loading.value = true;
    const service = new BaseService(`/timeline/whatsapp/${props.agendamento.id}`);
    const { data } = await service.findAll({});
    logs.value = data;
    loading.value = false;
}
</script>

<style lang="scss" scoped>
:deep(.p-timeline-event-opposite) {
    display: none;
}

.invert-image {
    filter: invert(100%);
}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.logo {
    margin-left: auto;
    height: 1.5rem;
    width: auto;
}
</style>