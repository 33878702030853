<template>
    <Panel :toggleable="true" class="nopad" :id="funcionario.id">
        <template #header>
            <div class="w-full py-3 text-xl font-medium">
                {{ funcionario.name }}
            </div>
        </template>
        <template #default>
            <div class="flex align-items-center column-gap-2 px-4">
                <Message class="flex-grow-1" severity="info" :closable="false">
                    <p>Este agendamento não pode ser feito via reserva de horários. Motivo: {{ motivo }}</p>
                </Message>

                <Button
                    label="Direcionar para um atendente"
                    class="p-button-text px-5 font-medium"
                    @click="direcionarParaAtendente"
                    :loading="loading"
                />
            </div>
        </template>
    </Panel>

    <Toast />
</template>

<script setup>
import { defineEmits, defineProps, inject, ref } from 'vue';

const emit = defineEmits(['salvouFuncionario']);
const props = defineProps({
    funcionario: {
        type: Object,
        required: true
    },
    motivo: {
        type: String,
        required: true
    }
});

const loading = ref(false);
const salvarAgendamento = inject('salvarAgendamento');

async function direcionarParaAtendente() {
    loading.value = true;
    const examesNaoCadastrados = props.funcionario.procedimentos
        .filter((p) => !p.idSugestao && !p.procedimentoDiagnosticoId)
        .map((p) => p.name)
        .join(', ');
    const observacao = `o(s) exame(s) ${examesNaoCadastrados} precisa ser cadastrado na plataforma. Para cadastrar é necessário ir no modulo Gestão Sesmt - Cadastro - procedimentos - pesquisar o procedimento - ir nos menu ( três pontos ao lado do exame) na opção alterar - no campo do e-social colocar o exame procedimento diagnóstico - salvar`;
    const salvo = await salvarAgendamento({ ...props.funcionario, direcionarParaAtendente: true, observacao: observacao });
    if (salvo) {
        emit('salvouFuncionario', props.funcionario.id);
    }
    loading.value = false;
}
</script>
