export class FuncionarioDto {
    id;
    customerId;
    codigoSoc;
    situation;
    name;
    nomeSocial;
    multipleLinks;
    effective;
    pcd;
    registration;
    code;
    sex;
    dataNascimento;
    admissionDate;
    resignationDate;
    changeDate;
    civilState;
    categoriaTrabalhadorId;
    pisPasep;
    rg;
    rgState;
    rgDispatcher;
    rgDate;
    cpf;
    ctps;
    ctpsState;
    zipCode;
    address;
    addressComplement;
    neighborhood;
    city;
    state;
    deficiency;
    email;
    lastMovementationDate;
    commercialPhone;
    customerHierarchyId;
    phone;
    matriculaCliente;
    matriculaEsocial;
    originDetail;
    createdAt;
    updatedAt;
    deletedAt;
    mudouRisco;
    statusIntegracaoSoc;
    customer;

    constructor(obj) {
        Object.assign(this, obj);
    }
}
