import dayjs from 'dayjs';
import moment from 'moment-timezone';

export const ENUM_DIAS_SEMANA = {
    DOMINGO: 0,
    SEGUNDA: 1,
    TERCA: 2,
    QUARTA: 3,
    QUINTA: 4,
    SEXTA: 5,
    SABADO: 6
};

export function horaLimitesCalendarPrimevue() {
    const INICIO_HORARIO_COMERCIAL = [5, 0, 0, 0];
    const FIM_HORARIO_COMERCIAL = [19, 59, 0, 0];

    const min = new Date();
    min.setHours(...INICIO_HORARIO_COMERCIAL);
    const max = new Date();
    max.setHours(...FIM_HORARIO_COMERCIAL);

    return {
        min,
        max
    };
}

export function horaLimites() {
    const INICIO_HORARIO_COMERCIAL = '06:00';
    const FIM_HORARIO_COMERCIAL = '19:01';
    const format = 'HH:mm';

    const inicioValido = moment(INICIO_HORARIO_COMERCIAL, format);
    const fimValido = moment(FIM_HORARIO_COMERCIAL, format);

    return { inicioValido, fimValido, format };
}

export function isHoraInHorarioComercial(hora) {
    const { format, inicioValido, fimValido } = horaLimites();
    const horaDesejada = moment(hora, format);

    if (horaDesejada.isBetween(inicioValido, fimValido)) {
        return true;
    }
}

export function verificaDiaNaoAtendidoEntre(inicio, fim, diasSemanaNaoAtendido, primeVue) {
    let nomeDiaNaoAtendido = '';

    for (const diaNaoUtil of diasSemanaNaoAtendido) {
        const diaNaoAtendido = new Date(inicio).getDay() === diaNaoUtil || new Date(fim).getDay() === diaNaoUtil;
        if (diaNaoAtendido) {
            nomeDiaNaoAtendido = primeVue.config.locale.dayNames[diaNaoUtil];
        }
    }
    return nomeDiaNaoAtendido;
}

export function getDiferencaDiasUteis(dataInicio, dataFim, diasSemanaNaoUteis = [ENUM_DIAS_SEMANA.DOMINGO]) {
    let inicio = dayjs(dataInicio).startOf('day');
    const fim = dayjs(dataFim).startOf('day');
    if (inicio.isSame(fim, 'day')) {
        return 0;
    }

    let diasDiferenca = 0;
    while (fim.isAfter(inicio)) {
        const dataAtualNaoConta = diasSemanaNaoUteis.includes(inicio.day());
        if (!dataAtualNaoConta) {
            diasDiferenca = diasDiferenca + 1;
        }
        inicio = inicio.add(1, 'day');
    }
    return diasDiferenca;
}
