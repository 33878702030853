<template>
    <Dialog v-model:visible="open" :style="{ width: '450px' }" class="p-fluid" header="Pendências" :closable="false" :modal="true">
        <div class="field">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span style="font-weight: bold">{{ statusAgendamento.descricao }}</span>
            </div>
        </div>
        <div class="field">
            <label for="Resposta">Resolução de pendências: </label>
            <Textarea v-model="respostaStatus" :autoResize="true" rows="3" cols="15" :class="{ 'p-invalid': submitted && !statusAgendamento }" />
            <small class="p-error" v-if="submitted && !statusAgendamento">Campo obrigatório.</small>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="closeDialog()" />
            <Button label="Finalizar" icon="pi pi-check" class="p-button-outlined p-button-success" @click="updateStatusAgendamento" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose } from 'vue';
import StatusAgendamento from '@/enums/StatusAgendamento';
import AreaMedicaService from '@/services/AreaMedicaService';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    agendamento: {
        type: Object,
        required: true
    }
});

const open = ref(false);
const statusAgendamento = ref({});
const respostaStatus = ref('');
const submitted = ref(false);
const enumStatusAgendamento = StatusAgendamento;
const serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
const toast = useToast();

const openDialog = () => {
    open.value = true;
    load();
};

const closeDialog = () => {
    open.value = false;
};

async function load() {
    const path = 'agendamento_status/agendamento';
    const service = new AreaMedicaService(`${path}/${props.agendamento.id}`);
    const { data } = await service.findAll({});
    statusAgendamento.value = data;
}

async function updateStatusAgendamento() {
    if (!respostaStatus.value) {
        toast.add({ severity: 'error', summary: 'Resposta não preenchida, por favor preencha o campo', life: 3000 });
        return;
    }

    try {
        await serviceAgendamentoStatus.save({
            descricao: `Pendência resolvida: ${respostaStatus.value}`,
            agendamento: props.agendamento,
            status: { id: enumStatusAgendamento.PENDENCIA_PRESTADOR }
        });
        toast.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 });
        closeDialog();
    } catch (err) {
        toast.add({ severity: 'error', summary: 'Problemas ao salvar dados na tabela', life: 3000 });
    }
}

defineExpose({
    openDialog
});
</script>
