<template>
    <div class="p-fluid grid">
        <div class="field col-12 m-0">
            <Textarea
                v-model="detalheConsulta"
                :autoResize="true"
                cols="20"
                rows="1"
                type="text"
                :class="{ 'p-invalid': v$.$invalid && v$.$dirty }"
                class="w-full"
            />
            <small v-if="v$.$invalid && v$.$dirty" class="p-error">
                {{ v$.required?.$message }}
            </small>
        </div>
    </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { defineProps, defineEmits, ref, watch, defineExpose } from 'vue';

const emit = defineEmits(['update']);

const props = defineProps({
    funcionarioId: {
        type: Number,
        required: true
    }
});

const detalheConsulta = ref(null);
const rules = {
    required: helpers.withMessage('Os detalhes da consulta devem ser informados', required),
    $lazy: true
};
const v$ = useVuelidate(rules, detalheConsulta);

watch(detalheConsulta, () => {
    emit('update', { funcionarioId: props.funcionarioId, detalheConsulta });
});

defineExpose({
    async validate() {
        return await v$.value.$validate();
    }
});
</script>
