import { FuncionarioDto } from './FuncionarioDto';
import { PrestadorDto } from './PrestadorDto';

export class AgendamentoStatusDto {
    id;
    descricao;
    primevueColor;
    iconName;
    hexColor;
    hexColorIndicadores;
    createdAt;
    updatedAt;
    deletedAt;
    constructor(obj) {
        Object.assign(this, obj);
    }
}
export class AgendamentoTipoExameDto {
    id;
    descricao;
    codigoSoc;
    esocialCodigo;
    createdAt;
    updatedAt;
    deletedAt;
    constructor(obj) {
        Object.assign(this, obj);
    }
}
export class AgendamentoDto {
    id;
    statusAgrupamento;
    idFuncionario;
    idPrestador;
    idCliente;
    idStatus;
    tipo;
    parecerAso;
    telemedicina;
    linkTelemedicina;
    nomeSolicitante;
    idSolicitante;
    emailSolicitante;
    telefoneSolicitante;
    dataSugestaoInicial;
    dataSugestaoFinal;
    periodo;
    observacao;
    telefoneFuncionario;
    resideForaDoPais;
    tipoRetorno;
    dataLiberacao;
    anexoLaudo;
    motivoConsulta;
    detalheConsulta;
    sequencialFicha;
    quemAgenda;
    quemAvisa;
    userId;
    userResponsavelId;
    createdAt;
    updatedAt;
    deletedAt;
    alteracoesRealizar;
    situacao;
    statusComparecimento;
    sequencialResultado;
    pcd;
    erroProcessamento;
    msgErroProcessamento;
    etapaProcessamento;
    usaReservaHorario;
    horarioMarcadoPrestador;
    companyId;
    depositoAntecipado;
    quantidadeFaltaConsulta;
    podeProcessar;
    preAdmissao;
    idPreSolicitacao;
    asoEmDia;
    asoResponsavelId;
    motivoExamesEmDia;
    asoExaminadorId;
    asoDataEmissao;
    dataHomologacao;
    justificativa;
    asoLocalizadoSOC;
    observacaoPrestador;
    aceitarObservacao;
    tipoAtendimento;
    tipoAgendamento;
    autoagendamento;
    statusAtendimento;
    examesEmDia;
    status = new AgendamentoStatusDto();
    cidade;
    tipoExame = new AgendamentoTipoExameDto();
    funcionario = new FuncionarioDto();
    funcionarios = [new FuncionarioDto()];
    prestador = new PrestadorDto();
    constructor(obj) {
        Object.assign(this, obj);
    }
}
