<template>
    <div class="flex align-content-center justify-content-center flex-wrap gap-2" style="height: 100%">
        <i class="pi pi-spin pi-spinner" style="font-size: 6rem"></i>
        <span class="flex align-items-center justify-content-center" style="min-width: 100%;">Carregando informações...</span>
    </div>
</template>

<script>
export default {

}
</script>
