<template>
    <span>
        <Button
            label="Deletar"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
            @click="confirmMultipleDeletions()"
            :disabled="!selectedRequests || !selectedRequests?.length || loading"
        />
        <Dialog v-if="deleteProductsDialog" v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span>Deseja mesmo deletar as pré-solicitações selecionadas?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="deleteProductsDialog = false" />
                <Button label="Sim" icon="pi pi-check" class="p-button-outlined p-button-success" @click="deleteMultipleRequests" />
            </template>
        </Dialog>
    </span>
</template>
<script setup>
import { ref, defineProps } from 'vue';
import AreaMedicaService from '../../../../../services/AreaMedicaService';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    selectedRequests: Array,
    loading: Boolean,
    loadFx: Function
});

const deleteProductsDialog = ref(false);
const toast = useToast();

function confirmMultipleDeletions() {
    deleteProductsDialog.value = true;
}

async function deleteMultipleRequests() {
    for (const solicitacao of props.selectedRequests) {
        if (!solicitacao?.id) return;
        try {
            await new AreaMedicaService('/pre-solicitacoes').remove(solicitacao?.id);
            deleteProductsDialog.value = false;
            await props.loadFx();
            toast.add({ severity: 'success', summary: 'Pré-solicitação excluida com sucesso!', life: 3000 });
        } catch (err) {
            console.error(err);
            toast.add({ severity: 'error', summary: 'Erro ao excluir pré-solicitação!', life: 3000 });
        }
    }
}
</script>
