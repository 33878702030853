<template>
    <Dialog :style="{ width: '60vw' }" :header="title" :modal="modal">
        <div class="grid" v-if="enableImport">
            <div class="col-12">
                <FileUpload
                    mode="advanced"
                    @uploader="uploadAnexo"
                    auto
                    customUpload
                    :maxFileSize="10485760"
                    :showCancelButton="false"
                    :showUploadButton="false"
                    invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                    label="Importar"
                    chooseLabel="Importar"
                    class="mr-2 inline-block"
                    :style="{ width: '100%' }"
                    :disabled="singleUpload && anexos.length >= 1"
                >
                    <template #empty>
                        <p>Arraste e solte o arquivo aqui.</p>
                    </template>
                </FileUpload>
            </div>
        </div>
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading && anexos.length <= 0" class="h-full flex align-content-center justify-content-center align-items-center">
            <div class="flex anexo-aviso">Não há anexos para este registro ainda!</div>
            <i class="icon-aviso pi pi-paperclip"></i>
        </div>

        <DataTable
            v-if="!loading && anexos.length"
            dataKey="id"
            :value="anexos"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="nomeArquivo" header="Nome">
                <template #body="{ data }">
                    <a :href="data.url" class="white-space-nowrap overflow-hidden text-overflow-ellipsis" style="max-width: 60vw" target="_blank">
                        {{ data.nomeArquivo }}
                    </a>
                </template>
            </Column>
            <Column field="tipo" header="Tipo"></Column>
            <Column field="createdAt" header="Data">
                <template #body="{ data }">
                    <span v-if="formatoDaData === 'datetime'">
                        {{ $filters.formatDate(data.createdAt) }}
                    </span>
                    <span v-if="formatoDaData === 'date'">
                        {{ $filters.formatDateOnly(data.createdAt) }}
                    </span>
                </template>
            </Column>
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button icon="pi pi-cloud-download" class="p-button-text p-button-secondary" @click="downloadAnexo($event, slotProps.data)" />
                </template>
            </Column>
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button
                        icon="pi pi-trash"
                        class="p-button-text p-button-secondary"
                        @click="onClickConfirmDeleteRecord($event, slotProps.data)"
                        :disabled="
                            (slotProps.data.origem === 'PROTOCOLO_PIC' ||
                                slotProps.data.origem === 'MANIPULACAO_ALIMENTOS' ||
                                slotProps.data.origem === 'INCIDENTES_DE_TRABALHO') &&
                            !enableRemove
                        "
                    />
                </template>
            </Column>
        </DataTable>

        <AppDeleteDialog
            v-if="record.nomeArquivo"
            v-model:visible="showAppDeleteDialog"
            v-model:title="record.nomeArquivo"
            @onConfirm="onClickDeleteRecord"
            @onClose="onClickCloseDeleteRecord"
        >
        </AppDeleteDialog>

        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="$emit('onClickCloseAnexo')" />
        </template>
    </Dialog>
</template>

<script>
import { getClientBase } from '@/services/http';
import axios from 'axios';

export default {
    emits: ['onClickCloseAnexo', 'onUpload', 'onDelete'],
    props: {
        modal: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        },
        origem: {
            type: [String, Number],
            required: true
        },
        origemId: {
            type: [String, Number],
            required: true
        },
        tipo: {
            type: String,
            required: true
        },
        enableImport: {
            type: Boolean,
            default: true
        },
        enableRemove: {
            type: Boolean,
            default: true
        },
        rotaPublica: {
            type: Boolean,
            default: false
        },
        cpfFuncionario: {
            type: String,
            default: null
        },
        singleUpload: {
            type: Boolean,
            default: false
        },
        formatoDaData: {
            type: String,
            default: 'datetime'
        }
    },
    data() {
        return {
            anexos: [],
            record: {},
            showAppDeleteDialog: false,
            loading: false
        };
    },
    async mounted() {
        this.load();
    },
    methods: {
        downloadAnexo($event, record) {
            axios({
                url: record.url,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));

                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', record.nomeArquivo);
                document.body.appendChild(docUrl);
                docUrl.click();
            });
        },
        async load() {
            let data;
            try {
                this.loading = true;
                if (this.rotaPublica) {
                    const request = {
                        origemId: this.origemId,
                        origem: this.origem,
                        cpfFuncionario: this.cpfFuncionario
                    };
                    const response = await getClientBase().post('/anexo/publica', request);
                    data = response.data;
                } else {
                    const response = await getClientBase().get(`/anexo/${this.origem}/` + this.origemId);
                    data = response.data;
                }
                this.anexos = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar.', life: 3000 });
                this.loading = false;
            }
        },
        async uploadAnexo(event) {
            if (this.singleUpload && this.anexos.length >= 1) {
                this.$toast.add({ severity: 'info', summary: 'Você não pode anexar mais de um arquivo!', life: 3000 });
                return;
            }
            this.loading = true;
            const formData = new FormData();
            formData.append('file', event.files[0]);
            formData.append('origem', this.origem);
            formData.append('origemId', this.origemId);
            formData.append('tipo', this.tipo);
            try {
                const { data } = await getClientBase().post('/anexo', formData);
                this.$emit('onUpload', data);
                this.load();
                this.$toast.add({ severity: 'success', summary: 'Arquivo carregado com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar o arquivo', life: 3000 });
            }
            this.loading = false;
        },
        onClickConfirmDeleteRecord($event, record) {
            this.record = record;
            this.showAppDeleteDialog = true;
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false;
        },
        async onClickDeleteRecord() {
            if (!this.record) {
                return;
            }

            try {
                await getClientBase().delete(`/anexo/${this.record.id}`);
                this.record = {};
                this.$emit('onDelete');
                this.load();
                this.onClickCloseDeleteRecord();
                this.$toast.add({ severity: 'success', summary: 'Anexo excluído com sucesso!', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o anexo!', life: 3000 });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.anexo-aviso {
    font-size: 13px;
    height: auto;
    color: #b6bfc7;
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da;
}
</style>
