<template>
    <div class="grid step1">
        <div class="col-12">
            <div class="card">
                <Toast />
                <div class="card">
                    <AgendamentosHeader
                        @iniciarTourTela="iniciarTourTela"
                        v-model:filter="filter"
                        v-model:page="page"
                        :sort="sort"
                        v-model:exames="exames"
                        v-model:filtrosExtras="filtrosExtras"
                        v-model:status="status"
                        @loadAppointments="loadAppointments"
                        @load="load"
                    />
                    <TabelaAgendamentos
                        v-model:sort="sort"
                        v-model:loading="loading"
                        v-model:filtrosExtras="filtrosExtras"
                        :filter="filter"
                        v-model:perPage="perPage"
                        v-model:page="page"
                        v-model:total="total"
                        v-model:records="records"
                        @load="load"
                        @loadAppointments="loadAppointments"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAgendamentosTour } from './composables/useAgendamentosTour';
import AgendamentosHeader from './AgendamentosHeader.vue';
import { onMounted, ref } from 'vue';
import AreaMedicaService from '@/services/AreaMedicaService';
import { useAgendamentoStatusValidator } from '../../../pages/agendamento-atendimento/composables/useAgendamentoStatusValidator';
import { useToast } from 'primevue/usetoast';
import TabelaAgendamentos from './TabelaAgendamentos.vue';

const { iniciarTourTela } = useAgendamentosTour();
const { enumStatusAgendamento } = useAgendamentoStatusValidator();

const filter = ref([]);
const status = ref([]);
const loading = ref(false);
const filtrosExtras = ref({});
const perPage = ref(10);
const page = ref(1);
const sort = ref('');
const exames = ref([]);
const records = ref([]);
const total = ref(0);
const toast = useToast();

const service = new AreaMedicaService('/agendamentos');
const serviceStatus = new AreaMedicaService('/status_agendamento');

async function loadAppointments() {
    try {
        loading.value = true;
        const extras = {
            status: filtrosExtras.value.status?.map((status) => {
                return status.id;
            }),
            tipoExame: filtrosExtras.value.tipoExame?.map((tipoExame) => {
                return tipoExame.id;
            }),
            datas: filtrosExtras.value.datas
        };

        const appointments = await service.findAll({
            limit: perPage.value,
            page: page.value,
            filter: filter.value,
            filtrosExtras: extras,
            sort: sort.value
        });

        records.value = appointments.data.items;
        total.value = appointments.data.meta.totalItems;
        loading.value = false;
    } catch (error) {
        loading.value = false;
        toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', life: 3000 });
    }
}

onMounted(async () => {
    await load();
});

async function load() {
    filter.value = [];
    await aplicarStatusParaFiltragem();
}

async function aplicarStatusParaFiltragem(filtro) {
    const { data } = await serviceStatus.findAll({});
    filtrosExtras.value.status = filtro
        ? data.filter((p) => p.id === +filtro)
        : data.filter(
              (p) =>
                  p.id !== enumStatusAgendamento.CANCELADO &&
                  p.id !== enumStatusAgendamento.DESISTENCIA_DE_VAGA &&
                  p.id !== enumStatusAgendamento.CONCLUIDO &&
                  p.id !== enumStatusAgendamento.FALTA_CONSULTA
          );
    await loadAppointments();
}
</script>
