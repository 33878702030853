export function GestaoMedicaDepositoAntecipado() {
    return [
        {
        id: 'step0',
        text:'<h3>Bem-vindo a tela de depósito antecipado!</h3></br> A partir desse guia iremos mostrar as principais funções!',
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step1',
        text: 'Aqui você pode pesquisar por um <b>depósito antecipado</b> buscando por funcionário, cliente ou prestador e clicando na tecla <b>ENTER</b>.',
        attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
    {
        id: 'step2',
        text: `Nesta sessão, você pode selecionar o filtro de busca que será utilizado para identificar o depósito`,
        attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step3',
        text: 'Utilize a opção <b>(limpar filtros)</b> para remover os filtros selecionados.',
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: 'A qualquer momento é possível <b>extrair relatórios</b>  conforme a seleção do filtro.',
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step5',
        text: `<h5>Acompanhe todos os depósitos antecipados a partir da listagem.</h5> <p>Status em que se encontram os depósitos:<p>
        <p><b>Pendente:</b> : Exibirá todos os agendamentos que estão com o status de “depósito antecipado”, com a pendência da
        inserção de um anexo/comprovante. </p>
        <p><b>Concluído:</b> : Depósitos antecipados que já foram anexados os comprovantes e concluídos dentro da etapa
        “Pendente”. </p>
        <p><b>Reembolso:</b> Quando a solicitação de agendamento para o prestador de depósito antecipado dentro da tela do
        “painel de agendamento” é : cancelado, reagendado, lançado falta consulta ou desistência de vaga, o depósito
        antecipado desse colaborador passa da etapa “concluído” para a etapa “reembolso” para o cliente optar pelo “Gerar
        crédito” ou “solicitar reembolso”. </p>`,
        attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
  
]
}