<template>
    <Card class="card-notificacao shadow-5">
        <template #title>
            <div class="flex flex-row">
                Notificações
                <Button v-if="notificacoes.length" @click="excluirTodasAsNotificacoes"
                    label="Excluir todas as notificações" class="p-button-link p-0 m-0 ml-auto" />
            </div>
        </template>
        <template #content>
            <div class="overflow-x-auto h-28rem" id="scrollComponent">
                <NotificacaoSkeleton v-if="loading" />
                <div v-if="!loading">
                    <NotificacaoItem @onExcluir="removerNotificacaoDoArray" :notificacoes="notificacoes" />
                    <NotificacaoSkeleton v-if="loadingMore" />
                    <FooterNotificacao v-if="notificacoes.length" :totalItems="metaDados?.totalItems"
                        :notificacaoLength="notificacoes.length" />
                    <MsgNaoHaNotificacao v-if="!notificacoes.length" />
                </div>
            </div>
        </template>
    </Card>
</template>
<script>
import { getClientMicroserviceNotificacao } from '@/services/http';
import * as storage from '../../common/storage';
import MsgNaoHaNotificacao from './components/MsgNaoHaNotificacao.vue';
import FooterNotificacao from './components/FooterNotificacao.vue';
import NotificacaoSkeleton from './components/NotificacaoSkeleton.vue';
import NotificacaoItem from './components/NotificacaoItem.vue';

export default {
    emits: ['onClose', 'qntNotificacoes'],
    components: {
        MsgNaoHaNotificacao,
        FooterNotificacao,
        NotificacaoSkeleton,
        NotificacaoItem
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
            showNotificacao: false,
            notificacaoSelecionada: null,
            notificacoesNaoLidas: null,
            metaDados: null,
            notificacoes: [],
            currentPerPage: 6,
        }
    },

    async created() {
        this.profile = storage.getProfile();
        await this.load();
    },
    mounted() {
        const listElm = document.querySelector('#scrollComponent');
        listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
                this.metaDados?.totalItems != this.notificacoes.length && !this.loadingMore) {
                this.currentPerPage = this.currentPerPage + 4;
                this.loadMore();
            }
        });
    },
    methods: {
        async getQuantidadeNãoLida() {
            const path = `/notificacoes/nao-lidas`;
            const { data } = await getClientMicroserviceNotificacao().get(path);
            this.$emit('qntNotificacoes', data);
        },
        async excluirTodasAsNotificacoes() {
            if (!this.notificacoes) return
            this.notificacoes = [];
            const path = `/notificacoes/excluir-todas`;
            await getClientMicroserviceNotificacao().delete(path);
            await this.getQuantidadeNãoLida();
        },
        async load() {
            this.loading = true;
            const notificacoes = await getClientMicroserviceNotificacao().get(`/notificacoes?limit=${this.currentPerPage}&desvio=${this.notificacoes.length}`);
            this.metaDados = Number(notificacoes?.data.count);
            this.notificacoes = notificacoes.data.registros || [];
            this.loading = false;
            await this.lerNotificacoes();
        },
        async loadMore() {
            this.loadingMore = true;
            const notificacoes = await getClientMicroserviceNotificacao().get(`/notificacoes?limit=${this.currentPerPage}&desvio=${this.notificacoes.length}`);
            this.notificacoes = notificacoes.data.registros || [];
            this.loadingMore = false;
            await this.lerNotificacoes();
        },
        async lerNotificacoes() {
            if (!this.notificacoes) return
            const idsNotificacoesNaoLidas = this.notificacoes.filter(p => p.lida === false).map(p => p.id);
            if (idsNotificacoesNaoLidas === 0) {
                return;
            }
            setTimeout(async () => {
                this.notificacoes.filter(p => p.lida === false).map(p => p.lida = true);
                await getClientMicroserviceNotificacao().post('/notificacoes/ler-notificacoes', { body: idsNotificacoesNaoLidas });
                await this.getQuantidadeNãoLida();
            }, 500);
        },
        removerNotificacaoDoArray(id) {
            this.notificacoes = this.notificacoes.filter(p => p.id !== id);
            this.loadMore();
        }
    },
}
</script>
<style lang="scss" scoped>
.card-notificacao {
    z-index: 9;
    width: 25rem;
    height: 400px;
    margin-top: 3rem;
    padding: 0px;
    position: relative;
    left: 15px;
    border-radius: 2px 2px 2px 2px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}

@media only screen and (max-width: 960px) {
    .card-notificacao {
        position: relative;
        left: -25px;
        width: 300px !important;
    }
}
</style>