import { getClientAreaMedica } from './http-file'

class UploadService {

  constructor(path) {
    this.path = path
  }

  upload(file) {
    return getClientAreaMedica().post(this.path, file)
  }
}

export default UploadService
