<template>
    <OverlayPanel ref="overlayPanel" showCloseIcon :style="{ width: '40vw' }">
        <div v-if="props.isDemissional">
            <h4>Exames demissional!</h4>
            <div class="my-4 flex flex-column text-lg">
                {{ mensagemPorGrauDeRisco }}
            </div>
        </div>
        <DataTable :value="procedimentosEmDia">
            <Column field="procedimento.name" header="Procedimento" />
            <Column field="dataUltimaRealizacao" header="Realizado em">
                <template #body="{ data, field }">
                    {{ filters.formatDateOnly(data[field]) }}
                </template>
            </Column>
            <Column field="dataRefazerExame" header="Refazer em">
                <template #body="{ data, field }">
                    {{ filters.formatDateOnly(data[field]) }}
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <div class="field-checkbox mb-0">
                        <Checkbox
                            id="refazerExame"
                            :disabled="data.procedimento.clinical && exameClinicoObrigatorio"
                            :binary="true"
                            v-model="data.aceitar"
                            @change="onChangeRefazerExameEmDia"
                        />
                        <label for="refazerExame">Refazer exame</label>
                    </div>
                </template>
            </Column>
        </DataTable>
    </OverlayPanel>
</template>

<script setup>
import { ref, defineExpose, defineEmits, defineProps, computed } from 'vue';
import { useGlobalFilters } from '../../../../../../utils/useGlobalFilters';

const emit = defineEmits(['onChangeRefazerExameEmDia']);
const filters = useGlobalFilters();
const overlayPanel = ref(null);
const procedimentosEmDia = ref([]);
const grauDeRisco = ref();
const exameClinicoObrigatorio = ref(false);
const toggle = (event) => overlayPanel?.value.toggle(event);
const props = defineProps({
    isDemissional: {
        type: Boolean,
        required: true,
        default: false
    }
});
const mensagemPorGrauDeRisco = computed(() => {
    if (grauDeRisco.value === '1' || grauDeRisco.value === '2') {
        return 'A norma Regulamentadora nº 07 (NR7) estabelece: “ No exame demissional pode ser dispensado caso o exame clínico ocupacional mais recente tenha sido realizado há menos de 135 (centro e trinta e cinco) dias, para as organizações graus de risco 1 e 2.';
    } else if (grauDeRisco.value === '3' || grauDeRisco.value === '4') {
        return 'A norma Regulamentadora nº 07 (NR7) estabelece: “ No exame demissional pode ser dispensado caso o exame clínico ocupacional mais recente tenha sido realizado há menos de 90 (noventa) dias, para as organizações graus de risco 3 e 4.';
    }
    return 'Grau de Risco não identificado';
});
function openOverlay(event, funcionario, clinicoObrigatorio) {
    exameClinicoObrigatorio.value = clinicoObrigatorio;
    procedimentosEmDia.value = funcionario.exames.filter((p) => p.dataRefazerExame);
    grauDeRisco.value = funcionario.branch.riskLevel;
    toggle(event);
}

function onChangeRefazerExameEmDia() {
    emit('onChangeRefazerExameEmDia');
}

defineExpose({
    openOverlay
});
</script>
