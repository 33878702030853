<template>
    <Dialog v-model:visible="isOpen" header="Visualizar manual" class="p-fluid" :style="{ width: '60%', height: '90%' }"
        :contentStyle="{ height: '100%', overflow: 'hidden' }" draggable>
        <div v-if="possuiManual" :style="{ height: '100%', position: 'relative' }">
            <embed ref="pdfContent" :src="`${pdfUrl}#toolbar=0`" type="application/pdf" width="100%" height="100%" />
        </div>
        <div v-else>
            <p>Esta tela não possui manual cadastrado ainda.</p>
        </div>

        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="close" />
        </template>
    </Dialog>
</template>
<script>
import { getClientMicroserviceManual } from '@/services/http';

export default {
    data() {
        return {
            isOpen: false,
            possuiManual: false,
            pdfUrl: null
        };
    },
    methods: {
        async open(manualSistema) {
            this.possuiManual = !!manualSistema;
            if (this.possuiManual) {
                const { data } = await getClientMicroserviceManual().get(`manuais-sistema/verificar-manual-anexado/${manualSistema}`);
                if (data) {
                    this.pdfUrl = data.urlGed;
                }
            }

            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        }
    }
};
</script>
