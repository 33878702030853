<template lang="">
    <Dialog :style="{ width: '60vw' }" :header="title" :modal="modal">
        <div v-if="!loading && logs.length <= 0" class="h-full flex align-content-center justify-content-center align-items-center">
            <div class="flex log-aviso mr-2">Não há logs para este registro ainda!</div>
            <i class="icon-aviso pi pi-chart-bar"></i>
        </div>

        <DataTable v-if="!loading && logs.length" dataKey="id" :value="logs" :row-hover="true" class="p-datatable-sm" responsiveLayout="stack" breakpoint="640px">
            <template #empty> Nenhum registro encontrado. </template>
            <Column field="mensagem" header="Erro"></Column>
            <Column field="createdAt" header="Data">
                <template #body="{ data }">
                    {{ $filters.formatDate(data.createdAt) }}
                </template>
            </Column>
        </DataTable>

        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';

export default {
    emits: ['onClose'],
    props: {
        modal: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        },
        origem: {
            type: [String, Number],
            required: true
        },
        origemId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            logs: [],
            record: {},
            loading: false
        };
    },
    async mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                const { data } = await getClientBase().get(`/log-erro/${this.origem}/` + this.origemId);
                this.logs = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar.', life: 3000 });
                this.loading = false;
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.log-aviso {
    font-size: 13px;
    height: auto;
    color: #b6bfc7
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da
}
</style>