<template>
    <Calendar
        id="value"
        v-model="value"
        :disabledDays="disabledDays"
        :minDate="minDate"
        :maxDate="maxDate"
        :showIcon="true"
        autocomplete="off"
        dateFormat="dd/mm/yy"
        :showOnFocus="false"
        :placeholder="placeholder"
        selectionMode="range"
        v-bind="$attrs"
        showButtonBar
        @date-select="verificarDatas"
        @focus="verificarPrestadorNaoPossuiHorario"
    />
    <Toast />
</template>

<script setup>
import { computed, defineEmits, defineProps, watch } from 'vue';
import dayjs from 'dayjs';
import { useToast } from 'primevue/usetoast';
import { getClientBase } from '@/services/http';
import { PrestadorAgendamento } from '../prestador/calendario-prestador-context/PrestadorAgendamento';

import { usePrimeVue } from 'primevue/config';
import { verificaDiaNaoAtendidoEntre } from '../../utils/CalendarHorarioComercial';
import { CalendarRangeSLA } from '../../pages/gestao-medica-v3/agendamentos/criar-agendamento/steps/utils/calendarRangeSLA';

const emit = defineEmits(['update:modelValue', 'onGetPeriodoManhaVisible']);

const props = defineProps({
    modelValue: {
        type: Array
    },
    required: {
        type: Boolean,
        default: false
    },
    idCidade: {
        type: Number
    },
    idPrestador: {
        type: Number
    },
    periodo: {
        type: Number
    },
    disabledDays: {
        type: Array,
        default: () => [0, 6]
    },
    minDate: {
        type: Date,
        default: null
    },
    maxDate: {
        type: Date,
        default: null
    },
    limiteDatas: {
        type: Number
    },
    placeholder: {
        type: String,
        default: 'Range de datas'
    }
});

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

async function verificarDatas() {
    if (!value.value?.[1]) return;
    return (
        await Promise.all([
            isRangeDeDatasValido(),
            isPrestadorFeriasColetivas(),
            verificarPeriodoDeAgendamento(),
            isFeriadoMunicipal(),
            isFeriadoNacional(),
            verificarPrestadorNaoPossuiHorario(),
            verificarNaoSelecionouDisabledDays()
        ])
    ).every((valido) => valido);
}

const toast = useToast();

async function verificarPeriodoDeAgendamento() {
    const dataAtual = dayjs();
    const dataAtualSemHoras = dataAtual.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    const dataSelecionada = dayjs(value.value[0]);
    const dataSelecionadaSemHoras = dataSelecionada.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    const periodoManhaAtivo = !(dataSelecionadaSemHoras.isSame(dataAtualSemHoras) && dataAtual.get('hour') > 12);
    emit('onGetPeriodoManhaVisible', periodoManhaAtivo);
    const dataMenorQueAtual = dataSelecionada.isBefore(dataAtual, 'day');
    if (dataMenorQueAtual) {
        toast.add({
            severity: 'warn',
            detail: 'A data inicial não pode ser menor que a data atual',
            life: 3000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isFeriadoMunicipal() {
    if (!props.idCidade || !value.value[1]) return true;
    const path = `/feriados/municipal/${props.idCidade}`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriado = data;
    if (feriado.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, ' +
                dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                ' é feriado ' +
                feriado[0].abrangencia.toLowerCase() +
                ' em ' +
                feriado[0].cidade.cidade +
                '-' +
                feriado[0].cidade.estado +
                '!',
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isFeriadoNacional() {
    const path = `/feriados/nacional`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriado = data;
    if (feriado.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, ' +
                dayjs(feriado[0].dataFeriado).add(3, 'hour').format('DD/MM/YYYY') +
                ' é feriado ' +
                feriado[0].abrangencia.toLowerCase() +
                '!',
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function isPrestadorFeriasColetivas() {
    if (!props.idPrestador || !props.periodo) return true;
    const path = `/ferias/prestador/verificar/${props.idPrestador}`;
    const { data } = await getClientBase().get(path, {
        params: {
            dataInicial: value.value[0],
            dataFinal: value.value[1],
            periodo: props.periodo
        }
    });
    const feriasColetivas = data;
    if (feriasColetivas.length) {
        toast.add({
            severity: 'warn',
            summary:
                'Por favor selecione outro período de datas, o Prestador entrará em Férias no dia ' +
                dayjs(feriasColetivas[0].dataInicial).add(3, 'hour').format('DD/MM/YYYY') +
                ' e retornará no dia ' +
                dayjs(feriasColetivas[0].dataFinal).add(3, 'hour').format('DD/MM/YYYY') +
                '.',
            life: 6000
        });
        value.value = [];
        return false;
    }
    return true;
}
const primeVue = usePrimeVue();
async function verificarNaoSelecionouDisabledDays() {
    const [inicio, fim] = value.value;
    if (!inicio || !fim) return true;

    const nomeDiaNaoAtendido = verificaDiaNaoAtendidoEntre(inicio, fim, props.disabledDays, primeVue);

    if (nomeDiaNaoAtendido) {
        toast.add({
            severity: 'warn',
            summary: `Por favor selecione outro período de datas, o prestador não atende ${nomeDiaNaoAtendido} !`,
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}

async function verificarPrestadorNaoPossuiHorario() {
    return new PrestadorAgendamento(toast).verificarPrestadorNaoPossuiHorario(null, props.disabledDays);
}

watch(value, async () => {
    await verificarDatas();
});

watch(
    () => props.idCidade,
    async () => {
        await verificarDatas();
    }
);

watch(
    () => props.idPrestador,
    async () => {
        await verificarDatas();
    }
);

watch(
    () => props.periodo,
    async () => {
        await verificarDatas();
    }
);

async function isRangeDeDatasValido() {
    const rangeSLA = new CalendarRangeSLA(value.value, props.disabledDays);
    const mensagemErro = await rangeSLA.isRangeDeDatasInvalido();

    if (mensagemErro) {
        toast.add({
            severity: 'warn',
            summary: mensagemErro,
            life: 5000
        });
        value.value = [];
        return false;
    }
    return true;
}
</script>
