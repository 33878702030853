<template>
    <div>
        <slot v-if="$route.params.id" name="header"></slot>
        <AppLoadingWrapper v-if="loading && loadingEffect" />
        <DataTable
            :lasy="lazyEffect"
            :loading="loading"
            v-if="!loading || lazyEffect"
            dataKey="id"
            :value="records"
            editMode="row"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            v-model:editingRows="editingRows"
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column v-if="expander" :expander="true" headerStyle="width: 3rem" />

            <slot name="columns"></slot>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <Button 
                        icon="pi pi-pencil" 
                        v-if="showButtonAlterar && !editandoRecord[slotProps.data.id] && !editingRows.length > 0"
                        class="p-button-text p-button-secondary" 
                        @click="editarRecord(slotProps)" 
                    />
                    <Button 
                        icon="pi pi-check" 
                        v-if="editandoRecord[slotProps.data.id]" 
                        class="p-button-text p-button-secondary" 
                        @click="confirmarEdicaoRecord(slotProps)" 
                    />
                    <Button 
                        icon="pi pi-times" 
                        v-if="editandoRecord[slotProps.data.id]" 
                        class="p-button-text p-button-secondary" 
                        @click="cancelarEdicaoRecord(slotProps)" 
                    />
                </template>
            </Column>
            <Column v-if="showButtonRemover" bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <Button
                        v-if="!editandoRecord[slotProps.data.id] && !editingRows.length > 0"
                        icon="pi pi-trash" 
                        class="p-button-text p-button-secondary" 
                        @click="confirmDeleteRecord(slotProps.data)" />
                </template>
            </Column>
            <template v-if="expander" #expansion="slotProps">
                <slot name="expansion" v-bind:data="slotProps?.data"></slot>
            </template>
        </DataTable>

        <Paginator
            v-if="isPaginated"
            v-show="!loading"
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>

        <div class="mt-2 w-2 md:mt-0">
            <Button
                v-if="showButtonAdicionarItem"
                :disabled="editingRows.length > 0"
                :label="labelBotaoAdicionarItem" 
                icon="pi pi-plus" 
                class="p-button p-button-outlined mr-2" 
                @click="adicionarItem" 
            />
        </div>
        
        <AppDeleteDialog
            v-if="showDeleteRecordDialog"
            v-model:visible="showDeleteRecordDialog"
            :record="record"
            @onConfirm="onClickDeleteRecord"
            @onClose="onClickCloseDeleteRecord"
        >
        </AppDeleteDialog>
    </div>
</template>
<script>
import { showError, showSuccess } from '../../utils/Toast';
export default {
    props: {
        service: {
            type: Object,
            required: true
        },
        parentName: {
            type: String,
            required: true
        },
        defaultProps: {
            type: Object
        },
        queryParams: {
            type: Object,
            required: true
        },
        firstPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        expander: {
            type: Boolean,
            default: false
        },
        loadingEffect: {
            type: Boolean,
            default: true
        },
        showButtonAlterar: {
            type: Boolean,
            default: false
        },
        showButtonRemover: {
            type: Boolean,
            default: true
        },
        showButtonAdicionarItem: {
            type: Boolean,
            default: true
        },
        labelBotaoAdicionarItem: {
            type: String,
            default: 'Item'
        },
        isValid: {
            type: Boolean,
            default: true
        },
        defaultEdit: {
            type: Boolean,
            default: true
        },
        isPaginated: {
            type: Boolean,
            default: true
        }
    },
    emits: ['onAfterDelete', 'onValidate', 'onEditGrid', 'onAfterSave', 'onAfterLoad'],
    data() {
        return {
            records: [],
            expandedRows: [],
            record: {},
            loading: false,
            lazyEffect: false,
            total: 0,
            page: 1,
            currentPerPage: null,
            sort: null,
            currentSortField: null,
            currentSortOrder: null,
            recordDialog: false,
            showDeleteRecordDialog: false,
            editingRows: [],
            editandoRecord: {}
        };
    },
    mounted() {
        this.$service = this.service;
        this.page = this.firstPage;
        this.currentPerPage = this.perPage;
        this.load();
    },
    methods: {
        confirmDeleteRecord(record) {
            this.record = record;
            this.openDeleteRecordDialog();
        },
        openDeleteRecordDialog() {
            this.showDeleteRecordDialog = true;
        },
        async onClickDeleteRecord(record) {
            if (!record) {
                return;
            }
            
            try {
                await this.$service.remove(record.id);
                this.load();
                this.showDeleteRecordDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro excluído com sucesso!',
                    life: 3000
                });
                await this.$emit('onAfterDelete');
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 3000
                });
            }
        },
        onClickCloseDeleteRecord() {
            this.showDeleteRecordDialog = false;
        },
        async load(loadingEffect = true) {
            try {
                if (!this.$route.params.id) {
                    return;
                }

                if (this.loadingEffect && loadingEffect) {
                    this.loading = true;
                }
                const { data } = await this.$service.findByOrigem({ filter: this.queryParams }, this.parentName);
                this.records = this.isPaginated ? data.items : data;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.$emit('onAfterLoad', data.items);
                if (this.loadingEffect && loadingEffect) {
                    this.loading = false;
                }
            } catch (error) {
                if (this.loadingEffect && loadingEffect) {
                    this.loading = false;
                }
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao listar os registros! ' + message,
                    life: 3000
                });
            }
        },
        setLazyEffect() {
            this.loading = true;
            this.lazyEffect = true;
        },
        disableLazyEffect() {
            this.loading = false;
            this.lazyEffect = false;
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        adicionarItem() {
            const record = {
                id: -Math.random()*100
            };
            this.records.push(record);
            this.editingRows = [record];
            this.editandoRecord[record.id] = true;
        },
        async confirmarEdicaoRecord(slotProps) {
            const { index, data } = slotProps;
            try {
                await this.$emit('onValidate', data);
                if (!this.isValid) return;
                
                if (data?.id <= 0) {
                    delete data.id;
                }

                const response = await this.service.save({...this.defaultProps, ...data});
                data.id = response?.data?.id;
                this.records[index] = data;
                this.editingRows = [];
                this.editandoRecord[data.id] = false;
                showSuccess(this.$toast); 
                this.$emit('onAfterSave', data);
            } catch (error) {
                if (!data.id) {
                    this.records.splice(index, 1);
                    this.editingRows = [];
                }
                showError(this.$toast, error);
            }
            
        },
        editarRecord(slotProps) {
            if (!this.defaultEdit) {
                this.$emit('onEditGrid', slotProps.data);
                return;
            }
            const { data } = slotProps;
            this.editingRows = [data];
            this.editandoRecord[data.id] = true;
        },
        cancelarEdicaoRecord(slotProps) {
            const { data, index } = slotProps;
            this.editingRows = [];
            this.editandoRecord[data.id] = false;

            if (data.id <= 0) {
                this.records.splice(index, 1);
            }
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
</style>
