<template>
    <div>
        <Button class="p-button p-button-outlined" icon="pi pi-arrow-left" label="Voltar" @click="confirmBack"/>
        <AppConfirmDialog v-if="showConfirmDialog" v-model:visible="showConfirmDialog"
                          :loading="false" title="Você perderá todas as alterações, deseja continuar?"
                          @onClose="showConfirmDialog = false" @onConfirm="goBack"/>
    </div>
</template>
<script>
import AppConfirmDialog from '../../components/AppConfirmDialog.vue';

export default {
    components: {AppConfirmDialog},
    props: {
        path: {
            type: String
        },
        label: {
            type: String,
            default: 'Voltar'
        },
        formChanged: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {showConfirmDialog: false};
    },
    methods: {
        confirmBack() {
            if (this.formChanged) {
                this.showConfirmDialog = true;
            } else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push(this.path);
        }
    }
};
</script>
