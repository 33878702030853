<template>
    <Dialog v-model:visible="open" :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :closable="false"
        :header="props.agendamento.funcionario?.name ? dataAtualAtualizada + ' - Timeline de ' + props.agendamento.funcionario.name : 'Carregando...'"
        :style="{ width: '45vw' }">
        <TabView v-model:activeIndex="active">
            <TabPanel header="Timeline Interna">
                <TimelineInterna :agendamentoId="props.agendamento.id" />
            </TabPanel>
            <TabPanel header="Timeline Whatsapp">
                <TimelineWhatsapp v-if="active === 1" :agendamento="props.agendamento" />
            </TabPanel>
        </TabView>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-arrow-left" label="Voltar" @click="closeDialog" />
        </template>
    </Dialog>
</template>

<script setup>
import TimelineInterna from './TimelineExterna.vue';
import TimelineWhatsapp from './TimelineWhatsapp.vue';
import { defineProps, ref, defineExpose, computed } from 'vue';
import { useGlobalFilters } from '../../../../utils/useGlobalFilters';

const props = defineProps({
    agendamento: {
        type: Object,
        required: true
    }
});

const open = ref(false);
const active = ref(0);
const $filters = useGlobalFilters();

const dataAtualAtualizada = computed(() => {
    return $filters.formatDate(new Date());
});

function openDialog() {
    open.value = true;
}

function closeDialog() {
    open.value = false;
}

defineExpose({
    openDialog
});
</script>

<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>
