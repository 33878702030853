<template>
    <div>
        <div class="grid mx-4 overflow-auto" style="min-width: 600px">
            <div class="col-3">
                <Button
                    icon="pi pi-paperclip"
                    class="p-button-link text-lg"
                    :class="{ 'p-button-outlined p-button-danger': submittedAnexo && !form?.anexos?.filter((p) => p.tipo === 'LAUDO')?.length }"
                    @click="toggle"
                    label="Enviar anexo"
                />
                <OverlayAnexos ref="overlayAnexos" :funcionario="funcionario" tipoAnexo="LAUDO" />
            </div>
            <div class="col-3">
                <div class="flex align-content-center flex-wrap h-full text-lg font-medium">
                    {{ form.name }}
                </div>
            </div>
            <div class="col-3">
                <Calendar
                    v-model="form.dataLiberacao"
                    class="w-full"
                    id="dataLiberacao"
                    :showIcon="true"
                    dateFormat="dd/mm/yy"
                    selectionMode="single"
                    placeholder="Liberação"
                />
                <small v-if="submitted && !form.dataLiberacao && !funcionarioComLaudoPcd" class="p-error">Campo obrigatório.</small>
            </div>
            <div class="col-3">
                <Dropdown
                    v-model="form.tipoRetorno"
                    class="w-full"
                    id="tipoRetorno"
                    :options="tiposRetorno"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Motivo"
                />
                <small v-if="submitted && !form.tipoRetorno && !funcionarioComLaudoPcd" class="p-error">Campo obrigatório.</small>
            </div>
        </div>
        <Divider type="solid" class="mt-0" />
    </div>
</template>
<script setup>
import { computed, defineEmits, defineProps, ref, watch } from 'vue';
import OverlayAnexos from './components/OverlayAnexos.vue';
import verificarSeTemProcedimentoLaudoPcd from '@/pages/gestao-medica-v3/agendamentos/criar-agendamento/steps/utils/verificarSeTemProcedimentoLaudoPcd';

const emit = defineEmits(['onUpdate']);
const overlayAnexos = ref(null);
const props = defineProps({
    funcionario: Object,
    submitted: Boolean,
    submittedAnexo: Boolean
});
const form = computed({
    get() {
        return props.funcionario;
    },
    set(value) {
        emit('onUpdate', value);
    }
});
watch(
    () => form.value.dataLiberacao,
    () => {
        emit('onUpdate', form);
    }
);
watch(
    () => form.value.tipoRetorno,
    () => {
        emit('onUpdate', form);
    }
);
const tiposRetorno = ref([
    { label: 'B31 - Auxilio - Doença Previdenciário', value: 1 },
    { label: 'B91 - Auxilio - Doença por acidente do trabalho', value: 2 },
    { label: 'B94 - Auxilio - Acidente por acidente do trabalho', value: 3 },
    { label: 'Licença Maternidade', value: 4 },
    { label: 'Prestação do Serviço Militar', value: 5 }
]);

function toggle(event) {
    overlayAnexos.value?.toggle(event);
}

const funcionarioComLaudoPcd = computed(() => {
    return props.funcionario.procedimentos.some(verificarSeTemProcedimentoLaudoPcd);
});
</script>

<style lang="scss" scoped>
:deep(.p-panel-content) {
    margin: 0;
    padding: 0;
}
</style>
