<template>
    <div>
        <div v-if="isLoadingDadosPrestador" class="flex align-content-center justify-content-center flex-wrap gap-4 mt-3" style="height: 100%">
            <i class="pi pi-spin pi-spinner text-primary" style="font-size: 5rem"></i>
            <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%">Carregando informações...</span>
        </div>
        <div v-else-if="configFuncionarios.length" class="flex flex-column gap-4">
            <AgendarPainelFuncionario
                v-for="(item, index) of configFuncionarios"
                v-model:form-data="form"
                :configFuncionario="item"
                :configFuncionariosOriginal="configFuncionariosOriginal"
                :configFuncionarioIndex="index"
                :key="item.idFuncionario"
                @periodoAlterado="alterarPeriodo"
            />
        </div>
        <div v-if="funcionariosSemConfigComRisco.length" class="flex flex-column gap-4 mt-3">
            <DirecionarPainelFuncionario
                v-for="funcionario of funcionariosSemConfigComRisco"
                :key="funcionario.id"
                :funcionario="funcionario"
                motivo="Erro no cadastro de Procedimentos"
                @salvouFuncionario="removerFuncionario"
            />
        </div>
        <div v-if="funcionariosSemRisco.length" class="flex flex-column gap-4 mt-3">
            <DirecionarPainelFuncionario
                v-for="funcionario of funcionariosSemRisco"
                :key="funcionario.id"
                :funcionario="funcionario"
                motivo="Funcionário sem risco aplicado"
                @salvouFuncionario="removerFuncionario"
            />
        </div>
        <Toast />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, inject, nextTick, ref, watch } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import AgendarPainelFuncionario from './components/AgendarPainelFuncionario.vue';
import DirecionarPainelFuncionario from './components/DirecionarPainelFuncionario.vue';
import { clone } from 'ramda';

const toast = useToast();
const emit = defineEmits(['update:formData']);
const isLoadingDadosPrestador = ref(false);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const configFuncionarios = computed({
    get() {
        return props.formData.configFuncionarios || [];
    },
    set(value) {
        form.value.configFuncionarios = value;
    }
});
const funcionariosSemConfigComRisco = computed(() => {
    const { funcionarios } = form.value || { funcionarios: [] };
    const { value: configFuncionariosValue } = configFuncionarios;

    return (
        funcionarios?.filter((funcionario) => {
            const possuiConfig = configFuncionariosValue?.some((conf) => conf.idFuncionario === funcionario.id);
            const possuiRisco = funcionario.possuiRisco;

            return !possuiConfig && possuiRisco;
        }) || []
    );
});
const funcionariosSemRisco = computed(() => {
    return form.value.funcionarios?.filter((funcionario) => !funcionario.possuiRisco) || [];
});
const closeDialog = inject('closeDialog');
watch(
    () => [...configFuncionarios.value, ...funcionariosSemConfigComRisco.value, ...funcionariosSemRisco.value],
    (val) => {
        if (!val.length) {
            closeDialog();
        }
    },
    { deep: true }
);

async function start() {
    isLoadingDadosPrestador.value = true;
    await criarConfigFuncionarios();
    isLoadingDadosPrestador.value = false;
}

async function alterarPeriodo(novasConfigs) {
    [form.value.intervaloSugerido, form.value.periodo] = novasConfigs;
    await nextTick(async () => {
        await criarConfigFuncionarios();
    });
}

const configFuncionariosOriginal = ref([]);

async function criarConfigFuncionarios() {
    isLoadingDadosPrestador.value = true;
    const criarConfigFuncionarioDto = form.value.funcionarios
        .filter((funcionario) => funcionario.possuiRisco)
        .map((funcionario) => {
            return {
                idFuncionario: funcionario.id,
                nomeFuncionario: funcionario.name,
                companyId: funcionario.customer.companyId,
                funcionarioProcedimentos: funcionario.procedimentos.map((procedimento) => {
                    return {
                        id: procedimento.id,
                        name: procedimento.name,
                        procedimentoDiagnosticoId: procedimento.procedimentoDiagnosticoId,
                        idSugestao: procedimento.idSugestao
                    };
                }),
                idPrestador: form.value.prestador.id,
                cnpjPrestador: form.value.prestador.cnpj,
                nomePrestador: form.value.prestador.name
            };
        });
    try {
        const { data } = await getClientBase().post(`prestador-config-reserva/criar-config-funcionarios`, criarConfigFuncionarioDto);
        form.value.configFuncionarios = data;
        configFuncionariosOriginal.value = clone(data);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.response?.data?.message || error.message,
            life: 3000
        });
    }
    isLoadingDadosPrestador.value = false;
}

function removerFuncionario(idFuncionario) {
    form.value.funcionarios = form.value.funcionarios.filter((funcionario) => funcionario.id !== idFuncionario);
}

defineExpose({
    validarFormulario() {
        return [!configFuncionarios.value.length, 'É necessário agendar todos os funcionários antes de prosseguir'];
    },
    start
});
</script>
