<template>
    <DialogLinkAutoagendamento v-model:token="tokenAutoagendamento" />
    <DialogAguardandoConfirmacaoRisco v-model="dialogAguardandoConfirmacaoRisco" />
    <DialogExamesEnquadramentoLaudoPCD v-model="enquadramentoLaudoPCD" @agendamentoManual="agendamentoManual" />
    <Dialog
        v-model:visible="open"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '80vw' }"
        closable
        header="Criar agendamento"
        modal
        position="top"
    >
        <template #default>
            <div>
                <Steps :activeStep="passoAtual" :model="steps" class="mb-5 p-steps" />

                <router-view v-slot="{ Component }" v-model:formData="form">
                    <keep-alive>
                        <component
                            ref="stepComponent"
                            :is="Component"
                            @voltarPasso="voltarPasso"
                            @desabilitarBotaoAvancar="desabilitarBotaoAvancar"
                        />
                    </keep-alive>
                </router-view>
            </div>
        </template>

        <template #footer>
            <div class="w-full flex flex-column gap-2 md:grid md:block m-auto">
                <Button
                    v-if="podeVoltar"
                    :loading="isLoading"
                    class="p-button-outlined col px-5"
                    icon="pi pi-chevron-left"
                    icon-class="text-xs"
                    label="Voltar"
                    @click="voltarPasso"
                />
                <Button
                    v-if="podeAvancar"
                    :disabled="desabilitarAvancar"
                    :loading="isLoading"
                    class="p-button-plain md:ml-2 md:mr-0 col px-5"
                    icon="pi pi-chevron-right"
                    icon-class="text-xs"
                    icon-pos="right"
                    label="Avançar"
                    @click="validarFormulario"
                />
                <Button
                    v-else-if="funcionariosSemRisco?.length && form.autoagendamento"
                    :loading="isLoading"
                    class="p-button-plain md:ml-2 md:mr-0 col px-5"
                    label="Solicitar Confirmação de Risco"
                    @click="validarFormulario"
                />
                <Button
                    v-if="!podeAvancar && !isReservaDeHorario && !form.autoagendamento && form.prestador"
                    label="Criar pré-solicitação"
                    class="p-button-outlined md:ml-2 md:mr-0 col px-5"
                    :loading="isLoading"
                    @click="validarPreSolicitacao"
                />
                <Button
                    v-if="(!podeAvancar && !isReservaDeHorario && form.prestador) || (!podeAvancar && form.autoagendamento)"
                    :icon="form.autoagendamento ? 'pi pi-send' : ''"
                    :label="form.autoagendamento ? 'Enviar Link' : 'Agendar'"
                    :loading="isLoading"
                    class="p-button-plain md:ml-2 md:mr-0 col px-5"
                    @click="validarFormulario"
                />
                <Button
                    v-if="!podeAvancar && isReservaDeHorario && !possuiCadastroPrestadorReservaDeHorario && !form.autoagendamento"
                    label="Direcionar para um atendente"
                    :loading="isLoading"
                    class="p-button-plain md:ml-2 md:mr-0 col px-5"
                    @click="direcionarAgendamentosParaAtendente"
                />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { computed, defineEmits, defineExpose, ref, provide, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import isTipoExameValidoParaHabilitarStepAnexos from './steps/utils/isTipoExameValidoParaHabilitarStepAnexos';
import AgendamentoService from '../../../gestao_medica/agendamentos/services/AgendamentoService';
import PreSolicitacaoService from '../../../gestao_medica/agendamentos/services/PreSolicitacaoService';
import { getClientBase } from '@/services/http';
import DialogLinkAutoagendamento from '../DialogLinkAutoagendamento';
import DialogAguardandoConfirmacaoRisco from './components/DialogAguardandoConfirmacaoRisco.vue';
import TipoExame from '@/enums/TipoExame';
import { verificarExameOcupacional } from '@/utils/VerificarExameOcupacional';
import eventBus from '../../../../event-bus';
import DialogExamesEnquadramentoLaudoPCD from './components/DialogExamesEnquadramentoLaudoPCD.vue';
import { CriarAgendamentoDialogDto } from './steps/dtos/CriarAgendamentoDialogDto';
import { NovaFuncaoService } from '../../../funcionarios/components/NovaFuncao/NovaFuncaoService';

const open = ref(false);
const form = ref({});
const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const stepComponent = ref(null);
const toast = useToast();
const tokenAutoagendamento = ref('');
const dialogAguardandoConfirmacaoRisco = ref(false);
const emit = defineEmits(['load', 'recarregarTabela']);
const agendamentoService = new AgendamentoService(toast);
const preSolicitacaoService = new PreSolicitacaoService(toast);
const desabilitarAvancar = ref(false);
const openDialogOpcoes = ref(new CriarAgendamentoDialogDto());

provide('isLoading', isLoading);

onMounted(() => {
    eventBus.on('onOpenDialogFormAgendamento', (opcoes) => {
        openDialog(opcoes);
    });
});

const steps = computed(() => {
    const items = [
        {
            label: 'Dados do solicitante',
            to: '/gestao-medica-v3/agendamentos/dados-solicitante',
            visible: true
        },
        {
            label: 'Dados do agendamento',
            to: '/gestao-medica-v3/agendamentos/dados-agendamento',
            visible: !form.value.autoagendamento
        },
        {
            label: 'Laudo PCD',
            to: '/gestao-medica-v3/agendamentos/pcd',
            visible: form.value.funcionarios?.some((funcionario) => funcionario.pcd) && !form.value.autoagendamento
        },
        {
            label: 'Procedimentos',
            to: '/gestao-medica-v3/agendamentos/procedimentos',
            visible: !form.value.autoagendamento
        },
        {
            label: 'Anexos',
            to: '/gestao-medica-v3/agendamentos/anexos',
            visible: isTipoExameValidoParaHabilitarStepAnexos(form.value.tipoExame?.id) && !form.value.autoagendamento
        },
        {
            label: 'Definir clínica',
            to: '/gestao-medica-v3/agendamentos/definir-clinica',
            visible: !form.value.autoagendamento
        },
        {
            label: 'Agendar Procedimentos',
            to: '/gestao-medica-v3/agendamentos/agendar',
            visible: isReservaDeHorario.value && !form.value.autoagendamento && possuiCadastroPrestadorReservaDeHorario.value
        },
        {
            label: 'Autoagendamento ',
            to: '/gestao-medica-v3/agendamentos/autoagendamento',
            visible: form.value.autoagendamento
        },
        {
            label: 'Dados do Funcionário',
            to: '/gestao-medica-v3/agendamentos/dados-funcionario',
            visible: form.value.autoagendamento
        }
    ];

    return items.filter((item) => item.visible);
});

const passoAtual = computed(() => {
    return steps.value.findIndex((step) => step.to === route.path);
});

const podeAvancar = computed(() => {
    return passoAtual.value < steps.value.length - 1;
});

const podeVoltar = computed(() => {
    return passoAtual.value > 0;
});

const prestadorUsaSoc = computed(() => {
    return form.value?.prestador?.systemsAndTechnology?.name === 'SOC';
});

const funcionarioPossuiRisco = computed(() => {
    return form.value?.funcionarios?.some((funcionario) => funcionario.possuiRisco);
});

const funcionariosSemRisco = computed(() => {
    return form.value?.funcionarios?.filter((funcionario) => !funcionario.possuiRisco);
});

const validarClienteUsaReservaHorario = computed(() => {
    return form.value?.cliente?.usaReservaHorario;
});

const houveramFuncionariosComRisco = ref(false);
watch(funcionarioPossuiRisco, (val) => (houveramFuncionariosComRisco.value = houveramFuncionariosComRisco.value || val));
function setHouveramFuncionariosComRisco() {
    houveramFuncionariosComRisco.value = form.value?.funcionarios?.some((funcionario) => funcionario.possuiRisco);
}

provide('setHouveramFuncionariosComRisco', setHouveramFuncionariosComRisco);

const isReservaDeHorario = computed(() => {
    const tipoExamesImpedidos = [
        TipoExame.RETORNO_AO_TRABALHO,
        TipoExame.CONSULTA,
        TipoExame.TERCEIROS,
        TipoExame.AVALIACAO_DE_ATESTADO,
        TipoExame.MONITORACAO_PONTUAL
    ];

    return (
        prestadorUsaSoc.value &&
        houveramFuncionariosComRisco.value &&
        validarClienteUsaReservaHorario.value &&
        verificarExameOcupacional(form.value.tipoExame?.id) &&
        !tipoExamesImpedidos.includes(form.value.tipoExame?.id) &&
        !form.value.funcionarios?.some((funcionario) => funcionario.pcd)
    );
});
provide('isReservaDeHorario', isReservaDeHorario);

const possuiCadastroPrestadorReservaDeHorario = ref(false);
provide('possuiCadastroPrestadorReservaDeHorario', possuiCadastroPrestadorReservaDeHorario);

function setPossuiCadastroPrestadorReservaDeHorario(val) {
    possuiCadastroPrestadorReservaDeHorario.value = val;
}

provide('setPossuiCadastroPrestadorReservaDeHorario', setPossuiCadastroPrestadorReservaDeHorario);

function openDialog(opcoes) {
    form.value = {};
    router.push('/gestao-medica-v3/agendamentos/dados-solicitante');
    open.value = true;
    openDialogOpcoes.value = new CriarAgendamentoDialogDto();
    openDialogOpcoes.value = opcoes;
}

function closeDialog() {
    form.value = {};
    open.value = false;
    emit('recarregarTabela');
}

provide('closeDialog', closeDialog);

function voltarPasso() {
    if (desabilitarAvancar.value) {
        desabilitarAvancar.value = false;
    }
    if (podeVoltar.value) {
        form.value.enquadramentoLaudoPCD = undefined;
        router.push(steps.value[passoAtual.value - 1].to);
    }

    if (passoAtual.value === 1 && form.value.autoagendamento) {
        form.value.autoagendamento = false;
        form.value.possuiRisco = false;
        form.value.funcionarios = [];
        form.value.enquadramentoLaudoPCD = undefined;
    }
}

function desabilitarBotaoAvancar(value) {
    desabilitarAvancar.value = value;
}

const excecoesValidacoes = computed(() => {
    const excecoes = [];
    const tipoExamesPrecisaDeValicacao = [TipoExame.CONSULTA, TipoExame.TERCEIROS];
    if (!tipoExamesPrecisaDeValicacao.includes(form.value.tipoExame?.name)) {
        excecoes.push(['Procedimentos']);
    }
    return excecoes;
});

function deveEmitirErro() {
    return !excecoesValidacoes.value.includes(steps.value[passoAtual.value].label);
}

async function avancarPasso() {
    await router.push(steps.value[passoAtual.value + 1].to);
    stepComponent.value?.start?.();
}

const enquadramentoLaudoPCD = ref(false);
async function validarFormulario() {
    const resultValidarFormulario = await stepComponent.value?.validarFormulario();
    const formularioValido = Array.isArray(resultValidarFormulario) ? resultValidarFormulario[0] : resultValidarFormulario;
    const mensagemErro = Array.isArray(resultValidarFormulario) ? resultValidarFormulario[1] : 'Preencha todos os campos corretamente';

    if (!formularioValido && deveEmitirErro()) {
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: mensagemErro,
            life: 3000
        });
        return;
    }

    if (form.value.enquadramentoLaudoPCD === 'sim') {
        enquadramentoLaudoPCD.value = true;
        return;
    }
    if (podeAvancar.value) avancarPasso();
    else if (form.value.autoagendamento) autoagendar();
    else validarAgendamento();

    return formularioValido;
}

provide('agendar', validarFormulario);

async function validarPreSolicitacao() {
    isLoading.value = true;
    const formularioValido = await stepComponent.value?.validarFormulario();
    if (formularioValido) {
        await salvarPreSolicitacao();
        emit('recarregarTabela');
    } else if (deveEmitirErro()) {
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Preencha todos os campos corretamente',
            life: 3000
        });
    }
    isLoading.value = false;
}

provide('preSolicitar', validarPreSolicitacao);

async function salvarPreSolicitacao() {
    let success = true;
    for (const funcionario of form.value.funcionarios) {
        funcionario.procedimentosDtoCopia = funcionario.procedimentos;

        const result = await preSolicitacaoService.save(form.value, funcionario);
        if (!result) {
            success = false;
        }
    }
    if (success) {
        closeDialog();
    }
}

async function direcionarAgendamentosParaAtendente() {
    const comando = 'direcionarAtendente';
    await validarAgendamento(comando);
}

async function validarAgendamento(comando) {
    isLoading.value = true;
    const formularioValido = await stepComponent.value?.validarFormulario();

    if (formularioValido) {
        if (comando === 'direcionarAtendente') {
            form.value.funcionarios.forEach((funcionario) => {
                funcionario.direcionarParaAtendente = true;
            });
        }
        await salvarAgendamentos();
    } else if (deveEmitirErro()) {
        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Preencha todos os campos corretamente',
            life: 3000
        });
    }
    isLoading.value = false;
}

async function salvarAgendamentos() {
    const promises = [];
    for (const funcionario of form.value.funcionarios) {
        promises.push(salvarAgendamento(funcionario));
    }
    const resultados = await Promise.all(promises);
    if (resultados.every((resultado) => resultado)) {
        closeDialog();
    }
}

async function salvarAgendamento(funcionario) {
    if (form.value.examesEmDia) {
        verificaExameClinico(funcionario);
        removerExamesRejeitados(funcionario);
    }
    const agendamentoSalvo = await agendamentoService.save(form.value, funcionario);
    await removePendenciaSeSolicitado(funcionario);
    return agendamentoSalvo;
}

async function removePendenciaSeSolicitado(funcionario) {
    if (
        !openDialogOpcoes.value?.funcionario?.removePendenciaNovaFuncaoId ||
        openDialogOpcoes.value?.funcionario?.removePendenciaNovaFuncaoId !== funcionario?.id
    ) {
        return;
    }

    let removido;
    try {
        removido = await new NovaFuncaoService().retirarPendenciaFuncionario(funcionario.id);
    } catch (err) {
        console.error({ removido, err });
    }
}

provide('removePendenciaSeSolicitado', removePendenciaSeSolicitado);
provide('salvarAgendamento', salvarAgendamento);

function verificaExameClinico(funcionario) {
    funcionario.exames.find((el) => el.procedimento.clinical).aceitar = true;
}

function removerExamesRejeitados(funcionario) {
    const examesId = funcionario.exames.map((el) => el.procedimento.id);
    const examesAceitarId = funcionario.exames?.filter((el) => el.aceitar).map((el) => el.procedimento.id);
    const procedimentosAdicionados = funcionario.procedimentos.filter((el) => !examesId.includes(el.id));
    funcionario.procedimentos = funcionario.procedimentos?.filter((el) => examesAceitarId.includes(el.id));
    funcionario.procedimentos.push(...procedimentosAdicionados);
}

async function autoagendar() {
    isLoading.value = true;
    try {
        for (const funcionario of form.value?.funcionarios || []) {
            const { data } = await getClientBase().post('/autoagendamento', {
                ...form.value,
                funcionario
            });

            open.value = false;
            if (!funcionariosSemRisco.value.length) {
                const { data: token } = await getClientBase().post('/autoagendamento/token', {
                    cpf: funcionario.cpf,
                    agendamentoId: data.agendamentoId
                });
                tokenAutoagendamento.value = token;
            } else dialogAguardandoConfirmacaoRisco.value = true;
        }
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message,
            life: 5000
        });
    }
    emit('load');
    isLoading.value = false;
}

function agendamentoManual() {
    form.value.autoagendamento = false;
    enquadramentoLaudoPCD.value = false;
    form.value.enquadramentoLaudoPCD = undefined;
}

defineExpose({
    openDialog
});
</script>

<style lang="scss" scoped>
:deep(.p-steps) {
    @media (max-width: 960px) {
        height: 20px;

        .p-steps-title {
            opacity: 0;
            width: 0;
        }
    }
}

:deep(.p-button) {
    flex-basis: initial;
}
</style>
