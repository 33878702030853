<template lang="">
    <Dialog :style="{ width: '40vw' }" :modal="modal">
        <div v-if="loading" style="height: 50vh">
            <AppLoadingWrapper style="height: 50vh" />
        </div>
        <div style="padding: 5px" v-show="!loading">
            <p class="text-3xl font-bold">Histórico de ações</p>
            <div class="input-container">
                <div class="input-group">
                    <label for="pesquisa">Pesquisa</label>
                    <div class="p-inputgroup">
                        <Chips
                            ref="chippesquisa"
                            v-model="filterUsers"
                            placeholder="Usuário"
                            style="width: 100%; margin-top: 5px !important; height: 35px"
                            @add="load"
                            @remove="removeFiltro"
                        />
                    </div>
                </div>
                <div class="input-group">
                    <label>Intervalo de datas</label>
                    <div class="p-inputgroup">
                        <Calendar
                            style="margin-top: 5px !important"
                            v-model="intervaloData"
                            :showIcon="true"
                            placeholder="/ /"
                            autocomplete="off"
                            dateFormat="dd/mm/yy"
                            :showOnFocus="false"
                            selectionMode="range"
                        />
                    </div>
                </div>
            </div>
            <div v-if="!logs?.length" class="no-logs-found">
                <p>Nenhum log encontrado!</p>
            </div>
            <div class="timeline-container" v-if="logs && logs?.length">
                <div
                    v-for="(log, index) in logs"
                    :key="index"
                    :class="this.logs.length - 1 === index ? 'timeline-last-child-transparent' : 'timeline-item'"
                >
                    <div class="timeline-icon">
                        <i class="pi pi-clock" style="font-size: 1.2rem"></i>
                    </div>
                    <div class="timeline-content">
                        <div class="timeline-text">
                            <div class="timeline-name" style="color: #71767a">{{ log.usuarioNome }}</div>
                            <div class="timeline-description" style="color: #71767a">{{ enumMethods[log.metodo] }}</div>
                        </div>
                        <div class="timeline-date">{{ log.dataCriacao }}</div>
                    </div>
                </div>
            </div>
            <Paginator
                ref="paginator"
                v-show="!loading && logs && logs?.length"
                :rows="10"
                :totalRecords="totalRecords"
                @page="onPage($event)"
                paginatorTemplate=" PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {last} de {totalRecords} registros"
            >
            </Paginator>
        </div>
    </Dialog>
</template>
<script>
import { getClientMicroserviceLogAcao } from '@/services/http';
import AppLoadingWrapper from './AppLoadingWrapper.vue';
import { getCurrentCompany } from '@/services/store';
import EnumMethodMapping from '@/enums/EnumMethodMapping';
import dayjs from 'dayjs';
export default {
    components: { AppLoadingWrapper },
    props: {
        modal: {
            type: Boolean,
            default: true
        },
        origem: {
            type: String
        },
        origem_id: {
            type: Number
        },
        limit: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            loading: false,
            pesquisa: '',
            intervaloData: null,
            logs: [],
            totalRecords: 0,
            offset: 1,
            page: 1,
            filterUsers: null,
            company_id: null,
            enumMethods: EnumMethodMapping
        };
    },
    watch: {
        async intervaloData() {
            if (this.intervaloData[0] && this.intervaloData[1]) {
                await this.getFindFilterLog();
            }
        }
    },
    methods: {
        async getFindFilterLog() {
            this.loading = true;
            try {
                let url = `/log-acoes?empresaId=${this.company_id}&origem=${this.origem}&origemId=${this.origem_id}&limit=${this.limit}&desvio=${this.offset}`;
                if (this.intervaloData && this.intervaloData?.[0] && this.intervaloData?.[1]) {
                    const dataInicial = dayjs(this.intervaloData[0]).format('DD/MM/YYYY');
                    const dataFinal = dayjs(this.intervaloData[1]).format('DD/MM/YYYY');
                    url += `&dataInicio=${dataInicial}&dataFim=${dataFinal}`;
                }
                if (this.filterUsers && this.filterUsers?.length) {
                    url += `&usuarioNome=${this.filterUsers}`;
                }
                const { data } = await getClientMicroserviceLogAcao().get(url);
                this.logs = data?.registros;
                this.totalRecords = Number(data?.count);
            } catch (error) {
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Nenhum log encontrado!',
                    detail: message,
                    life: 3000
                });
            }
            this.loading = false;
        },
        async load() {
            this.offset = 0;
            await this.getFindFilterLog();
        },
        async removeFiltro(event) {
            this.filterUsers = this.filterUsers.filter((e) => e !== event.value[0]);
            await this.load();
        },
        async onPage(event) {
            this.offset = event.first;
            this.page = event.page + 1;
            await this.getFindFilterLog();
        }
    },
    async mounted() {
        this.company_id = await getCurrentCompany();
        await this.getFindFilterLog();
    }
};
</script>
<style scoped>
.input-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.input-group {
    margin: 0 20px 0 0;
    width: 100% !important;
}

.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 40px 0 0 0;
}

.timeline-item {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
    width: 100%;
    position: relative;
}

.timeline-icon {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timeline-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.timeline-name {
    font-weight: bold;
    color: #71767a;
}

.timeline-date {
    font-weight: 500;
    color: #71767a;
}

.timeline-description {
    margin-top: 5px;
}

.timeline-text {
    display: flex;
    flex-direction: column;
}

.timeline-line {
    width: 2px;
    background-color: #ccc;
    margin-left: 20px;
    height: 100%;
}

.timeline-item::before {
    content: '';
    width: 1px;
    height: 20px;
    background: rgb(192, 192, 192);
    position: absolute;
    bottom: 0;
    left: 6px;
}

.timeline-last-child-transparent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.timeline-last-child-transparent::before {
    display: flex;
    justify-content: space-between;
    content: '';
    width: 1px;
    height: 20px;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 2px;
}

.no-logs-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}
</style>
