import { computed, ref } from 'vue';
import { useAgendamentoStatusValidator } from '../../../agendamento-atendimento/composables/useAgendamentoStatusValidator';
import AreaMedicaService from '@/services/AreaMedicaService';
import { getCompany } from '@/services/auth';

export function useAgendamentosFiltros(props, emit) {
    const dialogFiltros = ref(null);
    const serviceStatus = new AreaMedicaService('/status_agendamento');
    const serviceTipoExames = new AreaMedicaService('/tipo_exames');
    const { enumStatusAgendamento } = useAgendamentoStatusValidator();

    const _filter = computed({
        get() {
            return props.filter;
        },
        set(value) {
            emit('update:filter', value);
        }
    });

    const _filtrosExtras = computed({
        get() {
            return props.filtrosExtras;
        },
        set(value) {
            emit('update:filtrosExtras', value);
        }
    });

    const _status = computed({
        get() {
            return props.status;
        },
        set(value) {
            emit('update:status', value);
        }
    });

    const _exames = computed({
        get() {
            return props.exames;
        },
        set(value) {
            emit('update:exames', value);
        }
    });

    async function carregarFiltros() {
        dialogFiltros?.value.openDialogFiltro();
        await Promise.all([loadStatus(), loadExames()]);
    }

    async function loadStatus() {
        const { data } = await serviceStatus.findAll({});
        _status.value = data;
        const statusPendenciaPrestador = _status.value.find((p) => p.id === enumStatusAgendamento.PENDENCIA_PRESTADOR);
        if (statusPendenciaPrestador) {
            const company = await getCompany();
            statusPendenciaPrestador.descricao = 'Pendencia ' + company.name;
        }
    }

    async function loadExames() {
        const { data } = await serviceTipoExames.findAll({});
        _exames.value = data;
    }

    return { _filter, _filtrosExtras, _status, _exames, dialogFiltros, carregarFiltros };
}
