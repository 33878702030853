<template>
    <Dialog
        v-model:visible="open"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :modal="true"
        :style="{ width: '80vw' }"
        header="Pré solicitações"
        position="top"
        @hide="closeDialog"
    >
        <Toolbar class="mb-4">
            <template #start>
                <DeletarPresolicitacoesButton :selectedRequests="selectedRequests" :loading="loading" :loadFx="load" />
            </template>
        </Toolbar>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            :selection="selectedRequests"
            @update:selection="
                (selected) => {
                    if (solicitador.loading) {
                        return;
                    }
                    selectedRequests = selected;
                }
            "
            :filters="filters"
            :paginator="true"
            :rows="10"
            :rowsPerPageOptions="[5, 10, 25]"
            :value="records"
            currentPageReportTemplate="Mostrando {last} de {totalRecords} registros"
            dataKey="id"
            editMode="cell"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            responsiveLayout="scroll"
            @cell-edit-complete="editCell"
        >
            <Column :expander="true" headerStyle="width: 3rem"></Column>
            <Column :exportable="false" selectionMode="multiple" style="flex: 0 0 4rem"></Column>
            <Column :sortable="true" field="nomeFuncionario" header="Funcionario" style="min-width: 12rem"></Column>
            <Column :sortable="true" field="telefoneFuncionario" header="Telefone*" style="min-width: 10rem">
                <template #body="{ data }">
                    <div>
                        <InputMask
                            v-model="data.telefoneFuncionario"
                            :class="{ 'p-invalid mt-4': mascaraErro(data) || !data.telefoneFuncionario }"
                            autofocus
                            mask="(99) 99999-9999"
                            @change="saveCampos(data)"
                        />
                    </div>
                    <small v-if="mascaraErro(data)" class="p-error">Formato do telefone está errado.</small>
                    <small v-if="!data.telefoneFuncionario" class="p-error">Telefone é Obrigatório.</small>
                </template>
            </Column>
            <Column :sortable="true" field="emailFuncionario" header="Email(Opcional)" style="min-width: 8rem">
                <template #body="{ data, field }">
                    <InputText v-model="data[field]" autofocus @change="saveCampos(data)" />
                </template>
            </Column>
            <Column :sortable="true" field="tipoExame.descricao" header="Tipo de exame" style="min-width: 10rem" />
            <Column :sortable="true" :style="{ padding: '1rem 0.5rem' }" field="data.statusSolicitacao" header="Status">
                <template #body="{ data }">
                    <div v-if="preSolicitacaoTemPendenciaPorStatus(data)" class="field mt-1 mb-0" style="text-align: center">
                        <i
                            v-tooltip="'Campos adicionais obrigatórios'"
                            class="pi pi-exclamation-circle"
                            style="font-size: 2rem; color: #ff9900; cursor: pointer"
                            @click="exibirPendencias(data)"
                        />
                    </div>
                    <Tag v-else :style="getStatusColor(data.statusSolicitacao)" :value="getDescriptionStatus(data.statusSolicitacao)" class="mr-2">
                    </Tag>
                </template>
            </Column>
            <Column field="horariosAgenda" header="Período de agendamento" style="min-width: 12rem">
                <template #body="{ data }">
                    <div v-if="data.statusSolicitacao === 2">
                        {{ $filters.formatDateOnly(data.dataSugestaoInicial) + ' - ' + $filters.formatDateOnly(data.dataSugestaoFinal) }}
                    </div>
                    <div v-if="data.statusSolicitacao === 1">
                        <Dropdown
                            :options="horarios[data.id]"
                            :virtualScrollerOptions="{ loading: horariosLoading[data.id], showLoader: true, itemSize: 30 }"
                            placeholder="Horarios da agenda"
                            @change="onChangeHorario($event, data)"
                            @before-show="carregarHorarios(data)"
                        >
                            <template #value="{ placeholder }">
                                <span v-if="data.dataReserva">
                                    {{ $filters.formatDayMonth(data.dataReserva) }} | {{ data.horarioInicial?.substring(0, 5) }} -
                                    {{ data.horarioFinal?.substring(0, 5) }}
                                </span>
                                <span v-else>
                                    {{ placeholder }}
                                </span>
                            </template>
                            <template #option="{ option }">
                                <span
                                    >{{ $filters.formatDayMonth(data.dataSugestaoInicial) }} | {{ option.horarioInicial }} -
                                    {{ option.horarioFinal }}</span
                                >
                            </template>
                            <template v-slot:loader="{ options }">
                                <div class="flex align-items-center p-2" style="height: 38px">
                                    <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </template>
            </Column>
            <template #expansion="{ data }">
                <DetalhesPresolicitacao :data="data" />
            </template>
            <template #footer>
                <div style="text-align: right">
                    <Button
                        :disabled="loading"
                        :loading="loading"
                        class="submit p-button-success"
                        icon="pi pi-check"
                        label="Solicitar"
                        @click="iniciarAgendamento()"
                    />
                </div>
            </template>
        </DataTable>
    </Dialog>
</template>

<script setup>
import { ref, defineExpose, onMounted, computed } from 'vue';
import EnumTipoExame from '../../../enums/TipoExame';
import AreaService from '@/services/AreaMedicaService';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
import BaseService from '@/services/BaseService';
import { useGlobalFilters } from '@/utils/useGlobalFilters';
import { SolicitadorPreSolicitacao } from './dialog-pre-solicitacao/utils/SolicitadorPreSolicitacao';
import DetalhesPresolicitacao from './dialog-pre-solicitacao/components/DetalhesPresolicitacao.vue';
import DeletarPresolicitacoesButton from './dialog-pre-solicitacao/components/DeletarPresolicitacoesButton.vue';

const open = ref(false);

const selectedRequests = ref([]);
const expandedRows = ref([]);
const filters = ref({});
const records = ref([]);
const tipoExame = ref([]);
const toast = useToast();
const showModalAvisoPreSolicitacoes = ref(false);
const perPage = ref();
const page = ref();
const filter = ref();
const total = ref();
const totalPage = ref();
const record = ref();
const customers = ref();
const branches = ref();
const departments = ref();
const positions = ref();
const oldHierarchyid = ref();
const pendencias = ref();
const dialogPendencias = ref(false);
const horarios = ref({});
const horariosLoading = ref({});

const solicitador = ref(new SolicitadorPreSolicitacao());
const loading = computed(() => solicitador.value?.loading);
const $filters = useGlobalFilters();
const service = new AreaService('/pre-solicitacoes');
const serviceClients = new BaseService('/customers');
const serviceBranches = new BaseService('/customer/branches/combo');
const serviceDepartments = new BaseService('/customer/departments/combo');
const servicePositions = new BaseService('/customer/positions/combo');
const serviceAgendaHorarios = new AreaService('/agendas/horarios/consultar');

function openDialog() {
    load();
    open.value = true;
}

async function load() {
    solicitador.value.loading = true;
    await recarregarGrid();
    solicitador.value.loading = false;
}

async function recarregarGrid() {
    const { data } = await service.findAll({ limit: perPage.value, page: page.value, filter: filter.value });
    records.value = data;
    total.value = data.total;
    const calculoPaginacao = data.total / perPage.value;
    totalPage.value = calculoPaginacao === Math.floor(calculoPaginacao) ? calculoPaginacao : Math.floor(calculoPaginacao) + 1;
    const preSolicitacoesQuantidade = data.map((preSolicitacao) => preSolicitacao);
    const user = data.map((user) => user);
    if (preSolicitacoesQuantidade.length && user[0].user.exibePreSolicitacaoNotificacao === true) {
        showModalAvisoPreSolicitacoes.value = true;
    }
}

function closeDialog() {
    open.value = false;
}

async function editCell(event) {
    let { data, newValue, field } = event;
    if (data[field] !== newValue) {
        if (newValue.trim().length > 0) {
            data[field] = newValue;
            data.telefoneColaborador = data.telefoneColaborador
                ? data.telefoneColaborador.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                : null;
            data.telefoneFuncionario = data.telefoneFuncionario
                ? data.telefoneFuncionario.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
                : null;
            for (let i = 0; i < tipoExame.value.length; i++) {
                if (tipoExame.value[i].descricao === data.nomeTipoExame) {
                    data.tipoExame = tipoExame.value[i].id;
                }
            }
            if (data) {
                try {
                    await service.save(data);
                    await recarregarGrid();
                    toast.add({
                        severity: 'success',
                        summary: 'Dado atualizado com sucesso',
                        life: 3000
                    });
                } catch (err) {
                    toast.add({
                        severity: 'success',
                        summary: 'Dado atualizado com sucesso',
                        life: 3000
                    });
                }
            }
        } else {
            event.preventDefault();
        }
    }
}

function mascaraErro(data) {
    if (!data.telefoneFuncionario) {
        return;
    }
    return data.telefoneFuncionario.length < 11;
}

async function saveCampos(data) {
    try {
        await service.save(data);
        await recarregarGrid();
        toast.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 });
    } catch (err) {
        toast.add({ severity: 'success', summary: 'Dado atualizado com sucesso', life: 3000 });
    }
}

function preSolicitacaoTemPendenciaPorStatus(agendamento) {
    const idStatus = agendamento.tipoExame.id;
    if (agendamento.pendencia === 'NAO') {
        return false;
    }

    if (agendamento.pcd) {
        return true;
    }

    if (
        idStatus === EnumTipoExame.RETORNO_AO_TRABALHO ||
        idStatus === EnumTipoExame.CONSULTA ||
        idStatus === EnumTipoExame.DEMISSIONAL ||
        idStatus === EnumTipoExame.ABONO_ATESTADO
    ) {
        return true;
    }
    if (agendamento.cliente.desabilitarQuestionarioRisco) {
        return false;
    }
    if (agendamento.pendencia === 'SIM') {
        return true;
    }
    return !!agendamento.funcionarioSemRiscos;
}

async function exibirPendencias(solicitacao) {
    const { data } = await service.findById(solicitacao.id);
    record.value = data;
    if (record.value.tipoExame.id === EnumTipoExame.MUDANCA_FUNCAO) {
        customers.value = await loadCustomers(record.value.funcionario.customerId);
        branches.value = await loadBranches(customers.value.id);
        departments.value = await loadDepartments(customers.value.id);
        positions.value = await loadPositions(customers.value.id);
        record.value.funcionario.changeDate = null;
        record.value.funcionario.customerHierarchy.customerBranchId = null;
        record.value.funcionario.customerHierarchy.customerDepartmentId = null;
        record.value.funcionario.customerHierarchy.customerPositionId = null;
        oldHierarchyid.value = record.value.funcionario.customerHierarchy.id;
    }
    pendencias.value = JSON.parse(JSON.stringify(record));
    dialogPendencias.value = true;
}

async function loadCustomers(id) {
    const { data } = await serviceClients.findById(id);
    return data;
}

async function loadBranches(id) {
    const { data } = await serviceBranches.findAll({ customerId: id });
    return data;
}

async function loadDepartments(id) {
    const { data } = await serviceDepartments.findAll({ customerId: id });
    return data;
}

async function loadPositions(id) {
    const { data } = await servicePositions.findAll({ customerId: id });
    return data;
}

function getStatusColor(status) {
    const Status = {
        1: 'background-color: var(--green-200); color: var(--green-900)',
        2: 'background-color: var(--yellow-200); color: var(--yellow-900)'
    };
    return Status[status];
}

function getDescriptionStatus(status) {
    const Status = {
        1: 'Pré-agendado',
        2: 'Pré-solicitado'
    };
    return Status[status];
}

async function onChangeHorario(event, preRequest) {
    await service.save({
        id: preRequest.id,
        dataReserva: event.value.dataReserva,
        horarioInicial: event.value.horarioInicial,
        horarioFinal: event.value.horarioFinal
    });
    preRequest.dataReserva = event.value.dataReserva;
    preRequest.horarioInicial = event.value.horarioInicial;
    preRequest.horarioFinal = event.value.horarioFinal;
}

async function carregarHorarios(preRequest) {
    try {
        horariosLoading.value[preRequest.id] = true;
        const { data } = serviceAgendaHorarios.findAll({
            idsProcedures: 1,
            dataInicial: preRequest.dataSugestaoInicial,
            dataFinal: preRequest.dataSugestaoFinal
        });
        horarios.value[preRequest.id] = data.horarios;
    } finally {
        horariosLoading.value[preRequest.id] = false;
    }
}

onMounted(() => {
    initFilters();
});

function initFilters() {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
}

async function iniciarAgendamento() {
    await solicitador.value.iniciarAgendamentos(records.value, selectedRequests.value, toast);
    await recarregarGrid();
    selectedRequests.value = [];
}

defineExpose({
    openDialog
});
</script>
