const EnumDiaDaSemana = {
    SEG: 'SEG',
    TER: 'TER',
    QUA: 'QUA',
    QUI: 'QUI',
    SEX: 'SEX',
    SAB: 'SAB',
    DOM: 'DOM'
}

export default EnumDiaDaSemana;

export const diaDaSemanaPorIndice = {
    0: EnumDiaDaSemana.DOM,
    1: EnumDiaDaSemana.SEG,
    2: EnumDiaDaSemana.TER,
    3: EnumDiaDaSemana.QUA,
    4: EnumDiaDaSemana.QUI,
    5: EnumDiaDaSemana.SEX,
    6: EnumDiaDaSemana.SAB,
};

export function obterDiaDaSemanaPorIndice(indice) {
    return diaDaSemanaPorIndice[indice];
}
