export function IndexGuiaGestaoMedica() {
    return [
        {
            id: 'step1',
            text: '<h3>Bem-vindo a tela de agendamentos!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step2',
            text: 'Para pesquisar um agendamento, digite o nome do funcionário e tecle <b>ENTER.</b>',
            attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step3',
            text: 'Utilize a opção (filtros) para obter <b>status específicos</B> do agendamento.',
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step4',
            text: 'A qualquer momento é possível <b>extrair relatórios</b> da conforme a seleção do filtro.',
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step6',
            text: 'Para criar uma solicitação utilize o botão<b>Novo agendamento</b>.',
            attachTo: { element: document.querySelector('.step6'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step7',
            text: 'Utilize o botão <b>Pré-solicitação</b>, para visualizar e gerir solicitações <b>não liberadas para agendamentos.</b>',
            attachTo: { element: document.querySelector('.step7'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step8',
            text: '<b>Clique para obter mais informações sobre o agendamento.</b>',
            attachTo: {
                element: document.querySelector(
                    '#app > div > div.layout-main > div.layout-content > div > div > div > div > div.p-datatable.p-component.p-datatable-hoverable-rows.p-datatable-responsive-stack.p-datatable-sm.step10 > div.p-datatable-wrapper > table > tbody > tr:nth-child(1) > td:nth-child(1) > button'
                ),
                on: 'left'
            },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step9',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o agendamento, nesta ação é possível:</p>
            <p><b>Alterar:</b> Solicitação de alteração do agendamento.<p>
            <p><b>Reenviar Kit:</b> Reenviar o kit de atendimento a clínica e solicitante.<p>
            <p><b>Desistência de Vaga:</b> Cancelar do agendamento, devido a desistência da vaga.<p>
            <p><b>Cancelar:</b> Cancelar agendamento.<p>
            <p><b>Anexos:</b> Visualizar anexos do agendamento.<p>
            <p><b>Falta Consulta:</b> Informar falta consulta do funcionário.<p>
            <p><b>Timeline:</b> Visualizar histórico do agendamento.<p>
            `,
            attachTo: { element: document.querySelector('.step9'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step10',
            text: `<h5>Acompanhe todos os agendamentos a partir da listagem.</h5> <p>Solicitações em andamento:<p>
            <p><b>Aguardando Aplicação de Risco:</b> Aguardando os responsáveis técnicos para atribuição dos riscos à estrutura hierárquica do funcionário.</p>
            <p><b>Em Aberto:</b> Aguardando para realizar o agendamento.</p>
            <p><b>Em Andamento:</b> Em processo de agendamento.</p>
            <p><b>Aguardando Conf. Funcionário:</b> Aguardando confirmação do funcionário para presença na consulta.</p>
            <p><b>Pendência Cliente:</b> Aguardando retorno do cliente.</p>
            <p><b>Dia da Consulta:</b> Agendamentos cuja consulta é no dia atual.</p>
            <p><b>Aguardando Conf. Comparecimento:</b> Aguardando funcionário confirmar que compareceu na consulta.</p>
            <p><b>Agendado:</b> Agendamento realizado.</p>
            <p><b>Deposito antecipado”:</b> Aguardando depósito antecipado para o prestador.</p>`,
            attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step11',
            text: `<h5>Solicitações concluídas: </h5>
            <p><b>Cancelado:</b> Agendamento Cancelado</p>
            <p><b>Falta consulta:</b> Funcionário faltou à consulta, permitindo uma nova solicitação.</p>
            <p><b>Concluído:</b> Agendamento concluído e funcionário compareceu aos exames.</p>
            <p><b>Desistência de Vaga:</b> Processo de desistência de vaga.</p>
            `,
            attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step12',
            text: `<h4>Parabéns! Você concluiu o guia!👋<h4>`,
            attachTo: { on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}

export function ExamesGuiaGestaoMedica() {
    return [
        {
            id: 'Examesstep0',
            text: `<h3>Exames em dia!</h3></br> Está etapa é exibida apenas quando o funcionário estás com seu exame ocupacional em dia.</b>
            <p>A Norma Regulamentadora 7 (NR7) estabelece um prazo para que seja realmente necessário fazer novos exames.<p/>
            <p>Assim, o exame médico demissional é feito obrigatoriamente até a data de homologação (confirmação da demissão), caso o último exame periódico tenha sido realizado há mais de: </p>
            <p><b>135 dias</b>, para empresas de <b>grau de risco 1 e 2.</b></p>
            <p><b>90 dias</b>, para empresas de <b>grau de risco 3 e 4.</b></p>
            `,
            attachTo: { element: document.querySelector('.Examesstep0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'Examesstep1',
            text: `<p><b>Visualizar exames em dia e/ou autorizar</b></p>
                    <p>Ao clicar em <b>Ver exames</b> será possivel visualizar os procedimentos que estão em dia.</p>
                    <p>Ações:</p>
                    <p><b>Aceitar:</b> Ao clicar em aceitar, está sendo autorizado a realização dos procedimentos que estão em dia, essa ação pode gerar encargos e/ou cobrança.</p>
                    <p><b>Rejeitar:</b>Ao clicar em rejeitar, esta não está sendo autorizado a realização do procedimentos desta forma apenas os procedimentos autorizados serão realizados.</p>
                    `,
            attachTo: { element: document.querySelector('.Examesstep1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'Examesstep2',
            text: `Nome do funcionário`,
            attachTo: { element: document.querySelector('.Examesstep2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'Examesstep3',
            text: `Ao clicar em <b>Baicar o ASO</b>, será exibido todos os prontuários ocupacionais realizados pelo funcionário, podendo assim realizar o download do arquivo.`,
            attachTo: { element: document.querySelector('.Examesstep3'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'Examesstep4',
            text: 'clique em <b>VOLTAR</b> para retornar a etapa anterior.',
            attachTo: { element: document.querySelector('.Examesstep4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'Examesstep5',
            text: `<b>Avance para a próxima etapa</b>, caso necessário utilize novamente o guia de apoio.`,
            attachTo: { element: document.querySelector('.Examesstep5'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}

export function DefinirClinicaGuiaGestaoMedica() {
    return [
        {
            id: 'DefinirClinicastep0',
            text: '<h3>2º Definir clínica</h3></br> Nesta primeira etapa são definidos informações refente ao local e clínica de atendimento. Siga as instruções para conhecer os campos da tela. ',
            attachTo: { element: document.querySelector('.DefinirClinicastep0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep1',
            text: `<b>Informe o clínica para realizar o agendamento.</b>
                   <p>Será exibido as clínicas disponibilizadas conforme a <b>cidade informada</b>.
                   <p>Para visualizar mais detalhes sobre as clínicas, ao selecionar clique em <b>Clique aqui para ver informações da clínica</b>.
                   <p>Ao visualizar a lista de clínicas, será exibido na <b>cor preta, as clínicas que liberadas para uso ao cliente</b> e na <b>cor cinza, clínicas que possuem na cidade informada, porem não liberadas</b>, para liberação procure o prestador.
                   `,
            attachTo: { element: document.querySelector('.DefinirClinicastep1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep2',
            text: `<p>Informe o <b>intervalo de datas inicial e final</b>, que possa ser realizado o agendamento do funcionário.<p/>
                   <p>Esta intervalo será utilizado no ato do agendamento, priorizando sempre o intervalo informado. </p>
                   <p>Caso o agendamento seja realizado fora do intervalo selecionado, será enviado o email com a justificativa.</p>
                  `,
            attachTo: { element: document.querySelector('.DefinirClinicastep2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep3',
            text: `<p>Informe o <b>Período do dia</b> para realizar o agendamento.</p>
                   <p><b>Manha:</b> Realizar agendamento apenas na parte da manhã.</p>
                   <p><b>Tarde:</b> Realizar agendamento apenas na parte da tarde.</p>
                   <p><b>Ambos:</b> Realizar o agendamento com disponibilidade manhã e tarde.</p>`,
            attachTo: { element: document.querySelector('.DefinirClinicastep3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep5',
            text: 'Informa o nome do cliente vinculado ao funcionário.',
            attachTo: { element: document.querySelector('.DefinirClinicastep5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep6',
            text: `<p><b>Este campo define o fluxo de agendamento do funcionário. </b><p>
                   <p>A configuração é realizada na implantação do contrato no cadastro do cliente ou no cadastro do usuário.<p>
                    <p><b> Quem agenda:</b></p>
                    <p>Prestador: Quando informado o prestador, neste formato o <b>contato com a clínica é de responsabilidade do prestador</b>, apenas necessário realizar o acompanhamento através dos emails ou pela timeline.</p><br/>
                    Solicitante: Quando informado o solicitante, neste formato o <b>contato com a clínica é de responsabilidade do usuário</b> que está realizando a solicitação, desta forma é necessário realizar o contato com a clínica e informar a data e hora do agendamento.`,
            attachTo: { element: document.querySelector('.DefinirClinicastep6'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep7',
            text: `<p><b>Este campo define o fluxo de comunicação com o funcionário. </b><p>
                   <p>A configuração é realizada na implantação do contrato no cadastro do cliente ou no cadastro do usuário.<p>
                    <p><b> Quem avisa: </b></p>
                    <p>Prestador: Quando informado o prestador, neste formato o <b>contato com o funcionário é de responsabilidade do prestador</b>, desta forma é muito importante revisar o numero do funcionário informado.</p><br/>
                    Solicitante: Quando informado o solicitante, neste formato o <b>contato com o funcionário é de responsabilidade do usuário</b> que está realizando a solicitação, desta forma o prestador não fará nenhum contato com o funcionário.<br/>
                   `,

            attachTo: { element: document.querySelector('.DefinirClinicastep7'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep8',
            text: 'Caso necessário, informe uma observação, esta observação será visualizada e considerada no ato do agendamento.',
            attachTo: { element: document.querySelector('.DefinirClinicastep8'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep9',
            text: 'Caso o funcionário residir fora do país, habilite essa campo, desta forma será realizado agendamento conforme normas específicas.',
            attachTo: { element: document.querySelector('.DefinirClinicastep9'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep10',
            text: 'Para retornar a etapa anterior clique em <b>VOLTAR</b>.',
            attachTo: { element: document.querySelector('.DefinirClinicastep10'), on: 'top' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DefinirClinicastep11',
            text: '<b>Avançe para a proxima etapa</b>, caso necessário utilize novamente o guia de apoio.',
            attachTo: { element: document.querySelector('.DefinirClinicastep11'), on: 'top' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}

export function AgendarHorarioGuiaGestaoMedica() {
    return [
        {
            id: 'AgendarHorariostep0',
            text: `<h3>Agendar Horário</h3>
                  </p>Caso solicitação seja direcionado neste etapa, será possível realizar a <b>escolha da data e hora e realizar o agendamento</b>.</p>
                  <p>Utilize o guia a seguir para conhecer o processo.</p>`,
            attachTo: { element: document.querySelector('.AgendarHorariostep0'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep1',
            text: `<p><b>Particular</b></p>
                   Caso há exames a serem realizados de forma particular, habilite o campo <b>Particular</b> dos respectivos procedimentos.
                   <P>Os procedimentos habilitados <B>NÃO</B> serão agendados.</P>`,
            attachTo: { element: document.querySelector('.AgendarHorariostep1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep2',
            text: `<p>Clínica</p>
                <p>Para o agendamento de horário, será informado a clínica definido na etapa <b>Definir clínica.</b></p>
                <p>Procedimentos que <b>NÃO</B> serão realizados na clínica definida, será necessário a escolha da clínica disponível para realizar o agendamento.</p>
            `,
            attachTo: { element: document.querySelector('.AgendarHorariostep2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep3',
            text: `<p><B>Procedimentos</b></p>
                Lista dos procedimentos que serão realizados na clínica, os procedimentos são agrupados por dia da realização conforme campo <b>DIAS</B>.`,
            attachTo: { element: document.querySelector('.AgendarHorariostep3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep4',
            text: `<p><b>DIAS</b></P> <p>Os dias de antecedências mostram quantos dias que o funcionário precisa realizar o exame antes de passar pelo exame médico.</p>
                   <p><b>Exemplo:</b></p>
                   <p>1 DIA: Obrigatório realizar o exame com 1 dia de antecedência ao exame médico.</p>
                   <p>2 DIA: Obrigatório realizar o exame com 2 dias de antecedências ao exame médico.</p>
                   <p>3 DIA: Obrigatório realizar o exame com 3 dias de antecedências ao exame médico.</p>`,
            attachTo: { element: document.querySelector('.AgendarHorariostep4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep5',
            text: `<p><b>Datas</b></p>
            <p>Selecione a data e horário para agendar solicitação.</p>
            <p>Caso mensagem: <b>"Sem horários disponíveis para a data/período informado. Tente informar outra data de sugestão."</b> seja exibida, retorne a etapa <b>Dados do Agendamento</b> e defina um novo range de data.</p>
            <p>Para procedimentos realizados em outro prestador, informe a data de realização do exame.</p>
            `,
            attachTo: { element: document.querySelector('.AgendarHorariostep5'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep6',
            text: 'clique em <b>VOLTAR</b> para retornar a etapa anterior.',
            attachTo: { element: document.querySelector('.AgendarHorariostep6'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'AgendarHorariostep7',
            text: '<b>Avance para a próxima etapa</b>, caso necessário utilize novamente o guia de apoio.',
            attachTo: { element: document.querySelector('.AgendarHorariostep7'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}

export function DadosAgendamentoGuiaGestaoMedica() {
    return [
        {
            id: 'DadosDoAgendamentostep0',
            text: '<h3>1º Dados do agendamento.</h3></br> Nesta primeira etapa são definidos informações principais referente ao agendamento. Siga as instruções para conhecer os campos da tela. ',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep0'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep1',
            text: '<b>Nome do responsável pela solicitação</b>. <br/>Por padrão busca do cadastro do usuário.',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep1'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep2',
            text: '<b>E-mail do responsável pela solicitação</b>. <br/>Todos os comunicados serão enviados ao e-mail informado neste campo.',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep2'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep3',
            text: '<b>Telefone do responsável pela solicitação</b> <br/> Todos os comunicados serão enviados ao e-mail informado neste campo.',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep3'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep4',
            text: `<p><b>Tipo de exame a realizar</b>. Exemplos: <br/><b>Exame Admissional:</b> antes da efetivação/contratação de um funcionário. </p>
                    <p><b>Exame Demissional:</b> deve ser realizado até a data efetiva da demissão e da rescisão de contrato. </p>
                    <p><b>Exame Periódicos:</b> são realizados durante o vínculo do funcionário com a empresa. </p>
                    <p><b>Exame retorno ao trabalho:</b> atestar se o funcionário está apto ou não a voltar às suas funções após um período afastado. </p>
                    <p><b>Exame Mudança de riscos ocupacionais:</b> Em casos que a nova função apresente riscos diferentes dos riscos apresentados na função anterior. </p>`,
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep4'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep5',
            text: '<b>Cidade a realizar o exame.</b> <br/> Ao determinar a cidade, será listado na etapa posterior, todos as clínicas disponíveis para atendimento.',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep5'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep6',
            text: `<p><b>Seleção dos funcionários a realizar o exame. </b></p>
                   <p>Para buscar os funcionários, digite parte do nome e selecione o cadastro.</p>
                   <p>Após a seleção, clique em adicionar para selecionar o funcionário.</p>
                   `,
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep6'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep7',
            text: `Clique em adicionar.`,
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep7'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep8',
            text: `Informe o contato preferêncial Whatsapp do funcionário. <p><b>Telefone obrigatório</b></p> <p>E-mail do funcionário é opcional</P>.`,
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep8'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosDoAgendamentostep9',
            text: '<b>Avance para a próxima etapa</b>, caso necessário utilize novamente o guia de apoio.',
            attachTo: { element: document.querySelector('.DadosDoAgendamentostep9'), on: 'bottom' },
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}

export function TelaSolicitacoesGuiaGestaoMedica() {
    return [
        {
            id: 'DefinirSolicitacoesstep0',
            text: '<h3>Concluir solicitação</h3></br> Esta é a etapa final do processo de solicitação, ao chegar neste etapa é necessário realizar a conferencia, após a conferencia, defini em <b>CRIAR AGENDAMENTO</b> ou <b>PRÉ-SOLICITAÇÃO.</M> ',
            attachTo: { element: document.querySelector('.DefinirSolicitacoesstep0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep1',
            text: `<p><b>Pendencia de informações</b></p>
                   Caso haja pendencia ou informações adicionais, será necessário expandir e resolve-las.`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep2',
            text: `Nome do funcionário a concluir a solicitação.`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep3',
            text: `E-mail do funcionário para possível contato.`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep4',
            text: 'Telefone para <b>contato com o funcionário</B>, caso o parâmetro <b>quem avisa seja PRESTADOR</b>.',
            attachTo: { element: document.querySelector('.DadosSolictacoesstep4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep5',
            text: `Tipo de exame a realizar.`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep5'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep6',
            text: `Para casos que necessita de enquadramento PCD, selecione o campo e clique no icone <b>></b> ao lado da coluna nome, para preenchimento dos campos adicionais.`,

            attachTo: { element: document.querySelector('.DadosSolictacoesstep6'), on: 'left' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep7',
            text: `<p>Utilize o botão <B>CRIAR PRÉ-SOLICITAÇÃO</B>, para direcionar a tela de PRÉ-SOLICITAÇÃO.</p>
                   <p>Utilizando esse recurso <B>NÃO</b> será criado uma solicitação de agendamento, será direcionada para tela inicial sendo necessário uma aprovação pelo usuário, convertendo em uma solicitação de agendamento.</P>
                   <p><b>DICA:</b> Esse recurso é utilizado para preparar as solicitações e posteriormente ir realizando as liberações conforme a necessidade. </P>`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep7'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'DadosSolictacoesstep8',
            text: `<p>Utilize o botão <b>CRIAR AGENDAMENTO</b> para concluir a solicitação e disponibilizar para agendamento.</p>
                  <p>Após a conclusão é possível consultar através da tela inicial e acompanhar o processo.</p>`,
            attachTo: { element: document.querySelector('.DadosSolictacoesstep8'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Voltar', action: this.tour.back, classes: '' }
            ]
        }
    ];
}
