import { RouterView } from 'vue-router';
import AgendamentosIndex from '../agendamentos/Index.vue';
import DadosSolicitante from '../agendamentos/criar-agendamento/steps/DadosSolicitante.vue';
import DadosAgendamento from '../agendamentos/criar-agendamento/steps/DadosAgendamento.vue';
import Anexos from '../agendamentos/criar-agendamento/steps/Anexos.vue';
import Pcd from '../agendamentos/criar-agendamento/steps/Pcd.vue';
import Procedimentos from '../agendamentos/criar-agendamento/steps/Procedimentos.vue';
import DefinirClinica from '../agendamentos/criar-agendamento/steps/DefinirClinica.vue';
import Agendar from '../agendamentos/criar-agendamento/steps/Agendar.vue';
import Autoagendamento from '../agendamentos/criar-agendamento/steps/Autoagendamento';
import DadosFuncionario from '../agendamentos/criar-agendamento/steps/DadosFuncionario';

const gestaoMedicaV3 = {
    path: '/gestao-medica-v3',
    redirect: '/gestao-medica-v3/agendamentos',
    component: RouterView,
    meta: {
        requiresAuth: true,
        permission: 'gestao_medica_v3:view'
    },
    children: [
        {
            path: 'agendamentos',
            name: 'agendamentos-v3-index',
            component: AgendamentosIndex,
            children: [
                {
                    path: 'dados-solicitante',
                    name: 'agendamentos-v3-dados-solicitante',
                    component: DadosSolicitante
                },
                {
                    path: 'dados-agendamento',
                    name: 'agendamentos-v3-dados-agendamento',
                    component: DadosAgendamento
                },
                {
                    path: 'anexos',
                    name: 'agendamentos-v3-anexos',
                    component: Anexos
                },
                {
                    path: 'pcd',
                    name: 'agendamentos-v3-pcd',
                    component: Pcd
                },
                {
                    path: 'procedimentos',
                    name: 'agendamentos-v3-procedimentos',
                    component: Procedimentos
                },
                {
                    path: 'definir-clinica',
                    name: 'agendamentos-v3-definir-clinica',
                    component: DefinirClinica
                },
                {
                    path: 'agendar',
                    name: 'agendamentos-v3-agendar',
                    component: Agendar
                },
                {
                    path: 'autoagendamento',
                    name: 'agendamentos-v3-auto-agendamento',
                    component: Autoagendamento
                },
                {
                    path: 'dados-funcionario',
                    name: 'agendamentos-v3-dados-funcionario',
                    component: DadosFuncionario
                }
            ]
        }
    ]
};

export default gestaoMedicaV3;
