<template>
    <InputMask
        v-bind="$attrs"
        mask="99:99"
        v-model="value"
        autocomplete="off"
        placeholder="hh:mm"
        :class="{ 'p-invalid': required && submitted && !value }"
    />
    <small class="p-error" v-if="submitted && !value"> {{ requiredText }}</small>
</template>
<script>
export default {
    name: 'AppInputTempo',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        submitted: {
            type: Boolean,
            default: false
        },
        requiredText: {
            type: String,
            default: 'Preenchimento Obrigatório'
        }
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    }
};
</script>
