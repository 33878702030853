export function EstruturaFuncionarioList() {
    return [
        {
        id: 'step0',
        text: `<h3>Bem vindo a tela de funcionários!</h3>A partir desse guia iremos mostrar as principais funções! `,
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step1',
        text: `Aqui você pode pesquisar por um <b>funcionário</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
        attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
    {
        id: 'step3',
        text: `Para cadastrar um novo funcionário, utilize o botão <b>+Novo</b>.`,
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: `<h5>Acompanhe todos os funcionários a partir da listagem.</h5>
        <p> Neste item, você encontrará a tabela de funcionários, contendo todas as informações relevantes a respeito de cada um.`,
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step5',
        text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de um funcionário, nesta ação é possível:</p>
        <p><b>Alterar:</b> Alterar os dados do cadastro de um funcionário.<p>
        <p><b>Excluir:</b> Excluir os dados de um funcionário cadastrado.<p>
        <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico. <p>
        <p><b>Nova Função:</b> Função que realiza a troca da estrutura de hierarquia do funcionário. <p>
        <p><b>Efetivação:</b> Esta função realiza a efetivação do cadastro atual, criando um novo registro que sinaliza a efetivação do
        funcionário.<p>
        <p><b>Histórico de ASO:</b> Função que busca todos os ASOs efetuados pelo funcionário para a gestão dos exames realizados.<p>
        <p><b>Logs:</b> É registrado caso ocorra algum erro no ato de salvar o cadastro.<p>`,
        attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
]
}