<template>
    <Dropdown
        :disabled="!examesAtendidos?.data"
        v-model="value"
        :options="horariosDisponiveis"
        :loading="loading"
        class="w-full"
        placeholder="Horarios da agenda"
        @change="changeHorario(form)"
    >
        <template #value="{ placeholder, value }">
            <span v-if="value && value.horarioChegada">
                Entrada: {{ filters.formatTimeUtc(value.horarioChegada) }} - Saída: {{ filters.formatTimeUtc(value.horarioSaida) }}
            </span>
            <span v-else>
                {{ placeholder }}
            </span>
        </template>
        <template #option="{ option }">
            <span> Entrada: {{ filters.formatTimeUtc(option.horarioChegada) }} - Saída {{ filters.formatTimeUtc(option.horarioSaida) }} </span>
        </template>
    </Dropdown>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits, watch } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import { useGlobalFilters } from '@/utils/useGlobalFilters';

const filters = useGlobalFilters();
const emit = defineEmits(['update:modelValue', 'change']);
const props = defineProps({
    configFuncionario: Object,
    examesAtendidos: Object,
    formData: Object,
    modelValue: Object,
    indexAtual: {
        type: Number,
        required: true
    },
    todosExamesAtendidos: {
        type: Array,
        required: true
    },
    procedimentoLaudoPCD: Boolean
});

const horariosDisponiveis = ref([]);
const loading = ref(false);
const toast = useToast();
watch(
    () => props.examesAtendidos.data,
    (val) => {
        value.value = undefined;
        if (val) {
            carregarHorarios();
        }
    },
    { deep: true }
);
const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

async function changeHorario() {
    emit('change');
}
async function carregarHorarios() {
    loading.value = true;
    try {
        const dto = {
            prestadorId: props.examesAtendidos.prestador.id,
            funcionarioId: props.configFuncionario?.idFuncionario,
            ambienteId: props.examesAtendidos?.ambienteID,
            examesIds: props.examesAtendidos?.procedimentos.map((p) => p.id),
            dataInicial: props.examesAtendidos?.data,
            dataFinal: props.examesAtendidos?.data,
            periodo: props.formData?.periodo,
            isPCD:
                props.formData.funcionarios.find((funcionario) => funcionario.id === props.configFuncionario?.idFuncionario).pcd ||
                props.procedimentoLaudoPCD,
            tipoExameId: props.formData.tipoExame.id
        };
        const { data } = await getClientBase().post('agendamentos/new-faixas-disponiveis', dto);
        horariosDisponiveis.value = data;

        if (props.indexAtual > 0) {
            if (JSON.stringify(props.todosExamesAtendidos[0].data) === JSON.stringify(props.examesAtendidos.data)) {
                horariosDisponiveis.value = horariosDisponiveis.value.filter(
                    (h) =>
                        h.horarioChegada <= props.todosExamesAtendidos[0].hora.horarioChegada &&
                        h.horarioSaida <= props.todosExamesAtendidos[0].hora.horarioChegada
                );
            } else if (JSON.stringify(props.todosExamesAtendidos[props.indexAtual - 1].data) === JSON.stringify(props.examesAtendidos.data)) {
                horariosDisponiveis.value = horariosDisponiveis.value.filter(
                    (h) =>
                        (h.horarioChegada >= props.todosExamesAtendidos[props.indexAtual - 1].hora.horarioSaida &&
                            h.horarioSaida >= props.todosExamesAtendidos[props.indexAtual - 1].hora.horarioSaida) ||
                        (h.horarioChegada <= props.todosExamesAtendidos[props.indexAtual - 1].hora.horarioChegada &&
                            h.horarioSaida <= props.todosExamesAtendidos[props.indexAtual - 1].hora.horarioChegada)
                );
            }
        }
        if (!data?.length) {
            toast.add({
                severity: 'error',
                summary: 'Sem horários disponíveis para a data/período informado. Tente informar outra data de sugestão.',
                life: 3000
            });
        }
    } catch (error) {
        loading.value = false;
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
    loading.value = false;
}
</script>
