import { aesDencrypt, aesEncrypt } from './cypher';

export const clear = () => localStorage.clear();

export const getToken = () => localStorage.getItem('access_token');

export const getPrestadorToken = () => localStorage.getItem('prestador_token');

export const setAutoagendamentoToken = (token) => localStorage.setItem('autoagendamento_token', token);
export const getAutoagendamentoToken = () => localStorage.getItem('autoagendamento_token');
export const deleteAutoagendamentoToken = () => localStorage.removeItem('autoagendamento_token');

export const setToken = (token) => localStorage.setItem('access_token', token);

export const setPrestadorToken = (token) => localStorage.setItem('prestador_token', token);

export const deleteToken = () => localStorage.removeItem('access_token');

export const deletePrestadorToken = () => localStorage.removeItem('prestador_token');
export const setDefaultCompanyId = (id) => localStorage.setItem('defaultCompanyId', id);

export const getTenant = () => localStorage.getItem('x-api-key');
export const setTenant = (key) => localStorage.setItem('x-api-key', key);
export const deleteTenant = () => localStorage.removeItem('x-api-key');

export const getProfile = () => getCyphered('profile');
export const setProfile = (profile) => setCyphered('profile', profile);
export const deleteProfile = () => localStorage.removeItem('profile');

export const getPermissions = () => getCyphered('permissions');
export const setPermissions = (permissions) => setCyphered('permissions', permissions);
export const deletePermissions = () => localStorage.removeItem('permissions');

export const getCompany = () => getCyphered('company');
export const setCompany = (company) => setCyphered('company', company);

export const getCustomers = () => getCyphered('customers');
export const getFilterCustomer = () => getCyphered('filter-customer');
export const setCustomers = (customers) => setCyphered('customers', customers);
export const setFilterCustomer = (customers) => setCyphered('filter-customer', customers);

export const getCustomersBranch = () => getCyphered('customers-branch');
export const setCustomersBranch = (customersBranch) => setCyphered('customers-branch', customersBranch);

const getCyphered = (name) => {
    const value = localStorage.getItem(name);
    if (!value) {
        return;
    }
    return JSON.parse(aesDencrypt(value));
};

const setCyphered = (name, value) => {
    return localStorage.setItem(name, aesEncrypt(JSON.stringify(value)));
};
