import { setPermissions } from '@/common/storage';

export const auth = {
    state: {
        permissions: []
    },
    getters: {
        permissions: function (state) {
            return state.permissions;
        }
    },
    mutations: {
        UPDATE_PERMISSIONS(state, payload) {
            state.permissions = payload;
        }
    },
    actions: {
        addToPermissions(context, payload) {
            setPermissions(payload);
            context.commit('UPDATE_PERMISSIONS', payload);
        }
    }
};
