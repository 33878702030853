export function BaseEmpresa() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de empresas!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por uma <b>empresa</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }, 
         { 
            id: 'step3',
            text: `Para cadastrar uma nova empresa, utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        } ,
        {
            id: 'step4',
            text: `<h5>Acompanhe todas as empresas a partir da listagem.</h5>
            <p> Neste item, você encontrará a tabela de empresas, identificadas pelo seu nome e razão social. <p>`,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de uma empresa, nesta ação é possível:</p>
            <p><b>Alterar:</b> Alterar os dados do cadastro da empresa.<p>
            <p><b>Excluir:</b> Excluir uma empresa cadastrada.<p>
            <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico. <p>
            <p><b>Carteira de Manipulação de Alimentos:</b> : Permite definir se será enviado um anexo de manipulação de alimentos para agendamentos que
            envolvem riscos associados.<p>
            <p><b>Avaliação de Ocorrências no trabalho:</b> Você pode anexar
            automaticamente um documento de exame para casos de incidentes
            ocorridos no ambiente de trabalho envolvendo funcionários da empresa cadastrada.<p>
            <p><b>Protocolo de Interpretação e Condutas Médicas:</b> Permite definir se será enviado um anexo contendo o Protocolo de Interpretação e Condutas Médicas.<p>`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ]
}
