import TipoExame from '@/enums/TipoExame.js';

export function verificarExameOcupacional(tipoExameId) {
    const examesOcupacionais = [
        TipoExame.ADMISSIONAL,
        TipoExame.PERIODICO,
        TipoExame.RETORNO_AO_TRABALHO,
        TipoExame.DEMISSIONAL,
        TipoExame.MUDANCA_FUNCAO
    ];
    return examesOcupacionais.includes(tipoExameId);
}
