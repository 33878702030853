<template>
    <Dialog :style="{ width: '450px' }" class="p-fluid" header="Tentar novamente" :closable="false" :modal="true">
        <div class="field">
            <div class="retry-content">
                <i class="pi pi-info-circle mr-3" style="font-size: 2rem" />
                <span style="font-weight: bold">{{ title }}</span>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
            <Button label="Tentar Novamente" icon="pi pi-check" class="p-button-outlined p-button-success" :loading="loading"
                :disabled="loading" @click="$emit('onRetry')" />
        </template>
    </Dialog>
</template>
<script>

export default {
    name: 'AppRetryDialog',
    props: {
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    }
}
</script>
