<template>
    <Dialog v-model:visible="open" :closable="false" :modal="true" :style="{ width: '900px' }" class="p-fluid" header="Atualizar dados">
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="field">
                <label>Dados do solicitante</label>
                <div class="formgrid grid">
                    <div class="field col">
                        <InputText
                            id="nomeSolicitante"
                            v-model.trim="formLocal.nomeSolicitante"
                            :class="{ 'p-invalid': v$.nomeSolicitante.$error }"
                            autofocus
                            placeholder="Nome"
                            required="true"
                        />
                        <small v-if="v$.nomeSolicitante.$error" class="p-error">O nome do solicitante é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputText
                            id="emailSolicitante"
                            v-model.trim="formLocal.emailSolicitante"
                            :class="{ 'p-invalid': v$.emailSolicitante.$error }"
                            autofocus
                            placeholder="Email"
                            required="true"
                        />
                        <small v-if="v$.emailSolicitante.$error" class="p-error">O email do solicitante é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputMask
                            id="telefoneSolicitante"
                            v-model.trim="formLocal.telefoneSolicitante"
                            :class="{ 'p-invalid': v$.telefoneSolicitante.$error }"
                            autofocus
                            mask="(99) 99999-9999"
                            placeholder="Telefone"
                            required="true"
                        />
                        <small v-if="v$.telefoneSolicitante.$error" class="p-error">O telefone do solicitante é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="field">
                <label class="mb-3" for="id">Tipo de Exame</label>
                <Dropdown
                    id="id"
                    v-model="formLocal.tipoExame.id"
                    :options="records.tipoExames"
                    optionLabel="descricao"
                    optionValue="id"
                    placeholder="Tipo de exame"
                ></Dropdown>
            </div>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id === 3">
                <div class="field">
                    <label for="anexo">Anexo abono de atestado</label>
                    <FileUpload
                        v-model="formLocal.anexoAbono"
                        :auto="true"
                        :class="{ 'p-invalid': submitted && !formLocal.anexoAbono }"
                        :customUpload="true"
                        :fileLimit="1"
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        chooseLabel="Escolha o arquivo"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        mode="advanced"
                        name="anexoLaudo"
                        @uploader="myUploader"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                    </FileUpload>
                    <small v-if="submitted && !formLocal.anexoAbono" class="p-error">Campo obrigatório.</small>
                </div>
            </template>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id === 5">
                <div class="field">
                    <label>Nova Unidade</label>
                    <InputText placeholder="Unidade" />
                </div>
                <div class="field">
                    <label>Novo setor</label>
                    <InputText placeholder="Setor" />
                </div>
                <div class="field">
                    <label>Novo cargo</label>
                    <InputText placeholder="Cargo" />
                </div>
            </template>
            <div v-if="formLocal.tipoExame.id && formLocal.tipoExame.id === 4" class="field">
                <label>Data de Demissão</label>
                <Calendar
                    id="dataHomologacao"
                    v-model="formLocal.dataHomologacao"
                    :class="{ 'p-invalid': submitted && !formLocal.dataHomologacao }"
                    :showIcon="true"
                    dateFormat="dd/mm/yy"
                    selectionMode="single"
                />
                <small v-if="submitted && !formLocal.dataHomologacao" class="p-error">Campo obrigatório para demissional.</small>
            </div>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id === 9">
                <div class="field">
                    <label for="motivoConsulta">Motivo da Consulta</label>
                    <Dropdown
                        id="motivoConsulta"
                        v-model="formLocal.motivoConsulta"
                        :class="{ 'p-invalid': submitted && !formLocal.motivoConsulta }"
                        :options="motivosConsulta"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione um motivo"
                    ></Dropdown>
                    <small v-if="submitted && !formLocal.motivoConsulta" class="p-error">Campo obrigatório para consultas.</small>
                </div>
                <div class="field">
                    <label for="detalheConsulta">Detalhes da Consulta</label>
                    <Textarea
                        id="detalheConsulta"
                        v-model="formLocal.detalheConsulta"
                        :class="{ 'p-invalid': submitted && !formLocal.detalheConsulta }"
                        cols="20"
                        required="true"
                        rows="3"
                    />
                    <small v-if="submitted && !formLocal.detalheConsulta" class="p-error">Campo obrigatório para consultas.</small>
                </div>
                <div class="field">
                    <label for="anexo">Anexo</label>
                    <FileUpload
                        v-model="formLocal.anexoConsulta"
                        :auto="true"
                        :class="{ 'p-invalid': submitted && !formLocal.anexoConsulta }"
                        :customUpload="true"
                        :fileLimit="1"
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        chooseLabel="Escolha o arquivo"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        mode="advanced"
                        name="anexoLaudo"
                        @uploader="myUploader"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                    </FileUpload>
                    <small v-if="submitted && !formLocal.anexoConsulta" class="p-error">Campo obrigatório.</small>
                </div>
            </template>
            <template v-if="formLocal.tipoExame.id && formLocal.tipoExame.id === 3">
                <div class="field">
                    <div class="field">
                        <label for="tipoRetorno">Tipo de Retorno</label>
                        <Dropdown
                            id="tipoRetorno"
                            v-model="formLocal.tipoRetorno"
                            :class="{ 'p-invalid': submitted && !formLocal.tipoRetorno }"
                            :options="tiposRetorno"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Escolha o Tipo de Retorno"
                        ></Dropdown>
                        <small v-if="submitted && !formLocal.tipoRetorno" class="p-error">Campo obrigatório para Retorno ao Trabalho.</small>
                    </div>
                    <div class="field">
                        <label>Data Liberação</label>
                        <Calendar
                            id="dataLiberacao"
                            v-model="formLocal.dataLiberacao"
                            :class="{ 'p-invalid': submitted && !formLocal.dataLiberacao }"
                            :showIcon="true"
                            dateFormat="dd/mm/yy"
                            selectionMode="single"
                        />
                        <small v-if="submitted && !formLocal.dataLiberacao" class="p-error">Campo obrigatório para Retorno ao Trabalho.</small>
                    </div>
                </div>
            </template>
            <div class="grid formgrid">
                <div class="field col">
                    <label for="cidades">Cidade</label>
                    <DropdownCidade id="cidade" v-model="formLocal.cidade" :class="{ 'p-invalid': v$.cidade.$error }" />
                    <small v-if="v$.cidade.$error" class="p-error">A cidade é obrigatória.</small>
                </div>
                <div class="field col">
                    <label for="funcionario">Funcionario</label>
                    <InputText
                        id="funcionario"
                        v-model.trim="formLocal.funcionario.name"
                        :class="{ 'p-invalid': v$.funcionario.$error }"
                        disabled="true"
                    />
                    <small v-if="v$.funcionario.$error" class="p-error">O nome do funcionário é obrigatório.</small>
                </div>
                <div class="field col">
                    <label for="prestador">Prestador</label>
                    <DropdownPrestadorCliente
                        v-model="formLocal.prestador"
                        :autoLoad="true"
                        :class="{ 'p-invalid': v$.prestador.$error }"
                        :disabled="!formLocal.cidade"
                        :idCidade="cidadeId"
                        :idCliente="clientesSelecionados"
                    />
                    <small v-if="v$.prestador.$error" class="p-error">O prestador é obrigatório.</small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="editRangeDatas">Datas de sugestão</label>
                    <AppInputCalendarioRanged
                        id="editRangeDatas"
                        ref="inputCalendario"
                        v-model="editRangeDatas"
                        :idCidade="formLocal.cidade?.id"
                        :idPrestador="formLocal.prestador?.id"
                        :periodo="formLocal.periodo"
                        :required="true"
                        :submitted="submitted"
                        @onGetPeriodoManhaVisible="onGetPeriodoManhaVisible"
                    />
                </div>
                <div class="field col">
                    <label for="periodo">Periodo</label>
                    <Dropdown
                        id="periodo"
                        v-model="formLocal.periodo"
                        :options="periodo"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione..."
                    ></Dropdown>
                </div>
            </div>
            <div class="field">
                <label for="observação">Observação</label>
                <Textarea id="observação" v-model="formLocal.observacao" :autoResize="true" cols="20" rows="3" />
            </div>
            <div class="field-checkbox">
                <InputSwitch id="binary" v-model="formLocal.resideForaDoPais" />
                <label for="binary">Funcionario reside fora do país</label>
            </div>
            <div class="field-checkbox">
                <InputSwitch id="pcd" v-model="formLocal.pcd" />
                <label for="pcd">Enquadramento PCD</label>
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label=" " @click="closeDialog" />
            <Button class="p-button-outlined p-button-success" icon="pi pi-check" label="Atualizar" @click="onClickSalvar" />
        </template>
    </Dialog>
</template>

<script setup>
import DropdownCidade from '@/pages/cidades/components/DropdownCidade.vue';
import DropdownPrestadorCliente from '@/pages/prestadores/components/DropdownPrestadorCliente.vue';
import { ref, defineProps, defineEmits, defineExpose, watch, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import AreaMedicaService from '@/services/AreaMedicaService';
import UploadService from '@/services/upload-file';
import BaseService from '@/services/BaseService';
import moment from 'moment-timezone';
import { clone } from 'ramda';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

const emit = defineEmits(['update:form', 'updateRecordsTabela']);

const props = defineProps({
    form: {
        type: Object,
        required: true
    }
});

const _form = computed({
    get() {
        return props.form;
    },
    set(value) {
        emit('update:form', value);
    }
});

const service = new AreaMedicaService('/agendamentos');
const uploadService = new UploadService('/pre-solicitacoes/upload');
const serviceTipoExame = new AreaMedicaService('/tipo_exames');
const serviceCidade = new BaseService('/cidades');
const serviceDadosFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
const serviceDadosPrestador = new AreaMedicaService('/agendamento_prestador/agendamento');
const toast = useToast();

const formLocal = ref({});

watch(
    () => formLocal.value.cidade,
    () => {
        cidadeId.value = formLocal.value?.cidade?.id ? formLocal.value.cidade.id : null;
        formLocal.value.prestador = null;
    }
);

const tiposRetorno = ref([
    { label: 'B31 - Auxilio - Doença Previdenciário', value: 1 },
    { label: 'B91 - Auxilio - Doença por acidente do trabalho', value: 2 },
    { label: 'B94 - Auxilio - Acidente por acidente do trabalho', value: 3 },
    { label: 'Licença Maternidade', value: 4 },
    { label: 'Prestação do Serviço Militar', value: 5 }
]);

const periodo = computed(() => {
    const prestadorUsaSoc = formLocal.value.prestador?.systemsAndTechnology?.name === 'SOC';
    const periodoRetorno = [
        { label: 'Manhã', value: 1, visible: periodoManhaAtivo.value },
        { label: 'Tarde', value: 2, visible: true },
        { label: 'Ambos', value: 3, visible: !prestadorUsaSoc }
    ];
    return periodoRetorno.filter((p) => p.visible === true);
});

const submitted = ref(false);

const rules = {
    cidade: { required },
    prestador: { required },
    tipoExame: { required },
    nomeSolicitante: { required },
    emailSolicitante: { required, email },
    telefoneSolicitante: { required },
    dataSugestaoInicial: { required },
    dataSugestaoFinal: { required },
    periodo: { required },
    funcionario: { required }
};

const v$ = useVuelidate(rules, formLocal);
async function onClickSalvar() {
    submitted.value = true;

    v$.value.$touch();
    if (!v$.value.$invalid) {
        formLocal.value.dataSugestaoInicial = editRangeDatas.value[0];
        formLocal.value.dataSugestaoFinal = editRangeDatas.value[1];
        formLocal.value.telefoneColaborador = formLocal.value.telefoneColaborador
            ? formLocal.value.telefoneColaborador.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;
        formLocal.value.telefoneSolicitante = formLocal.value.telefoneSolicitante
            ? formLocal.value.telefoneSolicitante.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;
        if (formLocal.value.periodo.value) {
            formLocal.value.periodo = formLocal.value.periodo.value;
        }
        if (formLocal.value.tipoRetorno) {
            formLocal.value.tipoRetorno = formLocal.value.tipoRetorno.value;
        }
        if (formLocal.value) {
            try {
                await service.save(formLocal.value);
                _form.value = clone(formLocal.value);
                toast.add({ severity: 'success', summary: 'Registro atualizado com sucesso!', life: 3000 });
                const attTabela = true;
                closeDialog(attTabela);
            } catch (err) {
                toast.add({ severity: 'error', summary: 'Falha ao atualizar registro', life: 3000 });
            }
        }
    } else {
        toast.add({ severity: 'error', summary: 'Falha ao atualizar registro', life: 3000 });
    }
}

const open = ref(false);
function openDialog() {
    open.value = true;
    formLocal.value = clone(props.form);
    clientesSelecionados.value = [formLocal.value.funcionario.customerId];
    cidadeId.value = formLocal.value?.cidade?.id ? formLocal.value.cidade.id : null;

    load();
}

function closeDialog(atualizarTabela) {
    open.value = false;
    if (atualizarTabela) {
        emit('updateRecordsTabela');
    }
}

const loading = ref(false);

const clientesSelecionados = ref([]);
const cidadeId = ref(null);

defineExpose({
    openDialog
});

const records = ref({});
const arrayPrestadores = ref([]);
const editRangeDatas = ref([]);
const motivosConsulta = ref([
    { label: 'Motivo 1', value: 1 },
    { label: 'Motivo 2', value: 2 },
    { label: 'Motivo 3', value: 3 },
    { label: 'Motivo 4', value: 4 }
]);

async function load() {
    loading.value = true;
    formLocal.value.funcionario = {};
    formLocal.value.prestador = {};
    records.value.tipoExames = [];
    records.value.cidades = [];

    await carregarOpcoesDropdown();

    const agendamentoFuncionario = await serviceDadosFuncionario.findById(formLocal.value.id);
    const agendamentoPrestador = await serviceDadosPrestador.findById(formLocal.value.id);
    formLocal.value.funcionario = agendamentoFuncionario.data.funcionario;
    formLocal.value.prestador = agendamentoPrestador.data.prestador;

    const dataSugestaoInicial = moment.tz(formLocal.value.dataSugestaoInicial, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();
    const dataSugestaoFinal = moment.tz(formLocal.value.dataSugestaoFinal, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate();

    editRangeDatas.value = [dataSugestaoInicial, dataSugestaoFinal];

    if (formLocal.value.motivoConsulta && formLocal.value.tipoExame.id === 9) {
        const motivoConsulta = motivosConsulta.value.find((item) => item.value === parseInt(formLocal.value.motivoConsulta));
        formLocal.value.motivoConsulta = motivoConsulta.value;
    }
    if (formLocal.value.tipoExame.id === 3) {
        if (formLocal.value.tipoRetorno) {
            formLocal.value.tipoRetorno = parseInt(formLocal.value.tipoRetorno);
        }
        if (formLocal.value.dataLiberacao) {
            formLocal.value.dataLiberacao = new Date(formLocal.value.dataLiberacao);
        }
    }
    await orderedSupplier(formLocal.value.funcionario.customerId, formLocal.value.cidade.id);
    arrayPrestadores.value = [];
    const path = 'prestador-clientes/client';
    const servico = new AreaMedicaService(`${path}/${formLocal.value.funcionario.customerId}`);
    const response = await servico.findAll({});
    for (const prestadores of response.data) {
        arrayPrestadores.value.push(prestadores?.prestador?.id);
    }
    loading.value = false;
}

const periodoManhaAtivo = ref(true);

function onGetPeriodoManhaVisible(value) {
    periodoManhaAtivo.value = value;
}

const perPage = ref(10);
const page = ref(1);

async function carregarOpcoesDropdown() {
    const tipoExames = await serviceTipoExame.findAll({});
    records.value.tipoExames = tipoExames.data;
    const { data } = await serviceCidade.findAll({
        limit: perPage,
        page: page
    });
    data.items.forEach((item) => (item.cidade = `${item.cidade} - ${item.estado}`));
    records.value.cidades = data.items;
}

async function orderedSupplier(client, city) {
    const path = 'suppliers/ordered';
    if (!city) {
        city = 0;
    }
    const servico = new BaseService(`${path}/${client}/${city}/0`);
    const response = await servico.findAll({});
    records.value.prestadores = [];
    records.value.prestadores = response.data;
}

async function myUploader(event) {
    let formData = new FormData();
    formData.append('file', event.files[0]);
    try {
        const { data } = await uploadService.upload(formData);
        formLocal.value.anexoLaudo = data.path;
        toast.add({ severity: 'success', summary: 'Arquivo carregado com sucesso', life: 3000 });
    } catch (err) {
        toast.add({ severity: 'error', summary: 'Problemas ao carregar o arquivo', life: 3000 });
        console.log(err);
    }
}
</script>
