<template>
    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
    <div :style="loading ? 'opacity: 30%;' : ''">
        <div class="card p-fluid w-full">
            <slot name="content"></slot>
        </div>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <Button
                v-if="$route.params.id && activateRecord && !form.ativo && showAtivarInativar && this.$checkPermission(permission + ':ativar')"
                class="p-button-outlined p-button-primary mb-5 md:mb-0"
                icon="pi pi-check"
                label="Ativar"
                @click="activate"
            />
            <Button
                v-if="$route.params.id && activateRecord && form.ativo && showAtivarInativar && this.$checkPermission(permission + ':inativar')"
                class="p-button-outlined p-button-danger mb-5 md:mb-0"
                icon="pi pi-ban"
                label="Inativar"
                @click="inactivate"
            />
            <div class="flex flex-column md:flex-row md:justify-content-end md:ml-auto">
                <AppBackButton v-if="backPath" :formChanged="hasChanged" :path="backPath" class="mr-0 mt-3 mb-2 md:mr-3 md:mt-0 md:mb-0" />
                <Button
                    v-if="!loading"
                    :class="{ 'p-button-outlined': showSaveAndReturn }"
                    :disabled="disableSave"
                    :loading="loadingSalvar"
                    class="p-button"
                    icon="pi pi-check"
                    label="Salvar"
                    @click="save"
                />
                <Button
                    v-if="!loading && showSaveAndReturn"
                    :disabled="disableSave"
                    :loading="loadingSalvar"
                    class="p-button p-button-primary ml-3"
                    icon="pi pi-check"
                    label="Salvar e voltar"
                    @click="saveAndReturn"
                />
            </div>
        </div>
    </div>
    <AppInactivateDialog
        v-if="showInactivateRecordDialog"
        v-model:visible="showInactivateRecordDialog"
        :inactivatePath="inactivatePath"
        :record="record"
        :service="service"
        @onClose="onClickCloseInactivateRecord"
        @onConfirm="onClickInactivateRecord"
    >
    </AppInactivateDialog>
    <AppActivateDialog
        v-if="showActivateRecordDialog"
        v-model:visible="showActivateRecordDialog"
        :activatePath="activatePath"
        :record="record"
        :service="service"
        @onClose="onClickCloseActivateRecord"
        @onConfirm="onClickActivateRecord"
    >
    </AppActivateDialog>
</template>
<script>
export default {
    emits: ['onBeforeSave', 'onAfterSave', 'onLoadDataInsert', 'onLoadDataEdit', 'onError', 'onValidate'],
    props: {
        service: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        },
        disableSave: {
            type: Boolean,
            required: false
        },
        backPath: {
            type: String
        },
        activatePath: {
            type: String
        },
        inactivatePath: {
            type: String
        },
        activateRecord: {
            type: Boolean,
            default: true
        },
        permission: {
            type: String
        },
        showAtivarInativar: {
            type: Boolean,
            default: false
        },
        showSaveAndReturn: {
            type: Boolean,
            default: false
        },
        podeFechar: {
            type: Boolean,
            default: true
        },
        exibirToastSucesso: {
            type: Boolean,
            default: true
        },
        fecharToastAutomaticamente: {
            type: Boolean,
            default: true
        },
        saveData: {
            type: Function
        }
    },
    data() {
        return {
            loading: false,
            loadingSalvar: false,
            usingSaveAndReturn: false,
            showActivateRecordDialog: false,
            showInactivateRecordDialog: false,
            record: false,
            formOriginal: {},
            formChanged: false
        };
    },
    mounted() {
        this.$service = this.service;
        this.loadData();
    },
    computed: {
        hasChanged() {
            return Object.keys(this.form).some((field) => this.form[field] !== '' && this.form[field] !== this.formOriginal[field]);
        }
    },
    methods: {
        async saveAndReturn() {
            this.usingSaveAndReturn = true;
            await this.save();
        },
        async getSaveData() {
            if (this.saveData) {
                return await this.saveData();
            }
            return this.form;
        },
        async save() {
            try {
                this.loadingSalvar = true;
                await this.$emit('onBeforeSave');

                const isValid = await this.isValid();
                if (isValid === false) {
                    this.loadingSalvar = false;
                    return;
                }

                const saveData = await this.getSaveData();
                const response = await this.$service.save(saveData);
                this.loadingSalvar = false;
                await this.$emit('onAfterSave', response);
                this.formOriginal = { ...this.form };
                if (this.exibirToastSucesso) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Registro salvo com sucesso!',
                        life: 3000
                    });
                }
                this.loadingSalvar = false;

                if (this.backPath && this.podeFechar && (!this.showSaveAndReturn || this.usingSaveAndReturn)) {
                    this.$router.push(this.backPath);
                }
            } catch (err) {
                this.loadingSalvar = false;
                await this.$emit('onError', err);
                const message = this.getErrorMessage(err);
                if (message === 'companyId should not be empty') {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Problemas ao salvar registro! ' + 'O campo Empresa não pode ser vazio',
                        life: 3000
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Problemas ao salvar registro! ' + message,
                        life: this.fecharToastAutomaticamente ? 3000 : null
                    });
                }
            }
        },
        inactivate() {
            this.record = this.form;
            this.showInactivateRecordDialog = true;
        },
        activate() {
            this.record = this.form;
            this.showActivateRecordDialog = true;
        },
        onClickActivateRecord() {
            this.onClickCloseActivateRecord();
            this.loadData();
        },
        onClickCloseActivateRecord() {
            this.showActivateRecordDialog = false;
        },
        onClickInactivateRecord() {
            this.onClickCloseInactivateRecord();
            this.loadData();
        },
        onClickCloseInactivateRecord() {
            this.showInactivateRecordDialog = false;
        },
        async isValid() {
            let valid = true;

            await this.$emit(
                'onValidate',
                () => {
                    valid = true;
                },
                () => {
                    valid = false;
                }
            );

            return valid;
        },
        async loadData() {
            if (this.$route.params.id) {
                this.loadDataEdit();
                return;
            }

            this.loadDataInsert();
        },

        async loadDataInsert() {
            await this.$emit('onLoadDataInsert');
        },
        async loadDataEdit() {
            try {
                this.loading = true;
                const { data } = await this.$service.findById(this.$route.params.id);
                this.loading = false;
                this.formOriginal = { ...data };

                await this.$emit('onLoadDataEdit', data);
            } catch (error) {
                this.loading = false;
            }
        },
        getErrorMessage(err) {
            if (Array.isArray(err?.response?.data?.details?.response?.message)) {
                return err.response.data.details.response.message.join();
            }
            return err?.response?.data?.message;
        }
    }
};
</script>
