import AreaMedicaService from '../../../../../../services/AreaMedicaService';
import { ENUM_DIAS_SEMANA, getDiferencaDiasUteis } from '../../../../../../utils/CalendarHorarioComercial';

export class CalendarRangeSLA {
    dataInicialEFinal = [];
    diasSemanaNaoUteis = [];

    constructor(dataInicialEFinal, diasSemanaNaoUteis = [ENUM_DIAS_SEMANA.DOMINGO]) {
        this.dataInicialEFinal = dataInicialEFinal;
        this.diasSemanaNaoUteis = diasSemanaNaoUteis;
    }

    async isRangeDeDatasInvalido(propsLimiteDatas) {
        const diffEmDias = this.getDiferencaDias();
        const { data } = await new AreaMedicaService('configuracoes_sla').findAll({ filter: true });
        const limiteDatas = propsLimiteDatas || data[0]?.limiteDatas;
        return limiteDatas && diffEmDias >= limiteDatas
            ? 'Por favor selecione outro período de datas, o período da sugestão de datas deve conter no maximo ' + limiteDatas + ' dias.'
            : false;
    }

    getDiferencaDias() {
        if (this.dataInicialEFinal && this.dataInicialEFinal?.length > 1) {
            const [dataInicial, dataFinal] = this.dataInicialEFinal;
            return getDiferencaDiasUteis(dataInicial, dataFinal, this.diasSemanaNaoUteis);
        }
        return 0;
    }
}
