export function GestaoSesmtPerigoFatorRisco() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de perigos/fatores de risco!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step6',
            text: `Filtre a busca dos fatores de risco cadastrados na Duon por meio do tipo de avaliação de risco`,
            attachTo: { element: document.querySelector('.step6'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step7',
            text: `Filtre a busca dos fatores de risco por meio do grupo em que se encontra o risco em questão.`,
            attachTo: { element: document.querySelector('.step7'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step8',
            text: `Filtre a busca apenas pelos riscos que estão presentes na lista de checklist de perigos.`,
            attachTo: { element: document.querySelector('.step8'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por um <b>perigo/fator de risco</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step9',
            text: `Clique aqui para aplicar todos os filtros de busca selecionados anteriormente`,
            attachTo: { element: document.querySelector('.step9'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step10',
            text: `Utilize a opção <b>(limpar filtros)</b> para remover os filtros selecionados.`,
            attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step3',
            text: `Para cadastrar um novo perigo/fator de risco, utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step4',
            text: `<h5>Acompanhe todos os pergigos/fatores de risco a partir da listagem.</h5> 
            <p> Neste item, você encontrará a tabela de perigos/fatores de risco, contendo todas as informações relevantes a respeito de cada um.`,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de um fator de risco, nesta ação é possível:</p>
            <p><b>Alterar:</b> Alterar os dados do cadastro de um perigo/fator de risco.<p>
            <p><b>Excluir:</b> Remover o cadastro de um perigo/fator de risco.<p>
            <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico. <p>`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ];
}
