<template>
    <AppLoadingWrapper v-if="loading" />
    <template v-else>
        <DataTable
            v-model:expandedRows="expandedRows"
            row-hover
            :value="records"
            breakpoint="640px"
            class="p-datatable-sm step10"
            dataKey="id"
            responsiveLayout="stack"
            @rowExpand="onRowExpand"
            @sort="onSort"
        >
            <template #empty> Nenhum registro encontrado.</template>
            <template #loading> Carregando registros. Aguarde ...</template>
            <Column class="step8" :expander="true" headerStyle="width: 3rem" />
            <Column field="createdAt" header="Solicitado em" sortable>
                <template #body="{ data }">
                    <span class="mr-2">
                        {{ $filters.formatDateOnly(data.createdAt) }}
                    </span>
                    <span>
                        <Tag
                            v-if="data.cliente.asoRetido"
                            :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                            :value="'Aso retido'"
                            class="mr-2"
                        >
                        </Tag>
                    </span>
                </template>
            </Column>
            <Column field="funcionario.name" header="Funcionário" sortable></Column>
            <Column field="cliente.name" header="Cliente" sortField="cliente.name"></Column>
            <Column field="tipoExame.descricao" header="Tipo" sortable></Column>
            <Column field="status.descricao" header="Situação" sortable>
                <template #body="{ data }">
                    <div v-if="data.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE">
                        <Tag
                            v-tooltip="'Clique para visualizar e resolver pendências'"
                            :style="tagStatusStyleButton(data)"
                            :value="data.nomeSolicitante ? 'Pendência ' + data.nomeSolicitante : data.status.descricao"
                            class="mr-2"
                            @click="onClickExibirPendencias(data)"
                        >
                        </Tag>
                    </div>
                    <div v-if="data.status.id === enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA">
                        <Button
                            v-tooltip="'Clique aqui para reservar horários'"
                            class="p-button-outlined"
                            label="Clique aqui para reservar horários"
                            @click="onClickMenuReservaHorarios(data)"
                        />
                    </div>
                    <div v-if="data.status.id === enumStatusAgendamento.DEPOSITO_ANTECIPADO">
                        <Tag
                            v-tooltip="'Aguardando depósito antecipado para o prestador'"
                            :style="tagStatusStyleButton(data)"
                            :value="data.status.descricao"
                            class="mr-2"
                        >
                        </Tag>
                    </div>

                    <div
                        v-if="
                            data.status.id === enumStatusAgendamento.DESISTENCIA_DE_VAGA ||
                            data.status.id === enumStatusAgendamento.CANCELADO ||
                            data.status.id === enumStatusAgendamento.PENDENCIA_PRESTADOR
                        "
                    >
                        <Tag
                            v-tooltip="'Clique para visualizar motivo'"
                            :style="tagStatusStyleButton(data)"
                            :value="definirDescricaoTagStatus(data)"
                            class="mr-2"
                            @click="onClickExibirMotivo(data)"
                        ></Tag>
                    </div>
                    <div
                        v-if="
                            data.status.id !== enumStatusAgendamento.PENDENCIA_CLIENTE &&
                            data.status.id !== enumStatusAgendamento.DESISTENCIA_DE_VAGA &&
                            data.status.id !== enumStatusAgendamento.CANCELADO &&
                            data.status.id !== enumStatusAgendamento.PENDENCIA_PRESTADOR &&
                            data.status.id !== enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA &&
                            data.status.id !== enumStatusAgendamento.DEPOSITO_ANTECIPADO
                        "
                    >
                        <Tag :style="tagStatusStyle(data)" :value="definirDescricaoTagStatus(data)" class="mr-2"></Tag>
                    </div>
                </template>
            </Column>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="{ data }">
                    <Button
                        v-if="data.pcd"
                        class="p-button-text p-button"
                        icon="pi pi-cloud-upload"
                        title="Anexo do enquadramento PCD"
                        @click="onClickMenuAnexos(data)"
                    />
                    <Button
                        v-if="data.tipoExame.id === tipoExame.RETORNO_AO_TRABALHO"
                        class="p-button-text p-button"
                        icon="pi pi-cloud-upload"
                        title="Anexo liberação INSS/Especialista"
                        @click="onClickMenuAnexos(data)"
                    />
                    <Button class="p-button-text p-button step9" icon="pi pi-ellipsis-v" @click="toggleMenu($event, data)" />
                </template>
            </Column>
            <template #expansion="{ data }">
                <IndexExpansion
                    :hasError="error"
                    :loading="loadingDetalhesAgendamento"
                    :slotProps="data"
                    @loadAppointments="$emit('loadAppointments')"
                />
            </template>
        </DataTable>
        <Menu ref="menu" :model="actionItems" :popup="true" />

        <Paginator
         v-model:rows="_perPage"
        :rowsPerPageOptions="[10, 20, 50]"
        :totalRecords="total"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        :first="(_page - 1) * _perPage"
        @page="onPage($event)"
    />
    </template>

    <DialogEditar ref="editRecordDialog" v-model:form="actionRecord" @updateRecordsTabela="atualizarRecord" />

    <AppConfirmDialog
        v-if="showConfirmDialog"
        v-model:visible="showConfirmDialog"
        :loading="loadingKit"
        title="Deseja reenviar o kit desse Agendamento para o prestador e solicitante?"
        @onClose="showConfirmDialog = false"
        @onConfirm="reenviarKit"
    />

    <AppDescricaoDialog
        v-if="showMotivoDialogAlteracao"
        v-model:visible="showMotivoDialogAlteracao"
        title="Descreva a alteração do agendamento"
        @onClose="onClickCloseMotivoAlteracao"
        @onConfirm="onClickConfirmMotivoAlteracao"
    />

    <AppDescricaoDialog
        v-if="showMotivoDialogDesistencia"
        v-model:visible="showMotivoDialogDesistencia"
        title="Descreva o motivo da desistência"
        @onClose="onClickCloseMotivoDesistencia"
        @onConfirm="onClickConfirmMotivoDesistencia"
    />

    <AppDescricaoDialog
        v-if="showMotivoDialogCancelamento"
        v-model:visible="showMotivoDialogCancelamento"
        :loadingConfirm="loadingConfirmCancelamento"
        title="Descreva o motivo do cancelamento"
        @onClose="onClickCloseMotivoCancelamento"
        @onConfirm="onClickConfirmMotivoCancelamento"
    />

    <AppAnexoDialog
        v-if="showAnexoDialog"
        v-model:origemId="actionRecord.id"
        v-model:visible="showAnexoDialog"
        origem="AGENDAMENTO"
        tipo="Outro"
        title="Anexos para o Agendamento"
        @onClickCloseAnexo="onClickCloseAnexo"
        :enableRemove="false"
    />

    <DialogMotivo ref="motivoDialog" :agendamento="actionRecord" />

    <DialogFaltaConsulta ref="faltaConsultaDialog" :agendamento="actionRecord" @onLoad="emit('load')" />

    <DialogPendencia ref="dialogPendencias" :agendamento="actionRecord" />

    <DialogReservaHorario v-if="actionRecord?.id" ref="reservaHorariosDialog" :agendamentoId="actionRecord.id" @load="emit('load')" />

    <DialogReenviarLink v-model="dialogReenviarLink" :agendamento="actionRecord" />

    <DialogLinkAutoagendamento v-model:token="tokenAutoagendamento" />

    <DialogTimeline ref="dialogTimeline" :agendamento="actionRecord" />
</template>

<script setup>
import AppLoadingWrapper from '@/components/AppLoadingWrapper.vue';
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useAgendamentoStatusValidator } from '../../agendamento-atendimento/composables/useAgendamentoStatusValidator';
import AreaMedicaService from '@/services/AreaMedicaService';
import IndexExpansion from './IndexExpansion.vue';
import { useGlobalFilters } from '../../../utils/useGlobalFilters';
import StatusAgendamento from '@/enums/StatusAgendamento';
import TipoExame from '../../../enums/TipoExame';
import { checkPermission } from '../../../common/check-permission';
import { getClientAreaMedica, getClientBase } from '@/services/http';
import DialogEditar from './DialogEditar.vue';
import { clone } from 'ramda';
import AppConfirmDialog from '@/components/AppConfirmDialog.vue';
import DialogMotivo from './DialogMotivo.vue';
import DialogFaltaConsulta from './DialogFaltaConsulta.vue';
import DialogPendencia from './DialogPendencia.vue';
import DialogReservaHorario from './DialogReservaHorario.vue';
import DialogReenviarLink from './DialogReenviarLink.vue';
import DialogLinkAutoagendamento from './DialogLinkAutoagendamento.vue';
import DialogTimeline from './timeline/DialogTimeline.vue';

function atualizarRecord() {
    _records.value = _records.value.map((record) => {
        if (record.id === actionRecord.value.id) {
            return clone(actionRecord.value);
        }
        return record;
    });
}

const emit = defineEmits([
    'update:sort',
    'update:total',
    'update:loading',
    'update:page',
    'update:perPage',
    'update:filtrosExtras',
    'load',
    'loadAppointments',
    'update:records'
]);

const props = defineProps({
    sort: {
        type: String,
        required: true
    },
    loading: {
        type: Boolean,
        required: true
    },
    filtrosExtras: {
        type: Object,
        required: true
    },
    perPage: {
        type: Number,
        required: true
    },
    page: {
        type: Number,
        required: true
    },
    filter: {
        type: Array,
        required: true
    },
    total: {
        type: Number,
        required: true
    },
    records: {
        type: Array,
        required: true
    }
});

const expandedRows = ref([]);
const error = ref(false);
const loadingDetalhesAgendamento = ref(false);
const actionRecord = ref({});
const dialogPendencias = ref(null);
const dialogReenviarLink = ref(null);
const reservaHorariosDialog = ref(null);
const menu = ref(null);
const actionItems = ref([]);
const tokenAutoagendamento = ref('');
const {
    enumStatusAgendamento,
    isStatusEmAberto,
    isStatusEmAndamento,
    isStatusAguardandoConfColaborador,
    isStatusPendenciaCliente,
    isStatusDiaDaConsulta,
    isStatusDesistenciaDeVaga,
    isStatusConcluido,
    isStatusCancelado,
    isStatusAgendado,
    isStatusConfirmacaoAgenda,
    isStatusAguardandoAplicacaoRisco,
    isStatusPendenciaResolvida,
    isStatusKitPendente,
    isStatusPendente,
    isStatusNaoConfirmado,
    isStatusDepositoAntecipado,
    isStatusAguardandoConfComparecimento,
    isStatusFaltaConsulta
} = useAgendamentoStatusValidator();
const tipoExame = TipoExame;
const serviceAgendamentoFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
const toast = useToast();
const $filters = useGlobalFilters();

const _records = computed({
    get() {
        return props.records;
    },
    set(value) {
        emit('update:records', value);
    }
});

const _sort = computed({
    get() {
        return props.sort;
    },
    set(value) {
        emit('update:sort', value);
    }
});

const _page = computed({
    get() {
        return props.page;
    },
    set(value) {
        emit('update:page', value);
    }
});

const _perPage = computed({
    get() {
        return props.perPage;
    },
    set(value) {
        emit('update:perPage', value);
    }
});

const {
    toggleMenu,
    onClickMenuReservaHorarios,
    onClickExibirMotivo,
    onRowExpand,
    onClickExibirPendencias,
    showConfirmDialog,
    showAnexoDialog,
    onClickCloseAnexo,
    reenviarKit,
    loadingKit,
    showMotivoDialogCancelamento,
    loadingConfirmCancelamento,
    onClickConfirmMotivoCancelamento,
    onClickCloseMotivoCancelamento,
    showMotivoDialogAlteracao,
    onClickCloseMotivoAlteracao,
    onClickConfirmMotivoAlteracao,
    showMotivoDialogDesistencia,
    onClickConfirmMotivoDesistencia,
    onClickCloseMotivoDesistencia,
    dialogTimeline,
    faltaConsultaDialog,
    onClickMenuAnexos
} = useAcoesTabela();

function tagStatusStyle(data) {
    if (data?.status?.id === StatusAgendamento.PENDENCIA_PRESTADOR) {
        return {
            'background-color': 'var(--surface-900)',
            color: 'var(--surface-200)'
        };
    }

    return {
        'background-color': `var(--${data.status.primevueColor})`,
        color: `var(--${changeColorTone(data.status.primevueColor, 900)})`
    };
}

function tagStatusStyleButton(data) {
    return {
        'background-color': `var(--${data.status.primevueColor})`,
        color: `var(--${changeColorTone(data.status.primevueColor, 900)})`,
        cursor: 'pointer'
    };
}

function changeColorTone(primevueColor, newTone) {
    if (!primevueColor) return;
    const [color, tone] = primevueColor.split('-');
    return color + '-' + (newTone || tone);
}

function definirDescricaoTagStatus(data) {
    if (data.status.id === StatusAgendamento.PENDENCIA_PRESTADOR) {
        return 'Pendência: ' + data.company.name;
    }
    return data.status.descricao;
}

function onSort(event) {
    _sort.value = `${event.sortField} ${event.sortOrder}`;
    emit('load');
}

function onPage(event) {
    _page.value = event.page + 1;
    emit('loadAppointments');
}

const editRecordDialog = ref(null);
function useAcoesTabela() {
    const isDiaProximoDaConsulta = ref(false);
    const loadingKit = ref(false);
    const showConfirmDialog = ref(false);
    const faltaConsultaDialog = ref(null);
    const dialogTimeline = ref(null);
    const motivoDialog = ref(null);
    const showAnexoDialog = ref(false);
    const showMotivoDialogDesistencia = ref(false);
    const showMotivoDialogAlteracao = ref(false);
    const showMotivoDialogCancelamento = ref(false);
    const loadingConfirmCancelamento = ref(false);

    const serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');

    async function adicionarStatusAgendamento(agendamento, idStatus, descricao) {
        await serviceAgendamentoStatus.save({
            descricao,
            agendamento: agendamento,
            status: { id: idStatus },
            observacao: descricao
        });
    }

    watch(
        actionRecord,
        () => {
            actionItems.value = [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    disabled:
                        isStatusDesistenciaDeVaga(actionRecord.value) ||
                        isStatusCancelado(actionRecord.value) ||
                        isStatusNaoConfirmado(actionRecord.value) ||
                        isStatusAguardandoAplicacaoRisco(actionRecord.value) ||
                        isStatusAguardandoConfComparecimento(actionRecord.value) ||
                        isStatusFaltaConsulta(actionRecord.value) ||
                        isStatusConcluido(actionRecord.value) ||
                        !checkPermission('gestamedica_agendamentos:editar'),
                    command: () => onClickMenuEditar(actionRecord.value)
                },
                {
                    label: 'Reenviar Kit',
                    icon: 'pi pi-sync',
                    disabled:
                        (!isStatusAgendado(actionRecord.value) && !isStatusDiaDaConsulta(actionRecord.value)) ||
                        isStatusNaoConfirmado(actionRecord.value) ||
                        isStatusCancelado(actionRecord.value) ||
                        isStatusConfirmacaoAgenda(actionRecord.value) ||
                        isStatusDesistenciaDeVaga(actionRecord.value) ||
                        isStatusEmAndamento(actionRecord.value) ||
                        isStatusAguardandoAplicacaoRisco(actionRecord.value) ||
                        isStatusEmAberto(actionRecord.value) ||
                        isStatusKitPendente(actionRecord.value) ||
                        isStatusDepositoAntecipado(actionRecord.value) ||
                        isStatusPendenciaResolvida(actionRecord.value) ||
                        isStatusFaltaConsulta(actionRecord.value) ||
                        isStatusPendente(actionRecord.value) ||
                        isStatusAguardandoConfComparecimento(actionRecord.value) ||
                        isStatusAguardandoConfColaborador(actionRecord.value) ||
                        (isStatusPendenciaCliente(actionRecord.value) && !checkPermission('gestamedica_agendamentos:reenviarkit')),
                    command: () => onClickMenuKit(actionRecord.value)
                },
                {
                    label: 'Desistência de Vaga',
                    icon: 'pi pi-thumbs-down',
                    disabled:
                        isStatusDesistenciaDeVaga(actionRecord.value) ||
                        isStatusCancelado(actionRecord.value) ||
                        isStatusDiaDaConsulta(actionRecord.value) ||
                        isStatusAguardandoConfComparecimento(actionRecord.value) ||
                        isStatusNaoConfirmado(actionRecord.value) ||
                        isStatusFaltaConsulta(actionRecord.value) ||
                        isStatusConcluido(actionRecord.value) ||
                        !checkPermission('gestamedica_agendamentos:desistencia'),
                    command: () => onClickMenuDesistencia(actionRecord.value)
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    disabled:
                        isStatusCancelado(actionRecord.value) ||
                        isStatusDiaDaConsulta(actionRecord.value) ||
                        isStatusDesistenciaDeVaga(actionRecord.value) ||
                        isStatusAguardandoConfComparecimento(actionRecord.value) ||
                        isStatusFaltaConsulta(actionRecord.value) ||
                        isStatusConcluido(actionRecord.value) ||
                        isStatusNaoConfirmado(actionRecord.value) ||
                        !checkPermission('gestamedica_agendamentos:cancelar'),
                    command: () => onClickMenuDelete(actionRecord.value)
                },
                {
                    label: 'Anexos',
                    icon: 'pi pi-paperclip',
                    disabled: () => !checkPermission('gestamedica_agendamentos:anexos'),
                    command: () => onClickMenuAnexos(actionRecord.value)
                },
                {
                    label: 'Falta consulta',
                    icon: 'pi pi-times',
                    command: () => onClickMenuFaltaConsulta(actionRecord.value),
                    disabled: () =>
                        isStatusDesistenciaDeVaga(actionRecord.value) ||
                        isStatusCancelado(actionRecord.value) ||
                        isStatusNaoConfirmado(actionRecord.value) ||
                        isStatusFaltaConsulta(actionRecord.value) ||
                        isStatusConcluido(actionRecord.value)
                },
                {
                    label: 'Timeline',
                    icon: 'pi pi-sort-amount-down-alt',
                    disabled: () => !checkPermission('gestamedica_agendamentos:timeline'),
                    command: () => onClickMenuTimeline()
                },
                {
                    label: 'Reenviar Link',
                    icon: 'pi pi-link',
                    visible: () => actionRecord.value.idStatus === enumStatusAgendamento.AUTOAGENDAMENTO,
                    command: () => (dialogReenviarLink.value = true)
                }
            ];
        },
        { deep: true }
    );

    async function verificarSeEstaProximoDoDiaDaConsulta(agendamento) {
        if (agendamento.status.id === enumStatusAgendamento?.AGENDADO || agendamento.status.id === enumStatusAgendamento?.DIA_DA_CONSULTA) {
            const { data } = await getClientBase().get(`configuracoes-agendamentos-cancelar/validar-dia-proximo-da-consulta/${agendamento.id}`);
            isDiaProximoDaConsulta.value = data;
        } else {
            isDiaProximoDaConsulta.value = false;
        }
    }

    function onClickMenuReservaHorarios(record) {
        actionRecord.value = record;
        setTimeout(() => {
            reservaHorariosDialog?.value?.openDialog?.(record);
        }, 70);
    }

    function onClickExibirMotivo(record) {
        actionRecord.value = record;
        motivoDialog?.value.openDialog();
    }

    function onClickMenuAnexos(record) {
        actionRecord.value = record;
        showAnexoDialog.value = true;
    }

    function onClickCloseAnexo() {
        showAnexoDialog.value = false;
    }

    async function onRowExpand(event) {
        try {
            error.value = false;
            loadingDetalhesAgendamento.value = true;
            const { data } = await serviceAgendamentoFuncionario.findById(event.data.id);
            expandedRows.value.forEach((element) => {
                if (element.id === data.agendamento.id) {
                    element.unidade = data.unidade;
                    element.setor = data.setor;
                    element.cargo = data.cargo;
                }
            });
            loadingDetalhesAgendamento.value = false;
        } catch (error) {
            loadingDetalhesAgendamento.value = false;
            error.value = true;
            toast.add({
                severity: 'error',
                summary: 'Problemas ao exibir os detalhes do agendamento!',
                detail: error.message,
                life: 3000
            });
        }
    }

    function onClickExibirPendencias(record) {
        actionRecord.value = record;
        dialogPendencias?.value.openDialog();
    }

    async function toggleMenu(event, data) {
        actionRecord.value = data;
        menu?.value.toggle(event);
    }

    async function onClickMenuEditar(record) {
        await verificarSeEstaProximoDoDiaDaConsulta(record);

        if (typeof isDiaProximoDaConsulta.value === 'number') {
            toast.add({
                severity: 'info',
                summary: 'Você não pode alterar esse agendamento!',
                detail: `O prazo para alterar o agendamento é de até ${isDiaProximoDaConsulta.value}h antes da consulta!`,
                life: 3000
            });
            return;
        }

        actionRecord.value = record;
        if (
            actionRecord.value.status.id === enumStatusAgendamento.EM_ABERTO ||
            actionRecord.value.status.id === enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA
        ) {
            editRecordDialog?.value.openDialog();
        } else {
            showMotivoDialogAlteracao.value = true;
        }
    }

    async function onClickMenuDesistencia(record) {
        await verificarSeEstaProximoDoDiaDaConsulta(record);

        if (typeof isDiaProximoDaConsulta.value === 'number') {
            toast.add({
                severity: 'info',
                summary: 'Você não pode desistir do agendamento!',
                detail: `O prazo para desistir do agendamento é de até ${isDiaProximoDaConsulta.value}h antes da consulta!`,
                life: 3000
            });
            return;
        }

        actionRecord.value = record;
        showMotivoDialogDesistencia.value = true;
    }

    async function onClickConfirmMotivoCancelamento(motivo) {
        try {
            loadingConfirmCancelamento.value = true;

            const descricao = `Mudança de status para Cancelado. Motivo: ${motivo}`;
            await adicionarStatusAgendamento(actionRecord.value, enumStatusAgendamento.CANCELADO, descricao);
            toast.add({
                severity: 'success',
                summary: 'Registro cancelado com sucesso.',
                life: 3000
            });

            showMotivoDialogCancelamento.value = false;
            loadingConfirmCancelamento.value = false;
            emit('load');
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: error.response?.data?.message || error.message,
                life: 3000
            });
            loadingConfirmCancelamento.value = false;
        }
    }

    function onClickCloseMotivoCancelamento() {
        showMotivoDialogCancelamento.value = false;
    }

    function onClickCloseMotivoAlteracao() {
        showMotivoDialogAlteracao.value = false;
    }

    async function onClickConfirmMotivoAlteracao(motivo) {
        try {
            const descricao = `Solicitação de alteração: ${motivo}`;
            await adicionarStatusAgendamento(actionRecord.value, enumStatusAgendamento.PENDENCIA_PRESTADOR, descricao);

            showMotivoDialogAlteracao.value = false;
            emit('load');
            toast.add({
                severity: 'success',
                summary: 'Processado com sucesso, sua solicitação será analisada.',
                life: 3000
            });
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: error.response?.data?.message || error.message,
                life: 3000
            });
        }
    }

    async function onClickConfirmMotivoDesistencia(motivo) {
        try {
            const descricao = `Mudança de status para Desistência de Vaga. Motivo: ${motivo}`;
            await adicionarStatusAgendamento(actionRecord.value, enumStatusAgendamento.DESISTENCIA_DE_VAGA, descricao);
            toast.add({
                severity: 'success',
                summary: 'Desistência registrada com sucesso.',
                life: 3000
            });

            showMotivoDialogDesistencia.value = false;
            emit('load');
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: error.response?.data?.message || error.message,
                life: 3000
            });
        }
    }

    function onClickCloseMotivoDesistencia() {
        showMotivoDialogDesistencia.value = false;
    }

    async function onClickMenuDelete(record) {
        await verificarSeEstaProximoDoDiaDaConsulta(record);
        if (typeof isDiaProximoDaConsulta.value === 'number') {
            toast.add({
                severity: 'info',
                summary: 'Você não pode cancelar esse agendamento!',
                detail: `O prazo para cancelar o agendamento é de até ${isDiaProximoDaConsulta.value}h antes da consulta!`,
                life: 3000
            });
            return;
        }

        actionRecord.value = record;
        showMotivoDialogCancelamento.value = true;
    }

    function onClickMenuFaltaConsulta(record) {
        actionRecord.value = record;
        faltaConsultaDialog?.value.openDialog();
    }

    function onClickMenuTimeline() {
        dialogTimeline?.value.openDialog();
    }

    function onClickMenuKit(record) {
        actionRecord.value = record;
        showConfirmDialog.value = true;
    }

    async function reenviarKit() {
        try {
            loadingKit.value = true;
            const path = `agendamento/enviar-kit/${actionRecord.value.id}`;
            await getClientAreaMedica().post(path);
            loadingKit.value = false;
            showConfirmDialog.value = false;
            toast.add({
                severity: 'success',
                summary: 'O Kit de Agendamento foi enviado com sucesso!',
                life: 3000
            });
        } catch (error) {
            loadingKit.value = false;
            toast.add({
                severity: 'error',
                summary: error.response?.data?.message || error.message,
                life: 3000
            });
        }
    }

    return {
        toggleMenu,
        onClickMenuReservaHorarios,
        onClickExibirMotivo,
        onRowExpand,
        onClickExibirPendencias,
        onClickMenuKit,
        showConfirmDialog,
        dialogTimeline,
        faltaConsultaDialog,
        showAnexoDialog,
        onClickCloseAnexo,
        reenviarKit,
        loadingKit,
        loadingConfirmCancelamento,
        showMotivoDialogCancelamento,
        onClickConfirmMotivoCancelamento,
        onClickCloseMotivoCancelamento,
        showMotivoDialogAlteracao,
        onClickCloseMotivoAlteracao,
        onClickConfirmMotivoAlteracao,
        showMotivoDialogDesistencia,
        onClickConfirmMotivoDesistencia,
        onClickCloseMotivoDesistencia,
        onClickMenuAnexos
    };
}
</script>
