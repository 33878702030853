import { getClientBase } from '@/services/http';

export class NovaFuncaoService {
    retirarPendenciaFuncionario(funcionarioId) {
        return getClientBase().post(`/mudanca-de-funcao/customer/employees/${funcionarioId}`);
    }

    realizarMudancaFuncao(funcionarioId, funcionarioDto) {
        return getClientBase().patch(`/mudanca-de-funcao/customer/employees/${funcionarioId}`, funcionarioDto);
    }
}
