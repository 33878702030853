<template>
    <Dialog v-model:visible="open" :closable="false" :style="{ width: '450px' }" class="p-fluid" header="Filtros">
        <div class="field">
            <label>Filtro por Status</label>
            <MultiSelect
                v-model="_filtrosExtras.status"
                :filter="true"
                :options="status"
                dataKey="id"
                optionLabel="descricao"
                placeholder="Selecione um status..."
            />
        </div>
        <div class="field mt-3">
            <label>Filtro por Tipo de Exame</label>
            <MultiSelect
                v-model="_filtrosExtras.tipoExame"
                :filter="true"
                :options="exames"
                dataKey="id"
                optionLabel="descricao"
                placeholder="Selecione um Tipo de Exame..."
            />
        </div>
        <div class="field mt-3">
            <label>Filtro por Data</label>
            <div class="flex row w-12">
                <Calendar
                    id="range"
                    v-model="_filtrosExtras.datas"
                    :manualInput="false"
                    class="w-11"
                    placeholder="Selecione as datas..."
                    selectionMode="range"
                />
                <Button v-tooltip.bottom="'Limpar range de datas'" class="w-1" icon="pi pi-undo" @click="limpar()" />
            </div>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" @click="closeDialogFiltro" />
            <Button class="p-button-outlined p-button-success" icon="pi pi-check" label="Aplicar" @click="emit('loadAppointments')" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, defineExpose } from 'vue';
import { useAgendamentosFiltros } from './composables/useAgendamentosFiltros';

const emit = defineEmits(['loadAppointments', 'update:filtrosExtras', 'update:exames']);
const props = defineProps({
    filtrosExtras: {
        type: Object,
        required: true
    },
    status: {
        type: Array,
        required: true
    },
    exames: {
        type: Array,
        required: true
    }
});

const { _filtrosExtras } = useAgendamentosFiltros(props, emit);

const open = ref(false);

function openDialogFiltro() {
    open.value = true;
}

function closeDialogFiltro() {
    open.value = false;
}

function limpar() {
    _filtrosExtras.value.datas = null;
}

defineExpose({
    openDialogFiltro
});
</script>
