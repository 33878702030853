const StatusAgendamento = {
    EM_ABERTO: 1,
    EM_ANDAMENTO: 2,
    AGUARDANDO_CONF_COLABORADOR: 3,
    PENDENCIA_CLIENTE: 4,
    AGUARDANDO_DATA: 5,
    DIA_DA_CONSULTA: 6,
    AGUARDANDO_CONF_COMPARECIMENTO: 7,
    AGENDADO: 8,
    DESISTENCIA_DE_VAGA: 9,
    CANCELADO: 10,
    REAGENDADO: 11,
    AGUARDANDO_APLICACAO_RISCO: 12,
    PROCESSANDO_AGENDAMENTO: 13,
    PENDENCIA_RESOLVIDA: 14,
    KIT_PENDENTE: 15,
    PENDENCIA_PRESTADOR: 16,
    DEPOSITO_ANTECIPADO: 17,
    NAO_CONFIRMADO: 18,
    AGUARDANDO_CONFIRMACAO_AGENDA: 19,
    FALTA_CONSULTA: 20,
    PROCESSANDO_SOLICITACAO: 21,
    CONCLUIDO: 22,
    AGUARDANDO_CONFIRMACAO_DO_PRESTADOR: 26,
    AUTOAGENDAMENTO: 27
}

export default StatusAgendamento;
