<template>
    <AppOnePageDropdown
        ref="dropdown"
        v-bind="$attrs"
        v-model="value"
        :idValue="idValue"
        optionLabel="name"
        placeholder="Selecione o prestador..."
        :service="$service"
        :itemSize="itemSize"
        :filtrosExtras="filtrosExtras"
        :resultWithItems="false"
        :autoLoad="autoLoad"
        :loadAoAdicionarFiltrosExtras="false"
        :styleOptions="colorRowPrestador"
        @change="verificarAcessoPrestador"
        :renderOption="customRenderOption"
        :groupKey="groupKey"
        :disabled="disabled"
    >
    </AppOnePageDropdown>

    <ConfirmLiberarPrestadorDialog v-if="dialogConfirmacaoLiberacao" @onClose="cancelarLiberacaoPrestador" @onLiberar="liberarPrestador" />

    <Dialog v-model:visible="dialogLiberarPrestador" :style="{ width: '750px' }" header="Liberar prestador" :modal="true">
        <PrestadorCliente :prestador="prestadorLiberar" :cliente="clienteLiberar"></PrestadorCliente>
    </Dialog>
</template>
<script>
import EventBus from '../../../event-bus';
import BaseService from '@/services/BaseService';
import AreaMedicaService from '@/services/AreaMedicaService';
import ConfirmLiberarPrestadorDialog from './ConfirmLiberarPrestadorDialog';
import PrestadorCliente from '../../prestador-cliente/Index.vue';

export default {
    name: 'DropdownPrestadorCliente',
    components: { ConfirmLiberarPrestadorDialog, PrestadorCliente },
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        idCliente: {
            type: [Number, Array]
        },
        idExame: {
            type: [Number, Array]
        },
        idCidade: {
            type: Number
        },
        autoLoad: {
            type: Boolean
        },
        filtrosExtras: {
            type: Object
        },
        groupKey: {
            type: Number
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null,
            $serviceCliente: null,
            codigosPrestadoresLiberados: [],
            dialogConfirmacaoLiberacao: false,
            dialogLiberarPrestador: false,
            clientesLiberar: []
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        idClienteArray() {
            return Array.isArray(this.idCliente) ? this.idCliente : [this.idCliente];
        },
        idExameArray() {
            return Array.isArray(this.idExame) ? this.idExame : [this.idExame];
        },
        clientesSeparadosPorVirgula() {
            return this.idClienteArray.join();
        },
        examesSeparadosPorVirgula() {
            return this.idExameArray.join();
        }
    },
    watch: {
        idCliente() {
            if (this.clientesSeparadosPorVirgula) {
                this.load();
            }
        },
        idCidade() {
            if (this.clientesSeparadosPorVirgula) {
                this.load();
            }
        },
        idExame() {
            if (this.clientesSeparadosPorVirgula) {
                this.load();
            }
        },
        filtrosExtras() {
            if (this.clientesSeparadosPorVirgula) {
                this.load();
            }
        }
    },
    async created() {
        this.$serviceCliente = new BaseService('/customers');
        this.$service = new BaseService('/suppliers/ordered/0/0/0');
        if (this.autoLoad) {
            this.load();
        }
        EventBus.on('prestador-liberado', () => {
            this.dialogLiberarPrestador = false;
            this.dialogConfirmacaoLiberacao = false;
            this.dialogInformativeSupplierMessage = true;

            this.buscarPrestadoresLiberados();
        });
    },
    mounted() {
        this.buscarPrestadoresLiberados();
    },
    methods: {
        customRenderOption(slotProps) {
            const nome =
                (slotProps.option?.code ? slotProps.option?.code + ' - ' : ' ') +
                slotProps.option?.name +
                ' | ' +
                slotProps.option?.city?.cidade +
                ' - ' +
                slotProps.option?.city?.estado;

            return nome;
        },
        load() {
            const clientes = this.clientesSeparadosPorVirgula;
            const exames = this.examesSeparadosPorVirgula ? this.examesSeparadosPorVirgula : '0';
            const cidades = this.idCidade;
            if (!cidades || !clientes.length) return;
            this.$service = new BaseService(`/suppliers/ordered/${clientes}/${cidades}/${exames}`);
            if (this.clientesSeparadosPorVirgula) {
                setTimeout(() => {
                    this.$refs.dropdown.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
                    this.buscarPrestadoresLiberados();
                }, 100);
            }
        },
        colorRowPrestador(slotProps) {
            const prestadorHabilitado = this.codigosPrestadoresLiberados?.indexOf(slotProps.option.id) != -1;
            const color = prestadorHabilitado ? '#000000' : '#A9A9A9';

            return {
                color
            };
        },
        async buscarPrestadoresLiberados() {
            if (!this.idCliente) {
                return;
            }

            const idsConsultar = this.idClienteArray;

            if (!idsConsultar.length) {
                return;
            }

            for (const idCliente of idsConsultar) {
                const path = 'prestador-clientes/client';
                const service = new AreaMedicaService(`${path}/${idCliente}`);
                const response = await service.findAll({});
                for (const prestadores of response.data) {
                    this.codigosPrestadoresLiberados.push(prestadores?.prestador?.id);
                }
            }
        },
        async verificarAcessoPrestador($event) {
            const record = $event.value;
            this.clientesLiberar = [];

            if (!record) {
                return;
            }

            for (const idCliente of this.idClienteArray) {
                try {
                    const path = 'prestador-clientes/client';
                    const service = new AreaMedicaService(`${path}/${idCliente}/${record.id}`);
                    const response = await service.findAll({});
                    if (response.data.condicao) {
                        const responseCliente = await this.$serviceCliente.findById(idCliente);
                        this.clientesLiberar.push(responseCliente.data);
                    }
                } catch (error) {
                    this.$toast.add({
                        severity: 'info',
                        summary: error?.response?.data?.message || error?.message,
                        life: 3000
                    });
                }
            }
            if (this.clientesLiberar.length) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'O prestador não atende os funcionários da empresa: ' + this.clientesLiberar.map((p) => p.name).join() + '\n',
                    life: 5000
                });
                this.dialogConfirmacaoLiberacao = true;
            }
        },
        liberarPrestador() {
            this.dialogLiberarPrestador = true;

            this.prestadorLiberar = this.value.id;
            this.clienteLiberar = this.clientesLiberar?.[0]?.id;
        },
        cancelarLiberacaoPrestador() {
            this.dialogConfirmacaoLiberacao = false;
            this.value = null;
        }
    }
};
</script>
