<template>
    <Panel class="mb-3" v-if="funcionario.pcd">
        <template #header>
            <div class="flex flex-row w-full justify-content-between align-items-center">
                <div>
                    <span class="font-bold">{{ funcionario?.name }}</span>
                </div>
                <div>
                    <Button
                        class="p-button-link"
                        icon="pi pi-paperclip"
                        label="Anexar documentos"
                        @click="toggle"
                        :disabled="!form.tiposDeficiencia.some((p) => p.possui)"
                        :class="{
                            'p-button-outlined p-button-danger': submittedAnexo && !funcionario?.anexos?.filter((p) => p.tipo === 'LAUDO_PCD')?.length
                        }"
                    />
                    <OverlayAnexos ref="oa" :funcionario="funcionario" tipoAnexo="LAUDO_PCD" />
                </div>
            </div>
        </template>
        <DataTable :value="form.tiposDeficiencia">
            <template #header v-if="getPCDMessage(form.tiposDeficiencia)">
                <Message :closable="false">
                    {{ getPCDMessage(form?.tiposDeficiencia) }}
                </Message>
            </template>
            <Column field="name" header="Tipo de deficiência"></Column>
            <Column field="orientacao" v-if="false">
                <!-- Por enquanto não será utilizado -->
                <template #body>
                    <Button class="p-button p-button-text text-blue-600" label="Ver orientação" />
                </template>
            </Column>
            <Column field="possui" bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <InputSwitch
                        v-model="slotProps.data.possui"
                        @change="limpaArrayLaudoPCDeAtualizaTipoPCD(form?.tiposDeficiencia, slotProps?.data?.name)"
                    />
                </template>
            </Column>
        </DataTable>
    </Panel>
</template>
<script setup>
import { computed, defineProps, ref } from 'vue';
import OverlayAnexos from './components/OverlayAnexos.vue';

const oa = ref(null);
const props = defineProps({
    funcionario: Object,
    submittedAnexo: Boolean
});
const funcionario = computed({
    get() {
        return props.funcionario;
    }
});
const form = ref({
    tiposDeficiencia: [
        { name: 'Física', possui: false },
        { name: 'Auditiva', possui: false },
        { name: 'Visual', possui: false },
        { name: 'Intelectual', possui: false },
        { name: 'Multipla', possui: false }
    ]
});

function limpaArrayLaudoPCDeAtualizaTipoPCD(tiposDeficiencia, name) {
    tiposDeficiencia.forEach((tipo) => {
        if (tipo.name !== name) tipo.possui = false;
    });
}
function toggle(event) {
    oa.value?.toggle(event);
}
function getPCDMessage(list) {
    const index = list.findIndex((el) => el.possui);
    switch (list[index]?.name) {
        case 'Física':
            return 'Anexar relatório ou laudo médico com o diagnóstico e a limitação do indíviduo.';
        case 'Auditiva':
            return 'Anexar o exame audimetrico contendo o audiograma (gráfico com as médidads da audiometria).';
        case 'Visual':
            return 'Relatório ou laudo oftalmológico com acuidade visual.';
        case 'Intelectual':
            return 'Relatório ou laudo médico com o diagnostico e as habilidades adaptativas prejudicadas. A patologia apresentada deve ser incurável ou crônica.';
        case 'Multipla':
            return 'Associação de duas ou mais deficiências. Anexar comprovação de cada uma delas.';
        default:
            return null;
    }
}
</script>

<style lang="scss" scoped>
:deep(.p-panel-content) {
    margin: 0;
    padding: 0;
}
</style>
