export function EstruturaClienteList() {
    return [
        {
        id: 'step0',
        text: `<h3>Bem vindo a tela de clientes!</h3>A partir desse guia iremos mostrar as principais funções! `,
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step1',
        text: `Aqui você pode pesquisar por um <b>cliente</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
        attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step2',
        text: `Utilize a opção <b>(limpar filtros)</b> para remover os filtros selecionados.`,
        attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step3',
        text: `Para cadastrar um novo cliente, utilize o botão <b>+Novo</b>.`,
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: `<h5>Acompanhe todos os clientes a partir da listagem.</h5>
        <p> Neste item, você encontrará a tabela de clientes, contendo todas as informações relevantes a respeito de cada um.`,
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step5',
        text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de um cliente, nesta ação é possível:</p>
        <p><b>Alterar:</b> Alterar os dados do cadastro de um usuário.<p>
        <p><b>Excluir:</b> Excluir os dados de um usuário cadastrado.<p>
        <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico. <p>
        <p><b>Método de Atendimento Dinâmico:</b> Ao acessar esse item, será aberto uma pequena tela para incluir métodos de atendimento dinâmicos (Por tipo de exame).<p>`,
        attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    }
]
}