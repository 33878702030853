<template>
    <div class="grid justify-content-evenly border-2 border-200 border-round-md my-4 mx-0 p-3">
        <div class="col-12 md:col-4">
            <label for="funcionario"> Nome do funcionário <span class="p-error">*</span> </label>
            <InputText :modelValue="funcionario.name" class="w-full mt-2" disabled type="text" />
        </div>
        <div class="col-12 md:col-4">
            <label for="telefone"> Telefone do funcionário <span class="p-error">*</span> </label>
            <InputMask
                v-model="telefoneFuncionario"
                :autoClear="false"
                :class="{ 'p-invalid': $v?.phone.$error }"
                class="w-full mt-2"
                mask="(99) 99999-9999"
                type="tel"
            />
            <small v-if="$v?.phone.$error" class="p-error">
                {{ $v?.phone.$errors[0].$message }}
            </small>
        </div>
        <div class="col-12 md:col-4">
            <label for="tipoExame"> Enviar por <span class="p-error">*</span> </label>
            <div class="flex flex-row mt-3">
                <div class="flex align-items-center">
                    <RadioButton v-model="form.formaEnvio" inputId="whatsapp" name="envio" :value="formaDeEnvio.WHATSAPP" />
                    <label for="whatsapp" class="ml-2">Whatsapp</label>
                </div>
                <div class="flex align-items-center ml-6">
                    <RadioButton v-model="form.formaEnvio" inputId="email" name="envio" :value="formaDeEnvio.EMAIL" />
                    <label for="email" class="ml-2">Email</label>
                </div>
            </div>
            <InputText
                v-if="form.formaEnvio === formaDeEnvio.EMAIL"
                v-model="funcionario.email"
                :class="{ 'p-invalid': $v?.email.$error }"
                class="w-full mt-2"
                type="mail"
            />
            <small v-if="$v?.email.$error" class="p-error">
                {{ $v?.email.$errors[0].$message }}
            </small>
        </div>
    </div>
</template>
<script setup>
import { defineEmits, defineProps, defineExpose, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf, email } from '@vuelidate/validators';
import EnumFormaEnvio from '@/enums/EnumFormaEnvio';

const emit = defineEmits(['update']);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const formaDeEnvio = EnumFormaEnvio;

const form = computed({
    get() {
        return props.formData;
    },
    set(novoValor) {
        emit('update:formData', novoValor);
    }
});
const funcionario = computed({
    get() {
        return form.value.funcionarios?.[0] || {};
    },
    set(novoValor) {
        form.value.funcionarios = [novoValor];
    }
});

const telefoneFuncionario = computed({
    get() {
        return form.value.funcionarios?.[0]?.phone || '';
    },
    set(novoValor) {
        funcionario.value.phone = novoValor;
        form.value.telefoneFuncionario = novoValor;
    }
});

const regrasDeValidacaoDosCampos = computed(() => ({
    phone: {
        required: helpers.withMessage('Campo obrigatório', required),
        regex: helpers.withMessage('Digite um telefone válido', (value) => /^\(\d{2}\) \d{5}-\d{4}$/.test(value))
    },
    email: {
        requiredIf: helpers.withMessage('Campo obrigatório', requiredIf(form.value.formaEnvio === formaDeEnvio.EMAIL)),
        email: helpers.withMessage('Digite um email válido', email)
    }
}));
const $v = useVuelidate(regrasDeValidacaoDosCampos, funcionario);

onMounted(() => {
    form.value.formaEnvio = formaDeEnvio.WHATSAPP;
});

defineExpose({
    validarFormulario: async () => await $v.value.$validate()
});
</script>
