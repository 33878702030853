export function EstruturaHierarquiaList() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de hierarquia!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por uma <b>hierarquia</b> utilizando uma palavra-chave para identificar o cadastro e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }, 
         { 
            id: 'step3',
            text: `Para cadastrar uma nova hierarquia,utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        } ,
        {
            id: 'step4',
            text: `<h5>Acompanhe todos as hierarquias a partir da listagem.</h5>
            <p> Neste item, você encontrará a tabela de hierarquias, contendo todas as informações relevantes a respeito de cada cliente, unidade, setor e cargo vinculados ao cadastro`,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar uma hierarquia cadastrada, nesta ação é possível:</p>
            <p><b>Alterar:</b> Alterar os dados do cadastro de uma hierarquia.<p>
            <p><b>Excluir:</b> Excluir uma hierarquia cadastrada.<p>
            <p><b>Histórico de Ação:</b> Consiste em um registro detalhado com todas as ações realizadas por um usuário no cadastro em especifico.  <p>`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ]
}