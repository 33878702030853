import dayjs from 'dayjs';
import AreaMedicaService from '../../../../../services/AreaMedicaService';
import { EnumTipoExame } from '../../../../../enums/TipoExame';

export class SolicitadorPreSolicitacao {
    loading = false;
    $toast = null;

    solicitacoesDtoFactory = () => ({
        selecionadas: [],
        semErros: []
    });

    preSolicitacoesDto = this.solicitacoesDtoFactory();

    async iniciarAgendamentos(todasSolicitacoes, solicitacoesSelecionadas, $toast, clienteAgendaHorario, newRequestAdd) {
        this.loading = true;
        this.preSolicitacoesDto = this.solicitacoesDtoFactory();
        this.$toast = $toast;
        if (!solicitacoesSelecionadas?.length) {
            this.$toast.add({ severity: 'error', summary: 'Selecione alguma pré-solicitação!', life: 6000 });
            this.loading = false;
            return;
        }
        const idsSelecionados = solicitacoesSelecionadas.map((p) => p.id);
        this.preSolicitacoesDto.selecionadas = todasSolicitacoes.filter((p) => idsSelecionados.includes(p.id));

        this._carregarPreSolicitacoesSemErros(clienteAgendaHorario, newRequestAdd);
        for (const agendamentoPreSolicitado of this.preSolicitacoesDto.semErros) {
            await this._presolicitaAgendamento(agendamentoPreSolicitado?.id);
        }

        this.loading = false;
        const naoHouveErros =
            this.preSolicitacoesDto.semErros?.length > 0 && this.preSolicitacoesDto.semErros?.length === this.preSolicitacoesDto.selecionadas?.length;
        return naoHouveErros;
    }

    _carregarPreSolicitacoesSemErros(clienteAgendaHorario, newRequestAdd) {
        try {
            const { preSolicitacoesSemErros } = this._filtrarPreSolicitacoes(clienteAgendaHorario, newRequestAdd);
            this.preSolicitacoesDto.semErros = preSolicitacoesSemErros;
        } catch (err) {
            const message = err?.response?.data?.message || err.message;
            this.$toast.add({ severity: 'error', summary: message, life: 6000 });
        }
    }

    _filtrarPreSolicitacoes(clienteAgendaHorario, newRequestAdd) {
        let hasError = false;
        const preSolicitacoesSemErros = this.preSolicitacoesDto.selecionadas.filter((agendamentoPresolicitado) => {
            hasError = false;

            if (!agendamentoPresolicitado.telefoneFuncionario) {
                this._toastError('Telefone está vazio.', agendamentoPresolicitado);
                hasError = true;
            } else if (agendamentoPresolicitado?.telefoneFuncionario?.length <= 10) {
                this._toastError('Formato do telefone está errado.', agendamentoPresolicitado);
                hasError = true;
            }
            if (clienteAgendaHorario && !newRequestAdd?.horarioMarcadoPrestador) {
                this._toastError('pois não foi informado o horário definido com o prestador', agendamentoPresolicitado);
                hasError = true;
            }
            if (agendamentoPresolicitado?.tipoExame?.id == EnumTipoExame.DEMISSIONAL && !agendamentoPresolicitado?.dataHomologacao) {
                this._toastError('Data de demissão é obrigatória para o tipo de exame demissional.', agendamentoPresolicitado);
                hasError = true;
                return !hasError;
            }
            if (agendamentoPresolicitado?.pendencia == 'SIM' && !agendamentoPresolicitado?.cliente.desabilitarQuestionarioRisco) {
                this._toastError('Há pendências de campos obrigatórios não preenchidos.', agendamentoPresolicitado);
                hasError = true;
            }

            const dataAtual = dayjs();
            const dataAtualSemHoras = dayjs(dataAtual).hour(0).minute(0).second(0).millisecond(0);
            const dataSugestaoFinal = dayjs(agendamentoPresolicitado?.dataSugestaoFinal);
            if (dataAtualSemHoras > dataSugestaoFinal) {
                this._toastError('A data de sugestão é mais antiga que a data atual.', agendamentoPresolicitado);
                hasError = true;
            }
            if (
                !agendamentoPresolicitado?.horarioMarcadoPrestador &&
                dataSugestaoFinal.isSame(dataAtualSemHoras) &&
                agendamentoPresolicitado.periodo == 1 &&
                dataAtual.$d.getHours() > 12
            ) {
                this._toastError('O periodo é mais antigo que o periodo atual.', agendamentoPresolicitado);
                hasError = true;
            }

            if (agendamentoPresolicitado?.horarioMarcadoPrestador) {
                const [horaMarcada, minutoMarcado] = agendamentoPresolicitado.horarioMarcadoPrestador.split(':');
                const horaAtualComMinutos = new Date().setHours(parseInt(dataAtual.$d.getHours()), parseInt(dataAtual.$d.getMinutes()), 1, 0);
                const horarioMarcadoPrestador = new Date().setHours(parseInt(horaMarcada), parseInt(minutoMarcado), 1, 0);

                if (
                    agendamentoPresolicitado?.horarioMarcadoPrestador &&
                    dataSugestaoFinal.isSame(dataAtualSemHoras) &&
                    horaAtualComMinutos >= horarioMarcadoPrestador
                ) {
                    this._toastError('O horário marcado é mais antigo que o horário atual', agendamentoPresolicitado);
                    hasError = true;
                }
            }
            return !hasError;
        });
        return { hasError, preSolicitacoesSemErros };
    }

    _toastError(detail, agendamentoPresolicitado) {
        const summary = `Erro ao salvar solicitação do funcionário ${agendamentoPresolicitado.nomeFuncionario}!`;
        this.$toast.add({
            severity: 'error',
            summary,
            detail,
            life: 6000
        });
    }

    async _presolicitaAgendamento(agendamentoPreSolicitadoId) {
        try {
            await new AreaMedicaService('/pre-solicitacoes/solicitar').save([agendamentoPreSolicitadoId]);
            const funcionario = this.preSolicitacoesDto.semErros.find(
                (solicitacao) => solicitacao.id === agendamentoPreSolicitadoId
            )?.nomeFuncionario;
            const funcionarioSolicitado = funcionario ? `de ${funcionario}` : '';
            const mensagemSucesso = `Solicitação  ${funcionarioSolicitado} realizada com sucesso!`;
            this.$toast.add({ severity: 'success', summary: mensagemSucesso, life: 3000 });
        } catch (err) {
            const mensagemErro = err?.response?.data?.message || err.message;
            this.$toast.add({ severity: 'error', summary: `Erro ao processar a solicitação: ${mensagemErro}`, life: 6000 });
        }
    }
}
