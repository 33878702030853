export function GestaoMedicaChamadaProfissional() {
    return [
        {
        id: 'step0',
        text:'<h3>Bem-vindo a tela de profissionais!</h3></br> A partir desse guia iremos mostrar as principais funções!',
        attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step1',
        text: 'Pesquisa.',
        attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    
    {
        id: 'step2',
        text: `Botao1`,
        attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step3',
        text: 'Botao2',
        attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    },
    {
        id: 'step4',
        text: 'Lista',
        attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
        classes: 'example-step-extra-class',
        buttons: [
            { text: 'X', action: this.tour.hide },
            { text: 'Próximo', action: this.tour.next }
        ]
    }
]
}