import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useBreadcrumb() {
    const route = useRoute();
    const router = useRouter();

    const breadcrumbs = computed(() => {
        const matchedRoutes = route.matched;
        const breadcrumbs = matchedRoutes
            .filter((route) => route.name !== 'home' && !route.meta?.defaultRoute)
            .map((route) => ({
                label: route?.meta?.breadcrumbLabel ?? route.name,
                to: route.path,
                currentRoute: route.path === router.currentRoute.value.path
            }));
        return breadcrumbs;
    });
    const breadcrumbItems = ref([]);

    watchEffect(() => {
        breadcrumbItems.value = breadcrumbs.value;
    })

    return {
        breadcrumbItems
    };
}
