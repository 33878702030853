<template>
    <div v-if="totalItems == notificacaoLength"
        class="flex flex-row align-content-center justify-content-center">
        <p style="color: #ced4da">Por enquanto é só! :)</p>
    </div>
    <div v-else class="flex flex-row align-content-center justify-content-center">
        <p style="color: #ced4da">Carregando...</p>
    </div>
</template>
<script>
export default {
    props: {
        totalItems: {
            type: Number
        },
        notificacaoLength: {
            type: Number
        },
    },
}
</script>
