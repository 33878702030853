import { computed, reactive, ref } from 'vue';
import EnumPeriodo from '../../../../../../enums/EnumPeriodo';
import moment from 'moment';
import { obterDiaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';
import QuemAgendaAvisa from '@/enums/QuemAgendaAvisa';

export default function usePeriodo(form, openingHours, fluxoReservaDeHorario) {
    const periodoManhaAtivo = ref(true);
    const periodosNaoAtende = reactive({
        manha: false,
        tarde: false,
        ambos: false
    });

    function setPeriodoManhaVisible(visible) {
        periodoManhaAtivo.value = visible;
        if (!periodoManhaAtivo.value && form.value.periodo === 1) {
            form.value.periodo = null;
        }
    }

    const prestadorUsaSoc = computed(() => {
        return form.value.prestador?.systemsAndTechnology?.name === 'SOC';
    });

    const periodos = computed(() => {
        const periodo = [
            { label: 'Manhã', value: EnumPeriodo.MANHA, visible: periodoManhaAtivo.value && !periodosNaoAtende.manha },
            { label: 'Tarde', value: EnumPeriodo.TARDE, visible: !periodosNaoAtende.tarde },
            {
                label: 'Ambos',
                value: EnumPeriodo.AMBOS,
                visible: periodoManhaAtivo.value && !prestadorUsaSoc.value && !periodosNaoAtende.manha && !periodosNaoAtende.tarde
            }
        ];
        const visiveis = periodo.filter((p) => p.visible === true);

        return visiveis;
    });

    function validarDisponibilidadePeriodo() {
        if (!form.value.prestador || !form.value?.intervaloSugerido?.[0] || !form.value?.intervaloSugerido?.[1] || !openingHours.value) {
            return;
        }

        const [inicio, fim] = form.value.intervaloSugerido;
        const { atendimentosMatutinos, atendimentosVespertinos, dataInicial } = getAtendimentosIntervalo(inicio, fim);

        periodosNaoAtende.manha = atendimentosMatutinos.every((valor) => !valor);
        periodosNaoAtende.tarde = atendimentosVespertinos.every((valor) => !valor);

        validaDisponibilidadePeriodoHoje(dataInicial);
    }

    function getAtendimentosIntervalo(inicio, fim) {
        let dataInicial = moment(inicio);
        const dataFinal = moment(fim);
        const totalDias = dataFinal.diff(dataInicial, 'days') + 1;
        const dias = Array.from({ length: totalDias }, (_, index) => moment(dataInicial).add(index, 'days'));

        const atendimentosMatutinos = dias.map((dia) => {
            const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
            const horarioDeAtendimento = openingHours.value.find((p) => p.weekDay === diaDaSemana);
            return horarioDeAtendimento?.atendimentoMatutino;
        });

        const atendimentosVespertinos = dias.map((dia) => {
            const diaDaSemana = obterDiaDaSemanaPorIndice(dia.day());
            const horarioDeAtendimento = openingHours.value.find((p) => p.weekDay === diaDaSemana);
            return horarioDeAtendimento?.atendimentoVespertino;
        });
        return { atendimentosMatutinos, atendimentosVespertinos, dataInicial };
    }

    function validaDisponibilidadePeriodoHoje(dataInicialMoment) {
        const isDataInicialHoje = dataInicialMoment && moment().isSame(moment(dataInicialMoment), 'days');

        if (isDataInicialHoje) {
            periodosNaoAtende.manha = true;
            periodosNaoAtende.ambos = true;
            if (fluxoReservaDeHorario && form.value.quemAgenda === QuemAgendaAvisa.EMPRESA) {
                periodosNaoAtende.manha = false;
                periodosNaoAtende.ambos = false;
            }
        }
    }

    return {
        setPeriodoManhaVisible,
        prestadorUsaSoc,
        periodos,
        validarDisponibilidadePeriodo
    };
}
