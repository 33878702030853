<template lang="">
    <Dialog
        :closable="false"
        v-if="$checkPermission('gestamedica_painel_prestador_liberar_prestador:criar')"
        :style="{ width: '450px' }"
        header="Liberar prestador"
        :modal="true"
        :visible="$checkPermission('gestamedica_painel_prestador_liberar_prestador:criar')"
    >
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>
                Prestador não atende esse funcionário, para liberar o atendimento clique em liberar, caso tenha finalizado a
                liberação clique em voltar.
            </span>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="$emit('onClose')" />
            <Button label="Liberar" icon="pi pi-check" class="p-button-outlined p-button-success" @click="$emit('onLiberar')" />
        </template>
    </Dialog>

    <Dialog
        :closable="false"
        v-if="!$checkPermission('gestamedica_painel_prestador_liberar_prestador:criar')"
        :style="{ width: '450px' }"
        header="Liberar prestador"
        :modal="true"
        :visible="!$checkPermission('gestamedica_painel_prestador_liberar_prestador:criar')"
    >
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>
                Prestador não atende esse funcionário, para liberar o atendimento entre em contato com o gestor de contrato.
            </span>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
export default {
    name: 'ConfirmLiberarPrestadorDialog',
    emits: ['onClose', 'onLiberar']
};
</script>
