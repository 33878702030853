<template>
    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px">
        <FileUpload mode="basic" @uploader="uploadAnexo" auto customUpload :maxFileSize="10485760" :showCancelButton="false"
            :showUploadButton="false" invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
            label="Importar" chooseLabel="Importar" class="mr-2 inline-block" :style="{ width: '100%' }">
        </FileUpload>
        <DataTable dataKey="id" :value="form?.anexos?.filter(p => p.tipo === props.tipoAnexo)" :row-hover="true"
            class="p-datatable-sm mt-3" responsiveLayout="stack" breakpoint="640px">
            <template #empty> Nenhum registro encontrado. </template>
            <Column field="nome" header="Nome">
                <template #body="{ data }">
                    {{ data.nome }}
                </template>
            </Column>
            <Column field="tipo" header="Tipo"></Column>
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="{ data }">
                    <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="removerAnexo(data)" />
                </template>
            </Column>
        </DataTable>
    </OverlayPanel>
</template>
<script setup>
import {
    computed,
    defineEmits,
    defineProps,
    defineExpose,
    ref
} from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['onUpdate']);
const toast = useToast();
const op = ref(null);
const props = defineProps({
    funcionario: Object,
    tipoAnexo: String
});
const form = computed({
    get() {
        return props.funcionario;
    },
    set(value) {
        emit('onUpdate', value);
    }
});

function uploadAnexo(event) {
    form.value.anexos = form.value?.anexos ? form.value?.anexos : [];
    const arquivoExistente = form.value?.anexos?.filter((anexo) => anexo.nome === event.files[0].name && anexo.tipo === props.tipoAnexo);

    if (arquivoExistente.length) {
        toast.add({ severity: 'info', summary: 'Você já carregou este arquivo!', life: 3000 });
        return;
    }
    form.value.anexos.push({
        nome: event.files[0].name,
        arquivo: event.files[0],
        tipo: props.tipoAnexo,
    });
    emit('onUpdate', form)
}
function removerAnexo(data) {
    const index = form.value?.anexos.findIndex((anexo) => anexo.nome === data.nome && anexo.tipo === data.tipo);
    if (index !== -1) {
        form.value?.anexos.splice(index, 1);
    }
    emit('onUpdate', form.value.anexos)
}
defineExpose({
    toggle(event) {
        op.value?.toggle(event);
    }
});
</script>