<template>
    <div class="w-full">
        <div class="grid">
            <div class="flex flex-column gap-2 col-12 md:col-6">
                <label for="tipoExame"> Tipo de exame<span class="p-error">*</span></label>
                <Dropdown
                    id="tipoExame"
                    v-model="v$.form.tipoExame.$model"
                    :class="{ 'p-invalid': v$.form.tipoExame.$errors.length && v$.form.tipoExame.lazy.$dirty }"
                    :options="tiposExames"
                    :showClear="true"
                    optionLabel="descricao"
                    placeholder="Selecione o tipo de exame..."
                />
                <small v-if="v$.form.tipoExame.$errors.length && v$.form.tipoExame.lazy.$dirty" class="p-error">
                    {{ v$.form.tipoExame.$errors[0].$message }}
                </small>
            </div>

            <div class="flex flex-column gap-2 col-12 md:col-6">
                <label for="cidade"> Cidade<span class="p-error">*</span></label>
                <DropdownCidade
                    id="cidade"
                    v-model="v$.form.cidade.$model"
                    :class="{ 'p-invalid': v$.form.cidade.$errors.length && v$.form.cidade.lazy.$dirty }"
                    :filterInputProps="{ 'data-test-id': 'cidade-filter-input' }"
                />
                <small v-if="v$.form.cidade.$errors.length && v$.form.cidade.lazy.$dirty" class="p-error w-full">
                    {{ v$.form.cidade.$errors[0].$message }}
                </small>
            </div>
        </div>

        <div class="mt-3 flex flex-column gap-2">
            <label for="funcionarios"> Funcionário(s)<span class="p-error">*</span></label>
            <div class="w-full flex gap-2">
                <MultiSelectFuncionario
                    class="w-full"
                    v-model="v$.selectedFuncionarios.$model"
                    :loading="isAddingFuncionario"
                    :situacao="situacoesFuncionarios"
                    :customerId="form.cliente?.id"
                    :exame="form.tipoExame?.id ? form.tipoExame.id : null"
                    :class="{
                        'p-invalid':
                            (v$.form.funcionarios.$errors.length && v$.form.funcionarios.lazy.$dirty) ||
                            (v$.selectedFuncionarios.$errors.length && v$.selectedFuncionarios.lazy.$dirty && !v$.form.funcionarios.$errors.length)
                    }"
                    :limite-funcionarios="10"
                    :quantidade-funcionarios-em-uso="v$.form?.funcionarios?.$model?.length"
                    :change="setCustomerId"
                />
                <Button
                    :disabled="!selectedFuncionarios?.length"
                    :loading="isAddingFuncionario"
                    class="p-button-outlined w-10rem DadosDoAgendamentostep7"
                    label="Adicionar"
                    @click="adicionarFuncionario()"
                />
            </div>
            <small v-if="v$.form.funcionarios.$errors.length && v$.form.funcionarios.lazy.$dirty" class="p-error">
                {{ v$.form.funcionarios.$errors[0].$message }}
            </small>
            <small
                v-if="v$.selectedFuncionarios.$errors.length && v$.selectedFuncionarios.lazy.$dirty && !v$.form.funcionarios.$errors.length"
                class="p-error"
            >
                Existem funcionários selecionados que não foram adicionados
            </small>
        </div>

        <div v-if="form.funcionarios?.length" class="mt-2">
            <DataTable :value="v$.form.funcionarios.$model" responsiveLayout="scroll" breakpoint="640px" table-style="min-width: 600px">
                <Column field="name" header="Nome" :style="tamanhoDaColuna" />
                <Column v-if="isDemissional" header="Data de demissão" :style="tamanhoDaColuna">
                    <template #body="{ index }">
                        <Calendar
                            autofocus
                            class="w-full"
                            v-model="form.funcionarios[index].dataHomologacao"
                            selectionMode="single"
                            :min-date="dataMinimaParaOCampoDeDemissao"
                            :showIcon="true"
                            :class="{
                                'p-invalid':
                                    v$.funcionariosItens.$each.$response.$errors[index].dataHomologacao?.length && v$.funcionariosItens.$dirty
                            }"
                        />
                        <small
                            v-if="v$.funcionariosItens.$each.$response.$errors[index].dataHomologacao?.length && v$.funcionariosItens.$dirty"
                            class="p-error"
                        >
                            {{ v$.funcionariosItens.$each.$response.$errors[index].dataHomologacao?.[0].$message }}
                        </small>
                    </template>
                </Column>
                <Column header="E-mail" :style="tamanhoDaColuna">
                    <template #body="{ index }">
                        <InputText
                            class="w-full"
                            v-model="form.funcionarios[index].email"
                            autofocus
                            :class="{ 'p-invalid': v$.funcionariosItens.$each.$response.$errors[index].email?.length }"
                        />
                        <small v-if="v$.funcionariosItens.$each.$response.$errors[index].email.length" class="p-error">
                            {{ v$.funcionariosItens.$each.$response.$errors[index].email[0].$message }}
                        </small>
                    </template>
                </Column>
                <Column header="Telefone" :style="tamanhoDaColuna">
                    <template #body="{ index }">
                        <div>
                            <InputMask
                                class="w-full"
                                v-model="v$.form.funcionarios.$model[index].phone"
                                autofocus
                                mask="(99) 99999-999?9"
                                :class="{
                                    'p-invalid': v$.funcionariosItens.$each.$response.$errors[index].phone.length && v$.funcionariosItens.$dirty
                                }"
                            />
                        </div>
                        <small v-if="v$.funcionariosItens.$each.$response.$errors[index].phone.length && v$.funcionariosItens.$dirty" class="p-error">
                            {{ v$.funcionariosItens.$each.$response.$errors[index].phone[0].$message }}
                        </small>
                    </template>
                </Column>
                <Column>
                    <template #body="{ index }">
                        <InputSwitch v-model="form.funcionarios[index].pcd" binary @input="removerAnexosPCD(index)" />
                    </template>
                    <template #header>
                        <span> Laudo PCD </span>
                        <i
                            class="pi pi-info-circle text-blue-500 ml-2"
                            v-tooltip.bottom="{
                                value: 'Este campo deve ser selecionado apenas para as situações em que queira solicitar uma avaliação para caracterização de pessoa com deficiência, com emissão de laudo caracterizador. De acordo com os dispositivos da Convenção sobre os Direitos das Pessoas com Deficiência.',
                                pt: {
                                    text: 'font-medium w-25rem'
                                }
                            }"
                        />
                    </template>
                </Column>
                <Column bodyClass="text-right">
                    <template #body="{ index }">
                        <Button class="p-button-text p-button" icon="pi pi-trash" @click="funcionarioRemove(index)" />
                    </template>
                </Column>
            </DataTable>
        </div>
        <DialogMudancaRiscos ref="dialogMudancaRiscos" @onConfirmacaoCobranca="onConfirmacaoCobranca" @onCancelar="removerFuncionario" />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, inject, onMounted, ref, watch } from 'vue';
import { helpers, required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import DropdownCidade from '@/pages/cidades/components/DropdownCidade.vue';
import AreaMedicaService from '@/services/AreaMedicaService';
import MultiSelectFuncionario from '@/pages/gestao_medica/agendamentos/form/components/MultiSelectFuncionario.vue';
import EnumTipoExame from '@/enums/TipoExame';
import DialogMudancaRiscos from './components/DialogMudancaRiscos.vue';
import validacaoPersonalizadaTelefone from './utils/validacaoPersonalizadaTelefone';
import inserirMascaraTelefone from './utils/inserirMascaraTelefone';
import { clone } from 'ramda';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});
const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const funcionariosItens = computed(() => form.value.funcionarios || []);
const tamanhoDaColuna = computed(() => (form.value?.tipoExame?.id === EnumTipoExame.DEMISSIONAL ? 'width: 23%' : 'width: 29%'));
const isDemissional = computed(() => form?.value?.tipoExame?.id === EnumTipoExame.DEMISSIONAL);

const rules = {
    form: {
        cidade: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
        tipoExame: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
        funcionarios: {
            required: helpers.withMessage('Deve ser adicionado no minímo um funcionário', required),
            lazy: true
        }
    },
    funcionariosItens: {
        $each: helpers.forEach({
            email: {
                required: helpers.withMessage('O campo precisa ser informado corretamente', email)
            },
            dataHomologacao: {
                requiredIfDemissional: helpers.withMessage('Campo obrigatório', requiredIfDemissional)
            },
            phone: {
                required: helpers.withMessage('Campo obrigatório', required),
                validacaoPersonalizadaTelefone: helpers.withMessage('Telefone inválido', validacaoPersonalizadaTelefone)
            }
        })
    },
    selectedFuncionarios: { required: helpers.withMessage('Campo obrigatório', (value) => !value.length), lazy: true }
};

const selectedFuncionarios = ref([]);
const situacoesFuncionarios = 'ATIVO,AFASTADO,PENDENTE';
const tiposExames = ref([]);
const isAddingFuncionario = ref(false);
const serviceTiposExames = new AreaMedicaService('/tipo_exames');
const v$ = useVuelidate(rules, { form, funcionariosItens, selectedFuncionarios });
const dialogMudancaRiscos = ref(null);
const funcionariosParaAdicionar = ref([]);

watch(
    () => form.value.tipoExame,
    () => {
        form.value?.funcionarios?.forEach((funcionario) => {
            delete funcionario.exames;
            delete funcionario.procedimentos;
            delete funcionario.anexos;
            delete funcionario.dataLiberacao;
            delete funcionario.tipoRetorno;
            delete funcionario.asoEmDia;
            funcionario.dataHomologacao = null;
        });

        if (form.value.tipoExame?.id === EnumTipoExame.MUDANCA_FUNCAO) {
            form.value.funcionarios = [];
        }

        form.value.examesEmDia = false;
    }
);
watch(selectedFuncionarios, (value, oldValue) => {
    const clonedValue = clone(value);
    const clonedOldValue = clone(oldValue);

    if (clonedValue.length > clonedOldValue.length) {
        funcionariosParaAdicionar.value.push(clonedValue.at(-1));
        verificarMudancaFuncao(funcionariosParaAdicionar.value.at(-1));
    } else {
        const itensRemovido = clonedOldValue.filter((p) => !clonedValue.map((q) => q.id).includes(p.id));
        funcionariosParaAdicionar.value = funcionariosParaAdicionar.value.filter((p) => !itensRemovido.map((q) => q.id).includes(p.id));
    }
});

const dataMinimaParaOCampoDeDemissao = computed(() => {
    let dataDeHoje = new Date();
    let dataDoAnoAnterior = dataDeHoje.getFullYear() - 1;

    return new Date(dataDoAnoAnterior, 0, 1);
});

function setCustomerId(option) {
    if (!form.value.funcionarios?.length) {
        form.value.cliente = null;
    }

    if (option.value[0] && !form.value.cliente) {
        form.value.cliente = option.value[0].customer;
    }
}

function verificarMudancaFuncao(itemNovo) {
    if (!form.value.tipoExame || form.value.tipoExame?.id !== EnumTipoExame.MUDANCA_FUNCAO) {
        return;
    }

    if (!itemNovo?.mudouRisco) {
        dialogMudancaRiscos?.value.openDialog(itemNovo);
    }
}

async function adicionarFuncionario() {
    isAddingFuncionario.value = true;

    for (const funcionario of funcionariosParaAdicionar.value) {
        if (form.value.funcionarios?.some((element) => element.id === funcionario.id)) {
            continue;
        }

        funcionario.pcd = false;
        funcionario.phone = inserirMascaraTelefone(funcionario.phone);
        funcionario.dataHomologacao = null;
        form.value.funcionarios?.push({ ...funcionario });
    }

    selectedFuncionarios.value = [];
    isAddingFuncionario.value = false;
}

const setHouveramFuncionariosComRisco = inject('setHouveramFuncionariosComRisco');
function funcionarioRemove(index) {
    form.value.funcionarios.splice(index, 1);

    if (form.value.funcionarios.length === 0) {
        form.value.cliente = null;
    }
    setHouveramFuncionariosComRisco();
}

function onConfirmacaoCobranca(idFuncionarioSemMudancaDeRisco) {
    funcionariosParaAdicionar.value = funcionariosParaAdicionar.value.map((p) => {
        if (p.id === idFuncionarioSemMudancaDeRisco) {
            p.asoEmDia = true;
        }
        return p;
    });
}

function removerFuncionario(idFuncionarioSemMudancaDeRisco) {
    selectedFuncionarios.value = selectedFuncionarios.value.filter((p) => p.id !== idFuncionarioSemMudancaDeRisco);
}
function removerAnexosPCD(indexFuncionario) {
    form.value.funcionarios[indexFuncionario].anexos =
        form.value.funcionarios[indexFuncionario].anexos?.filter((anexo) => anexo.tipo !== 'LAUDO_PCD') ?? [];
}

function requiredIfDemissional(value) {
    return !isDemissional.value || (isDemissional.value && !!value);
}

onMounted(async () => {
    const { data } = await serviceTiposExames.findAll({});
    tiposExames.value = data;
    form.value.funcionarios = [];
});

defineExpose({
    validarFormulario() {
        return v$.value.$validate();
    }
});
</script>
