export function BaseImportacao() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de importações!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por uma <b>importação</b> buscando pelo número do código vinculado a importação e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }, 
         { 
            id: 'step3',
            text: `Para cadastrar uma nova importação, utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        } ,
        {
            id: 'step4',
            text: `<h5>Acompanhe todos os usuários a partir da listagem.</h5>
            <p> Neste item, você encontrará uma tabela com as importações realizadas, contendo todas as informações relevantes a respeito do processo de sincronização dos dados com o SOC. <p>`,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar uma importação cadastrada, nesta ação é possível:</p>
            <p><b>Visualizar:</b> Janela onde podem ser visualizados todos os dados da importação criada de uma forma mais completa.<p>
            <p><b>Cancelar:</b> Consiste em excluir um processo de importação.<p>
            <p><b>Voltar Etapa:</b> Tem a função de retornar a etapa da importação realizada.<p>`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ]
}
