import { reactive } from 'vue';
import { getCompany, getCustomers, getFilterCustomer, setCompany, setCustomers, setFilterCustomer, setTenant } from '../common/storage';

const state = reactive({
    companyId: null,
    company: null,
    customers: [],
    customersIds: [],
    filterCustomer: [],
    filterCustomerIds: [],
});

export function setCurrentCompany(company) {
    state.customers = [];
    state.companyId = company.id;
    state.company = company;
    setCompany(company);
    setTenant(company?.key || company?.apiKey?.key);
}

export function setCurrentCustomers(customers = []) {
    state.customers = customers;
    state.customersIds = customers.map(c => c.id);
    setCustomers(customers);
}

export function setCurrentFilterCustomers(filterCustomer = []) {
    state.filterCustomer = filterCustomer;
    state.filterCustomerIds = filterCustomer.map(c => c.id);
    setFilterCustomer(filterCustomer);
}

export function getCurrentCompany() {
    if (!state.companyId) {
        setCurrentCompany(getCompany())
    }
    return state.companyId;
}

export function getCurrentCompanyObject() {
    if (!state.company) {
        setCurrentCompany(getCompany())
    }
    return state.company;
}

export function getCurrentCustomersObjects() {
    if (!state.customers.length) {
        setCurrentCustomers(getCustomers());
    }
    return state.customers;
}

export function getCurrentFilterCustomerObjects() {
    if (!state.filterCustomer.length) {
        setCurrentFilterCustomers(getFilterCustomer());
    }
    return state.filterCustomer;
}

export function getCurrentCustomers() {
    return state.customersIds;
}

export function getCurrentFilterCustomer() {
    return state.filterCustomerIds;
}
