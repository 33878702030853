import axios from 'axios'
import { logout } from './auth'
import { getToken } from '@/common/storage';

export function getClientAreaMedica(auxHeaders = {}) {
  let headers = getToken() ? { 'Authorization': `Bearer ${getToken()}` } : {}
  headers = {
    ...headers,
    ...auxHeaders,
    'Content-Type': 'multipart/form-data'
  }

  const client = axios.create({
    baseURL: process.env.VUE_APP_API_MEDICO_URL,
    headers
  })

  client.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error && error.response && error.response.status === 401) {
      logout()
    }
    return Promise.reject(error)
  })

  return client
}
