<template lang="">
    <div class="grid">
        <div class="col-12">
            <AppBackButton v-if="hasBackButton" :path="backPath" class="mb-3"/>
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        location: {
            type: Array
        },
        subtitle: {
            type: String
        }, 
        backPath: {
            type: String
        },
        hasBackButton: {
            type: Boolean,
            default: false
        }
    }
}
</script>
