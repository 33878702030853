export class PrestadorDto {
    id;
    activeContract;
    name;
    tradeName;
    cnpj;
    code;
    type;
    zipCode;
    street;
    number;
    addressReference;
    addressComplement;
    advanceDeposit;
    daysInAdvance;
    neighborhood;
    email;
    phone;
    commercialPhone;
    createdAt;
    updatedAt;
    deletedAt;
    nomeTitularConta;
    cpfTitularConta;
    cnpjTitularConta;
    nomeBanco;
    codigoBanco;
    codigoAgencia;
    contaCorrente;
    idCity;
    atendeTodasCidades;
    metodoPagamento;
    chavePix;
    codigoEmpresaPrincipal;
    chaveAcesso;
    username;
    codigoResponsavel;
    codigoUsuarioSoc;
    codigoExportaDados;
    chaveAcessoExportaDados;
    atendeAsoRetido;
    gerarAsoComData;
    permiteConfirmacaoPrestador;
    prioridade;
    constructor(obj) {
        Object.assign(this, obj);
    }
}
