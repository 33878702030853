<template>
    <AppNotificacaoCard ref="cardNotificacao" v-if="showNotificacao" @onClose="openNotificacao"
        @qntNotificacoes="newQuantidadeNotificacoes" />
    <Button @click="openNotificacao" class="p-button-rounded p-button-text" style="width: 45px; height: 45px">
        <i v-if="qntNotificacoes > 0" class="pi pi-bell p-text-secondary" style="color: white; font-size: 1.5rem"
            v-badge="`${qntNotificacoes}`"></i>
        <i v-else class="pi pi-bell" style="color: white; font-size: 1.5rem"></i>
    </Button>
</template>
<script>
import AppNotificacaoCard from './AppNotificacaoCard.vue';
import * as storage from '../../common/storage';
import { getClientMicroserviceNotificacao } from '@/services/http';

export default {
    components: { AppNotificacaoCard },
    data() {
        return {
            showNotificacao: false,
            qntNotificacoes: null
        };
    },
    async mounted() {
        this.profile = storage.getProfile();
        await this.getQuantidadeNãoLida();
    },
    watch: {
        async '$route.name'() {
            await this.getQuantidadeNãoLida();
        }
    },
    methods: {
        openNotificacao() {
            this.showNotificacao = !this.showNotificacao;
        },
        async getQuantidadeNãoLida() {
            const path = `/notificacoes/nao-lidas`;
            const { data } = await getClientMicroserviceNotificacao().get(path);
            this.qntNotificacoes = data;
        },
        newQuantidadeNotificacoes(value) {
            this.qntNotificacoes = value;
        }
    }
};
</script>
