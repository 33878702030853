import { AgendamentoDto } from '../../../../../utils/dtos/AgendamentoDto';

export class ConfigFuncionarioPrestadorDto {
    id;

    constructor(obj) {
        Object.assign(this, obj);
    }
}
export class ConfigFuncionarioExameDto {
    data;
    hora;
    prestador = new ConfigFuncionarioPrestadorDto({});
    antecedencia;
    procedimentos = [];

    constructor(obj) {
        Object.assign(this, obj);
    }
}
export class ConfigFuncionarioReservaDto {
    idFuncionario;
    nomeFuncionario;
    prestador = new ConfigFuncionarioPrestadorDto({});
    clienteAsoRetido;
    tempoPCDRetornoAoTrabalhoConsulta;
    examesAtendidos = [new ConfigFuncionarioExameDto({})];
    examesNaoAtendidos = [new ConfigFuncionarioExameDto({})];

    constructor(obj) {
        Object.assign(this, obj);
    }
}
export class AgendamentoReservaHorarioDto extends AgendamentoDto {
    configFuncionarios = [new ConfigFuncionarioReservaDto()];
}
