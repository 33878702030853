<template>
    <OverlayPanel ref="overlayPanel" showCloseIcon :style="{ width: '30vw' }">
        <Message v-if="possuiuExamesDifererentesDoPeriodico(procedimentos)">
            Existem procedimentos a serem realizados que não fazem parte do exame periódico, mas serão incluídos neste momento porque não foram
            solicitados anteriormente
        </Message>
        <DataTable :value="procedimentos">
            <Column field="name" header="Procedimento">
                <template #body="slotProps">
                    <div class="flex align-items-center">
                        {{ slotProps.data.name }}
                     
                    </div>
                </template>
            </Column>
            <Column v-if="possuiuExamesDifererentesDoPeriodico(procedimentos)" header="Não periódico" headerStyle="width:10rem" bodyClass="flex justify-content-center width:10rem">
                <template #body="slotProps">
                    <div  v-if="!!slotProps.data.procedimentoNaoPeriodico">
                        <i class="pi pi-exclamation-circle" style="font-size: 1rem; margin-left: 5px; margin-bottom: 5px"></i>
                    </div>
                </template>
            </Column>
        </DataTable>
    </OverlayPanel>
</template>

<script setup>
import { ref, defineExpose } from 'vue';

const overlayPanel = ref(null);
const procedimentos = ref([]);
const toggle = (event) => overlayPanel?.value.toggle(event);

function possuiuExamesDifererentesDoPeriodico(procedimentos) {
    return !!procedimentos?.find((procedimento) => procedimento.procedimentoNaoPeriodico === true);
}

function openOverlay(event, funcionario) {
    procedimentos.value = funcionario.procedimentos;
    toggle(event);
}

defineExpose({
    openOverlay
});
</script>

<style></style>
