<template>
    <div class="flex flex-row breadcrumb-container">
        <router-link :to="{ path: '/' }"><i class="pi pi-home icon-home"></i></router-link>
        <template v-for="(crumb, index) in breadcrumbItems">
            <div v-if="index < breadcrumbItems.length - 1" class="flex align-items-center items-container" :key="index">
                <i class="pi pi-chevron-right separator"></i>
                <router-link :to="crumb.to">
                    <span :class="{ 'current-route': crumb.currentRoute }">{{ crumb.label }}</span>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script setup>
import { useBreadcrumb } from './composables/useBreadcrumb.js';

const { breadcrumbItems } = useBreadcrumb();
</script>
<style lang="scss" scoped>
.breadcrumb-container {
    * {
        color: #495057;
        font-weight: 500;
    }

    .current-route {
        font-weight: 100;
    }

    i {
        border: none;
        padding: 10px;
        font-size: 1.3rem;
    }
}
</style>
