<template>
    <Dialog :visible="props.modelValue" :closable="false" :modal="true" header="Atualizar dados" style="width: 800px">
        <div class="grid">
            <div class="col-6">
                <label for="cidade"> Cidade </label>
                <DropdownCidade id="cidade" v-model="cidade" class="w-full mt-1" />
            </div>
            <div class="col-6">
                <label :class="{ 'text-500': !cidade }" for="prestador"> Prestador </label>
                <DropdownPrestadorCliente
                    id="prestador"
                    v-model="prestador"
                    :disabled="!cidade?.id"
                    :filtrosExtras="{ agruparPrestadores: true }"
                    :idCidade="cidade?.id"
                    :idCliente="agendamento?.idCliente"
                    class="w-full mt-1"
                />
            </div>
            <div v-if="!reservaDeHorario" class="col-6">
                <label :class="{ 'text-500': !cidade }" for="dataAgendamento"> Data </label>
                <Calendar
                    id="dataAgendamento"
                    v-model="dataAgendamento"
                    :disabled="!cidade?.id"
                    :disabledDays="[0, 6]"
                    :loading="loading"
                    :maxDate="dataMaxima"
                    :minDate="dataMinima"
                    class="w-full mt-1"
                    dateFormat="dd/mm/yy"
                />
            </div>
            <div v-if="!reservaDeHorario" class="col-6">
                <label :class="{ 'text-500': !dataAgendamento }" for="periodo"> Período </label>
                <Dropdown
                    id="periodo"
                    v-model="periodo"
                    :disabled="!dataAgendamento"
                    :options="periodos"
                    class="w-full mt-1"
                    optionLabel="label"
                    optionValue="value"
                />
            </div>
            <div v-if="reservaDeHorario" class="col-12">
                <label for="horarioAgendaSOC"> Horário </label>
                <Dropdown
                    id="horarioAgendaSOC"
                    v-model="procedimentosFromSoc"
                    :loading="loading"
                    :options="faixasDeHorario"
                    :optionLabel="formatarHorariosAgenda"
                    class="w-full mt-1"
                    filter
                    placeholder="Selecione o horário"
                />
            </div>
        </div>
        <template #footer>
            <Button :loading="loading" class="p-button-text" icon="pi pi-times" label="Cancelar" @click="emit('update:modelValue', false)" />
            <Button
                :disabled="!camposPreenchidos"
                :loading="loading"
                class="p-button-outlined p-button-success"
                icon="pi pi-check"
                label="Agendar"
                @click="reservaDeHorario ? confirmarAgendamento() : validarSePrestadorAtendeDataInformada()"
            />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, watch } from 'vue';
import { obterDiaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';
import { showSuccess } from '@/utils/Toast';
import { getClientAreaMedica } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import dayjs from 'dayjs';
import moment from 'moment';
import BaseService from '@/services/BaseService';
import EnumPeriodo from '@/enums/EnumPeriodo';
import DropdownCidade from '../../cidades/components/DropdownCidade.vue';
import DropdownPrestadorCliente from '../../prestadores/components/DropdownPrestadorCliente.vue';

const emit = defineEmits(['loadAppointments', 'update:modelValue']);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    agendamento: {
        type: Object,
        required: true
    }
});

const $servicePrestadores = new BaseService('/suppliers');

const toast = useToast();
const loading = ref(false);
const faixasDeHorario = ref([]);
const cidade = ref(null);
const periodo = ref(null);
const prestador = ref(null);
const dataAgendamento = ref(null);
const procedimentosFromSoc = ref(null);
const periodos = [
    { label: 'Manhã', value: EnumPeriodo.MANHA },
    { label: 'Tarde', value: EnumPeriodo.TARDE },
    { label: 'Ambos', value: EnumPeriodo.AMBOS }
];

const camposPreenchidos = computed(() => {
    return cidade.value && prestador.value && (dataAgendamento.value || procedimentosFromSoc.value);
});
const dataMaxima = computed(() => {
    return dayjs(props.agendamento.dataSugestaoFinal).toDate();
});
const dataMinima = computed(() => {
    const hoje = dayjs();
    const dataSugestao = dayjs(props.agendamento.dataSugestaoInicial);
    const passouDaDataInicial = hoje.format('YYYY-MM-DD') > dataSugestao.format('YYYY-MM-DD');

    return passouDaDataInicial ? hoje.toDate() : dataSugestao.toDate();
});
const reservaDeHorario = computed(() => {
    const prestadorUsaSoc = prestador.value?.systemsAndTechnology?.name === 'SOC';
    const usaReservaHorario = props.agendamento?.cliente?.usaReservaHorario === true;

    return prestadorUsaSoc && usaReservaHorario;
});

watch(cidade, () => {
    prestador.value = null;
});
watch(prestador, () => {
    if (reservaDeHorario.value) buscarFaixasDisponiveis();
});

async function buscarFaixasDisponiveis() {
    loading.value = true;
    try {
        const { data } = await getClientAreaMedica().post(`agendamentos/faixas-disponiveis`, {
            agendamentoId: props.agendamento.id,
            funcionarioId: props.agendamento.idFuncionario,
            examesIds: props.agendamento.agendamentoProcedimento.map((el) => el.idProcedimento),
            periodo: props.agendamento.periodo,
            prestadorId: prestador.value?.id,
            dataInicial: dataMinima.value,
            dataFinal: dataMaxima.value
        });
        faixasDeHorario.value = data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message,
            life: 3000
        });
    }
    loading.value = false;
}
async function confirmarAgendamento() {
    loading.value = true;
    try {
        await getClientAreaMedica().patch(`autoagendamento/solicitante-confirmar-prestador`, {
            id: props.agendamento.id,
            cidade: cidade.value,
            idPrestador: prestador.value.id
        });
        await getClientAreaMedica().post(`autoagendamento/solicitante-confirmar-agendamento`, {
            agendamentoId: props.agendamento.id,
            periodo: props.agendamento.periodo,
            dataAgendamento: dataAgendamento.value || undefined,
            procedimentosFromSoc: procedimentosFromSoc.value || undefined
        });

        showSuccess(toast, 'Agendamento confirmado');
        emit('loadAppointments');
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message,
            life: 3000
        });
    }
    loading.value = false;
}
async function validarSePrestadorAtendeDataInformada() {
    const dayjsData = dayjs(dataAgendamento.value);

    const { data } = await $servicePrestadores.findById(prestador.value.id);
    const diaDaSemana = obterDiaDaSemanaPorIndice(dayjsData.day());
    const horarioDeAtedimento = data.openingHours.find((p) => p.weekDay === diaDaSemana);

    if (!horarioDeAtedimento) {
        toast.add({
            severity: 'warn',
            summary: 'O prestador não atende neste dia da semana',
            detail: 'Por favor, selecione outro dia e tente novamente',
            life: 3000
        });
        dataAgendamento.value = null;
        return false;
    }

    const atenteManha = periodo.value === EnumPeriodo.MANHA && horarioDeAtedimento.atendimentoMatutino;
    const atendeTarde = periodo.value === EnumPeriodo.TARDE && horarioDeAtedimento.atendimentoVespertino;
    const atendeAmbos = periodo.value === EnumPeriodo.AMBOS && horarioDeAtedimento.atendimentoMatutino && horarioDeAtedimento.atendimentoVespertino;
    if (!atenteManha && !atendeTarde && !atendeAmbos) {
        toast.add({
            severity: 'warn',
            summary: 'O prestador não atende no período selecionado',
            detail: 'Por favor, selecione outro período e tente novamente',
            life: 3000
        });
        periodo.value = null;
        return false;
    }

    confirmarAgendamento();
}
const formatarHorariosAgenda = (horario) => {
    const diaDoMes = moment.tz(horario.horarioChegada, 'UTC').format('DD/MM');
    const horarioChegada = moment.tz(horario.horarioChegada, 'UTC').format('HH:mm');
    const horarioSaida = moment.tz(horario.horarioSaida, 'UTC').format('HH:mm');
    const duracao = horario.minutosAgendas;

    return `${diaDoMes} | Entrada ${horarioChegada} - Saída ${horarioSaida} (${duracao}min)`;
};
</script>
