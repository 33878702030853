import StatusAgendamento from '../../../enums/StatusAgendamento';

export function useAgendamentoStatusValidator() {
    const enumStatusAgendamento = StatusAgendamento;

    function isStatusEmAberto(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.EM_ABERTO;
    }

    function isStatusEmAndamento(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.EM_ANDAMENTO;
    }

    function isStatusAguardandoConfColaborador(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR;
    }

    function isStatusPendenciaCliente(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE;
    }

    function isStatusAguardandoData(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGUARDANDO_DATA;
    }

    function isStatusDiaDaConsulta(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.DIA_DA_CONSULTA;
    }

    function isStatusDesistenciaDeVaga(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.DESISTENCIA_DE_VAGA;
    }

    function isStatusConcluido(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.CONCLUIDO;
    }

    function isStatusCancelado(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.CANCELADO;
    }

    function isStatusAgendado(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGENDADO;
    }

    function isStatusConfirmacaoAgenda(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA;
    }

    function isStatusAguardandoAplicacaoRisco(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGUARDANDO_APLICACAO_RISCO;
    }

    function isStatusProcessandoAgendamento(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.PROCESSANDO_AGENDAMENTO;
    }

    function isStatusPendenciaResolvida(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.PENDENCIA_RESOLVIDA;
    }

    function isStatusKitPendente(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.KIT_PENDENTE;
    }

    function isStatusPendente(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.PENDENCIA_PRESTADOR;
    }

    function isStatusNaoConfirmado(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.NAO_CONFIRMADO;
    }

    function isStatusDepositoAntecipado(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.DEPOSITO_ANTECIPADO;
    }

    function isAgendamentoDepositoAntecipado(agendamento) {
        return agendamento.depositoAntecipado;
    }

    function isStatusAguardandoConfComparecimento(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.AGUARDANDO_CONF_COMPARECIMENTO;
    }

    function isStatusFaltaConsulta(agendamento) {
        return agendamento.status.id === enumStatusAgendamento.FALTA_CONSULTA;
    }

    return {
        enumStatusAgendamento,
        isStatusEmAberto,
        isStatusEmAndamento,
        isStatusAguardandoConfColaborador,
        isStatusPendenciaCliente,
        isStatusAguardandoData,
        isStatusDiaDaConsulta,
        isStatusDesistenciaDeVaga,
        isStatusConcluido,
        isStatusCancelado,
        isStatusAgendado,
        isStatusConfirmacaoAgenda,
        isStatusAguardandoAplicacaoRisco,
        isStatusProcessandoAgendamento,
        isStatusPendenciaResolvida,
        isStatusKitPendente,
        isStatusPendente,
        isStatusNaoConfirmado,
        isStatusDepositoAntecipado,
        isAgendamentoDepositoAntecipado,
        isStatusAguardandoConfComparecimento,
        isStatusFaltaConsulta
    };
}
