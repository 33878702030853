export default (telefone) => {
    const numeroLimpo = telefone.replace(/\D/g, '');

    const padraoTelefone = numeroLimpo.length === 11 ? /^(\d{2})(\d{5})(\d{4})$/ : /^(\d{2})(\d{4})(\d{4})$/;

    return numeroLimpo.replace(padraoTelefone, (match, p1, p2, p3) => {
        if (numeroLimpo.length === 11) {
            return `(${p1}) ${p2}-${p3}`;
        } else {
            return `${p1} ${p2}-${p3}`;
        }
    });
};
