<template>
    <Dialog class="p-fluid" :style="{ width: '450px' }" :header="title" :modal="true" :closable="false">
        <div class="field">
            <Textarea type="text" v-model="descricao" :class="{ 'p-invalid': submitted && !descricao }" />
            <small class="p-error" v-if="submitted && !descricao">Campo obrigatório.</small>
        </div>
        <template #footer>
            <Button label=" " icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
            <Button :label="labelConfirm" :loading="loadingConfirm" icon="pi pi-check" class="p-button-outlined p-button-success"
                @click="onClickConfirmar" />
        </template>
    </Dialog>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        defaultDescription: {
            type: String,
        },
        labelConfirm: {
            type: String,
            default: 'Confirmar'
        },
        descricaoObrigatoria: {
            type: Boolean,
            default: true
        },
        loadingConfirm: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            descricao: null,
            submitted: false
        };
    },
    mounted() {
        this.descricao = this.defaultDescription;
    },
    methods: {
        onClickConfirmar() {
            this.submitted = true;
            if (this.descricao == ''){
                this.descricao = null;
            }
            if (this.descricaoObrigatoria && !this.descricao) return;
            this.$emit('onConfirm', this.descricao);
        }
    }
}
</script>
