import store from '@/store';
import { getPermissions } from '@/common/storage';

export function checkPermission(permission) {
    let permissions = null;


    if (store.state.auth.permissions.length) {
        permissions = store.state.auth?.permissions;
    } else {
        const loadPermissionsFromLocalStorage = getPermissions();
        if (loadPermissionsFromLocalStorage?.length) {
            store.dispatch('addToPermissions', loadPermissionsFromLocalStorage);
        }
    }

    const hasPermission = permissions?.includes(permission);


    return hasPermission;
}
