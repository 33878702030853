<template>
    <AppMultiselect
        v-model="value"
        :optionDisabled="checkDisabled"
        :renderOption="
            (slotProps) => {
                return slotProps.option?.codigoSoc
                    ? slotProps.option?.name +
                          ' | SOC: ' +
                          slotProps.option?.codigoSoc +
                          ' | Admissão: ' +
                          $filters.formatDateOnly(slotProps.option?.admissionDate)
                    : slotProps.option?.name;
            }
        "
        :service="$serviceFuncionario"
        :showToggleAll="false"
        :filtrosExtras="{ exame }"
        optionLabel="name"
        autoFilterFocus
        @change="handleChange"
    />
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    name: 'MultiSelectFuncionario',
    props: {
        modelValue: {
            type: Object
        },
        situacao: {
            type: String,
            required: true
        },
        change: {
            type: Function,
            default: () => {}
        },
        customerId: {
            type: Number
        },
        exame: {
            type: Number
        },
        limiteFuncionarios: {
            type: Number
        },
        quantidadeFuncionariosEmUso: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $serviceFuncionario: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        exame() {
            this.$serviceFuncionario = new BaseService(`/customer/employees/funcionarios-por-situacao/${this.situacao}`);
        }
    },
    created() {
        this.$serviceFuncionario = new BaseService(`/customer/employees/funcionarios-por-situacao/${this.situacao}`);
    },
    methods: {
        checkDisabled(option) {
            return !!(this.customerId && option.customerId !== this.customerId);
        },
        handleChange(option) {
            if (this.limiteFuncionarios) {
                if (option.value.length > this.limiteFuncionarios || this.quantidadeFuncionariosEmUso >= this.limiteFuncionarios) {
                    this.value = this.value.slice(0, this.limiteFuncionarios);
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Limite de funcionários atingido!',
                        detail: `O limite máximo de funcionários em um agendamento é de ${this.limiteFuncionarios}.`,
                        life: 3000
                    });
                } else {
                    this.change(option);
                }
            } else {
                this.change(option);
            }
        }
    }
};
</script>
