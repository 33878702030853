<template>
    <div v-for="n in 5" id="skeleton" :key="n" class="notificacao-bloco">
        <div class="flex flex-row">
            <div class="notificacao-avatar">
                <Skeleton shape="circle" v-tooltip.top="n" size="3rem" class="mr-2"></Skeleton>
            </div>
            <div class="w-30rem">
                <div class="flex col flex-col p-0 m-0">
                    <div class="user-notificacao overflow-hidden">
                        <Skeleton height="1rem" width="8rem" class="mb-1"></Skeleton>
                    </div>
                    <small class="data-nofiticacao ml-auto">
                        <Skeleton height="1rem" width="7rem" class="mb-1"></Skeleton>
                    </small>
                    <small class="data-nofiticacao">
                        <Skeleton height="1rem" width="1rem"></Skeleton>
                    </small>
                </div>
                <div class="coment">
                    <Skeleton width="w-full" height="3rem"></Skeleton>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.notificacao-bloco {
    background-color: #ffffff;
    border-radius: 10px;
    border-left: solid #f4f4f4;
    border-width: 5px;
    padding: 5px;
    margin-right: 3px;
    margin-bottom: 7px;
    cursor: pointer;
    transition-property: background-color, border-color, color;
    transition-duration: 500ms;
}
.notificacao-avatar {
    top: 10px;
}

.data-nofiticacao {
    margin-left: 5px;
    font-size: 9px;
    display: inline-block;
    transition: 250ms linear all;
}

.user-notificacao {
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    width: 105px;
    overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.coment {
    font-size: 11px;
}
</style>