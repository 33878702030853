export function GestaoMedicaPainelAgendamento() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de painel de agendamentos!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Para pesquisar um agendamento, digite o nome do funcionário e tecle <b>ENTER.</b>`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step2',
            text: `Utilize a opção (filtros) para obter <b>status específicos</b> do agendamento.`,
            attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step3',
            text: `Utilize a opção (limpar filtros) para remover os filtros selecionados.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step4',
            text: `Seleciona todos os agendamentos que se referem a <b>depósitos
            antecipados</b> e, em seguida, gera um extrato detalhado contendo a lista de prestadores juntamente com os respectivos
            valores a serem depositados por cada um. `,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `A qualquer momento é possível <b>extrair relatórios</b> da conforme a seleção do filtro.`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step6',
            text: `Esta função inicia o processo de agendamento através da ferramenta. Qualquer agendamento com o status <b>“Em aberto”</b>. pode ser iniciado.`,
            attachTo: { element: document.querySelector('.step6'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step7',
            text: `<h5>Acompanhe todos os agendamentos realizados a partir da listagem.</h5> <p>Descrição dos filtros de busca de agendamentos:<p>
            <p><b>Meus:</b>Quantidade de agendamentos atribuídos ao usuário responsável que está atualmente logado na plataforma Duon. </p>
            <p><b>Pendências:</b> Quantidade de pendências de agendamentos do painel, através dos status Kit Pendente e Pendência Prestador. </p>
            <p><b>Em Aberto:</b> Quantidade de agendamentos com o status em aberto. </p>`,
            attachTo: { element: document.querySelector('.step7'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ];
}
