import { ref } from 'vue';
import { useShepherd } from 'vue-shepherd';

export function useAgendamentosTour() {
    const tour = ref(null);

    function iniciarTourTela() {
        tour.value = useShepherd({
            useModalOverlay: true,
            scrollTo: true
        });
        const steps = [
            {
                id: 'step1',
                text: '<h3>Bem-vindo a tela de agendamentos!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
                attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step2',
                text: 'Para pesquisar um agendamento, digite o nome do funcionário e tecle <b>ENTER.</b>',
                attachTo: { element: document.querySelector('.step2'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step3',
                text: 'Utilize a opção (filtros) para obter <b>status específicos</B> do agendamento.',
                attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step4',
                text: 'A qualquer momento é possível <b>extrair relatórios</b> da conforme a seleção do filtro.',
                attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step6',
                text: 'Para criar uma solicitação utilize o botão<b>Novo agendamento</b>.',
                attachTo: { element: document.querySelector('.step6'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step7',
                text: 'Utilize o botão <b>Pré-solicitação</b>, para visualizar e gerir solicitações <b>não liberadas para agendamentos.</b>',
                attachTo: { element: document.querySelector('.step7'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step8',
                text: '<b>Clique para obter mais informações sobre o agendamento.</b>',
                attachTo: {
                    element: document.querySelector(
                        '#app > div > div.layout-main > div.layout-content > div > div > div > div > div.p-datatable.p-component.p-datatable-hoverable-rows.p-datatable-responsive-stack.p-datatable-sm.step10 > div.p-datatable-wrapper > table > tbody > tr:nth-child(1) > td:nth-child(1) > button'
                    ),
                    on: 'left'
                },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step9',
                text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o agendamento, nesta ação é possível:</p>
                    <p><b>Alterar:</b> Solicitação de alteração do agendamento.<p>
                    <p><b>Reenviar Kit:</b> Reenviar o kit de atendimento a clínica e solicitante.<p>
                    <p><b>Desistência de Vaga:</b> Cancelar do agendamento, devido a desistência da vaga.<p>
                    <p><b>Cancelar:</b> Cancelar agendamento.<p>
                    <p><b>Anexos:</b> Visualizar anexos do agendamento.<p>
                    <p><b>Falta Consulta:</b> Informar falta consulta do funcionário.<p>
                    <p><b>Timeline:</b> Visualizar histórico do agendamento.<p>
                    `,
                attachTo: { element: document.querySelector('.step9'), on: 'left' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step10',
                text: `<h5>Acompanhe todos os agendamentos a partir da listagem.</h5> <p>Solicitações em andamento:<p>
                    <p><b>Aguardando Aplicação de Risco:</b> Aguardando os responsáveis técnicos para atribuição dos riscos à estrutura hierárquica do funcionário.</p>
                    <p><b>Em Aberto:</b> Aguardando para realizar o agendamento.</p>
                    <p><b>Em Andamento:</b> Em processo de agendamento.</p>
                    <p><b>Aguardando Conf. Funcionário:</b> Aguardando confirmação do funcionário para presença na consulta.</p>
                    <p><b>Pendência Cliente:</b> Aguardando retorno do cliente.</p>
                    <p><b>Dia da Consulta:</b> Agendamentos cuja consulta é no dia atual.</p>
                    <p><b>Aguardando Conf. Comparecimento:</b> Aguardando funcionário confirmar que compareceu na consulta.</p>
                    <p><b>Agendado:</b> Agendamento realizado.</p>
                    `,
                attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step11',
                text: `<h5>Solicitações concluídas: </h5>
                    <p><b>Cancelado:</b> Agendamento Cancelado</p>
                    <p><b>Falta consulta:</b> Funcionário faltou à consulta, permitindo uma nova solicitação.</p>
                    <p><b>Concluído:</b> Agendamento concluído e funcionário compareceu aos exames.</p>
                    <p><b>Desistência de Vaga:</b> Processo de desistência de vaga.</p>
                    `,
                attachTo: { element: document.querySelector('.step10'), on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' },
                    { text: 'Próximo', action: tour.value.next }
                ]
            },
            {
                id: 'step12',
                text: `<h4>Parabéns! Você concluiu o guia!👋<h4>`,
                attachTo: { on: 'bottom' },
                classes: 'example-step-extra-class',
                buttons: [
                    { text: 'X', action: tour.value.hide },
                    { text: 'Voltar', action: tour.value.back, classes: '' }
                ]
            }
        ];
        tour.value.addSteps(steps);
        tour.value.start();
    }

    return { iniciarTourTela };
}
