<template>
    <Dialog :style="{ width: '450px' }" header="Confirmar" :modal="true" :closable="false">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="title">Tem certeza que deseja excluir <b>{{ title }}</b>?</span>
            <span v-else-if="customTitle">{{ customTitle }}</span>
        </div>
        <template #footer>
            <Button 
                :label="labelClose" 
                icon="pi pi-times" 
                class="p-button-text" 
                @click="$emit('onClose')" />
            <Button 
                :label="labelConfirm" 
                :loading="loadingRemove"
                icon="pi pi-trash" 
                class="p-button-outlined p-button-danger"
                @click="onClickConfirm" />
        </template>
    </Dialog>
</template>

<script>
export default {
    name: 'AppDeleteDialog',
    props: {
        title: {
            type: String
        },
        customTitle: {
            type: String,
            default: 'Tem certeza que deseja excluir este registro?'
        },
        labelClose: {
            type: String,
            default: 'Cancelar'
        },
        labelConfirm: {
            type: String,
            default: 'Excluir'
        },
        record: {
            type: Object
        },
        loadingRemove: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async onClickConfirm() {
            await this.$emit('onConfirm', this.record)
        }
    }
}
</script>
