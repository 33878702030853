<template>
    <AnexosFuncionario
        v-for="(funcionario, index) in form.funcionarios"
        :key="index"
        :funcionario="funcionario"
        :submitted="submitted"
        :submittedAnexo="submittedAnexo"
        @onUpdate="onUpdate"
    />
</template>
<script setup>
import AnexosFuncionario from './AnexosFuncionario.vue';
import { computed, defineEmits, defineProps, defineExpose, ref, onActivated } from 'vue';
import TipoExame from '../../../../../enums/TipoExame';
import verificarSeTemProcedimentoLaudoPcd from '@/pages/gestao-medica-v3/agendamentos/criar-agendamento/steps/utils/verificarSeTemProcedimentoLaudoPcd';

const submitted = ref(false);
const submittedAnexo = ref(false);
const emit = defineEmits(['update:formData']);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});
const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

onActivated(() => {
    submitted.value = false;
    submittedAnexo.value = false;
});

function onUpdate(data) {
    form.value.funcionarios.forEach((funcionario) => {
        if (data.value.id !== funcionario.id) return;
        return data;
    });
}

function checaCamposFaltando(condicao) {
    return form.value.funcionarios.some(condicao);
}

function atualizaValoresCampo(camposFaltando) {
    submitted.value = camposFaltando;
    submittedAnexo.value = camposFaltando;
}

function validarCampos() {
    let faltaCampos = false;
    if (form.value.tipoExame?.id === TipoExame.RETORNO_AO_TRABALHO) {
        faltaCampos = checaCamposFaltando(
            (funcionario) => !funcionario.dataLiberacao || !funcionario.tipoRetorno || !funcionario?.anexos?.filter((p) => p.tipo === 'LAUDO')?.length
        );
        atualizaValoresCampo(faltaCampos);
    } else if ([TipoExame.CONSULTA, TipoExame.TERCEIROS].includes(form.value.tipoExame?.id)) {
        faltaCampos = checaCamposFaltando(
            (funcionario) =>
                funcionario.procedimentos.some(verificarSeTemProcedimentoLaudoPcd) && !funcionario?.anexos?.filter((p) => p.tipo === 'LAUDO')?.length
        );
        atualizaValoresCampo(faltaCampos);
    }
    return !faltaCampos;
}

defineExpose({
    validarFormulario() {
        return validarCampos();
    }
});
</script>
