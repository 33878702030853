<template>
    <Dialog v-model:visible="open" :closable="false" modal class="p-fluid" header="Atenção!" :style="{ width: '40vw' }">
        <div class="field flex-column justify-align-items">
            <div class="confirmation-content field">
                <i class="pi pi-info-circle mr-2" style="font-size: 2rem" />
                <span style="font-size: 14px">
                    O colaborador não sofreu alterações de riscos e os exames serão cobrados. Deseja prosseguir com o
                    agendamento?
                </span>
            </div>
            <div class="field-checkbox ml-2">
                <Checkbox v-model="cienteCobrancaAso" :binary="true"
                    :class="{ 'p-invalid': submittedDialogMudancaRiscos && !cienteCobrancaAso }" />
                <label :class="{ 'p-error': submittedDialogMudancaRiscos && !cienteCobrancaAso }">
                    Estou ciente de que os exames serão cobrados.</label>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="cancelar" />
            <Button label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-success"
                @click="enviarConfirmacao" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { ref, defineExpose, defineEmits } from 'vue';

const emit = defineEmits(['onConfirmacaoCobranca', 'onCancelar']);
const toast = useToast();
const open = ref(false);
const funcionarioSemMudancaDeRisco = ref();
const submittedDialogMudancaRiscos = ref(false);
const cienteCobrancaAso = ref(false);

function openDialog(itemNovo) {
    funcionarioSemMudancaDeRisco.value = itemNovo;
    cienteCobrancaAso.value = false;
    open.value = true;
}

function enviarConfirmacao() {
    try {
        submittedDialogMudancaRiscos.value = true;

        if (!cienteCobrancaAso.value) {
            toast.add({ severity: 'error', summary: 'É necessário marcar o campo para realizar a confirmação do exame.', life: 3000 });
            return;
        }

        submittedDialogMudancaRiscos.value = false;
        toast.add({ severity: 'success', summary: 'Funcionário adicionado com sucesso!', life: 3000 });
        emit('onConfirmacaoCobranca', funcionarioSemMudancaDeRisco.value.id);
        open.value = false;
    } catch (error) {
        submittedDialogMudancaRiscos.value = false;
        toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
    }
}

function cancelar() {
    emit('onCancelar', funcionarioSemMudancaDeRisco.value.id);
    open.value = false;
}

defineExpose({
    openDialog
});
</script>
