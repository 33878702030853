export const queryParamsTelaClientesGro = {
    state: {
        queryParamsTelaClientesGro: {},
        queryParamsTelaUnidadesGro: {}
    },
    getters: {
        queryParamsTelaClientesGro: function (state) {
            return state.queryParamsTelaClientesGro;
        },
        queryParamsTelaUnidadesGro: function (state) {
            return state.queryParamsTelaUnidadesGro;
        }
    },
    mutations: {
        UPDATE_QUERY_PARAMS_TELA_CLIENTES_GRO(state, payload) {
            state.queryParamsTelaClientesGro = payload;
        },
        UPDATE_QUERY_PARAMS_TELA_UNIDADES_GRO(state, payload) {
            state.queryParamsTelaUnidadesGro = payload;
        }
    },
    actions: {
        setQueryParamsTelaClientesGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_CLIENTES_GRO', payload);
        },
        setQueryParamsTelaUnidadesGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_UNIDADES_GRO', payload);
        }
    }
};
