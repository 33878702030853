<template>
    <div class="p-panel p-component">
        <div class="p-panel-header">
            <span class="p-panel-title">Detalhes</span>
        </div>
        <div class="p-toggleable-content">
            <div class="p-panel-content">
                <div class="grid">
                    <div class="col-4">
                        <div class="field">
                            <b>Solicitante: </b>
                            {{ data.nomeSolicitante }}
                        </div>
                        <div class="field">
                            <b>Telefone do solicitante: </b>
                            {{ $filters.phone(data.telefoneSolicitante) }}
                        </div>

                        <div class="field" :title="data.emailSolicitante.replace(/[;,]/g, '\n')">
                            <b>Email do solicitante: </b>
                            {{ limitarCaracteresEmail(data.emailSolicitante, 40) }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="field">
                            <b>Data de sugestão: </b> entre {{ $filters.formatDateOnly(data.dataSugestaoInicial) }} e
                            {{ $filters.formatDateOnly(data.dataSugestaoFinal) }} no período da
                            {{ getDescription(data.periodo) }}
                        </div>
                        <div class="field">
                            <b>Exames do funcionario: </b>
                            {{ getNomeProcedimento(data.procedimentos) }}
                        </div>
                        <div class="field">
                            <b>Riscos do funcionario: </b>
                            {{ getRiskname(data.riscos) }}
                        </div>
                        <template v-if="data.tipoExame.id == EnumTipoExame.DEMISSIONAL">
                            <div class="field">
                                <b> Data de demissão: </b>
                                {{ $filters.formatDateOnly(data.dataHomologacao) }}
                            </div>
                        </template>
                        <template v-if="data.tipoExame.id == EnumTipoExame.RETORNO_AO_TRABALHO">
                            <div class="field">
                                <b>Data Liberação: </b>
                                {{ $filters.formatDateOnly(data.dataLiberacao) }}
                            </div>
                            <div class="field">
                                <b>Tipo de retorno: </b>
                                {{ getDescriptionReturn(data.tipoRetorno) }}
                            </div>
                        </template>
                        <template v-if="data.tipoExame.id == EnumTipoExame.CONSULTA">
                            <div class="field">
                                <b>Detalhes da consulta</b>
                                {{ data.detalheConsulta }}
                            </div>
                        </template>
                    </div>
                    <div class="col-4">
                        <div class="field">
                            <b>Cliente/CNPJ: </b>
                            {{ data.cliente?.apelido ? data.cliente.apelido : 'Não informado' }} /
                            {{ data.cliente?.inscricao ? data.cliente.inscricao : 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Unidade do funcionario/CNPJ: </b>
                            {{ data.funcionario?.customerHierarchy?.customerBranch?.name ?? 'Não informado' }} /
                            {{ data.funcionario?.customerHierarchy?.customerBranch?.tradeCode ?? 'Não informado' }}
                        </div>
                        <div class="field">
                            <b>Setor do funcionario: </b>
                            {{ data.funcionario.customerHierarchy.customerDepartment.name }}
                        </div>
                        <div class="field">
                            <b>Cargo do funcionario: </b>
                            {{ data.funcionario.customerHierarchy.customerPosition.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps } from 'vue';
import { EnumTipoExame } from '../../../../../enums/TipoExame';

defineProps({
    data: Object
});
function limitarCaracteresEmail(texto, maxCaracteres) {
    if (texto?.length > maxCaracteres) {
        return texto.substring(0, maxCaracteres) + '...';
    }
    return texto;
}

function getDescription(periodo) {
    const Periodo = {
        1: 'Manhã',
        2: 'Tarde'
    };
    return Periodo[periodo];
}

function getNomeProcedimento(procedimentosList) {
    const procedimentos = procedimentosList.map((r) => r.procedimento);
    let nomeProcedimento = '';
    for (let i = 0; i < procedimentos.length; i++) {
        if (nomeProcedimento) {
            nomeProcedimento = nomeProcedimento + ', ' + procedimentos[i]?.name;
        } else {
            nomeProcedimento = procedimentos[i]?.name;
        }
    }
    return nomeProcedimento;
}

function getRiskname(riscos) {
    let nomeRiscos = '';
    for (let i = 0; i < riscos.length; i++) {
        if (nomeRiscos) {
            nomeRiscos = nomeRiscos + ', ' + riscos[i]?.nome;
        } else {
            nomeRiscos = riscos[i]?.nome;
        }
    }
    return nomeRiscos;
}

function getDescriptionReturn(tipoRetorno) {
    const TipoRetorno = {
        1: 'B31 - Auxilio - Doença Previdenciário',
        2: 'B91 - Auxilio - Doença por acidente do trabalho',
        3: 'B94 - Auxilio - Acidente por acidente do trabalho',
        4: 'Licença Maternidade',
        5: 'Prestação do Serviço Militar'
    };
    return TipoRetorno[tipoRetorno];
}
</script>
