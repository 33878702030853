<template>
    <Dialog v-model:visible="open" :style="{ width: '450px' }" class="p-fluid" :modal="true" :closable="false">
        <div class="field">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span style="font-weight: bold">{{ statusAgendamento.descricao }}</span>
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="closeDialog" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineExpose, defineProps } from 'vue';
import AreaMedicaService from '@/services/AreaMedicaService';

const props = defineProps({
    agendamento: {
        type: Object,
        required: true
    }
});

const open = ref(false);
const statusAgendamento = ref({});
const serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status/agendamento');

async function openDialog() {
    open.value = true;
    const { data } = await serviceAgendamentoStatus.findById(props.agendamento.id);
    statusAgendamento.value = data;
}

function closeDialog() {
    open.value = false;
}

defineExpose({
    openDialog
});
</script>
