<template>
    <div>
        <TabelaReservaExamesAtendidos
            :configFuncionario="configFuncionario"
            :configFuncionariosOriginal="configFuncionariosOriginal"
            :configFuncionarioIndex="configFuncionarioIndex"
            :agendamento="formData"
            :minDate="minDate"
            :maxDate="maxDate"
        />
        <TabelaReservaExamesNaoAtendidos
            :configFuncionario="configFuncionario"
            :configFuncionariosOriginal="configFuncionariosOriginal"
            :agendamento="formData"
        />
        <Toast />
    </div>
</template>

<script setup>
import TabelaReservaExamesNaoAtendidos from '../../../dialog-reserva-horario/components/TabelaReserva/components/TabelaReservaExamesNaoAtendidos.vue';
import { computed, defineEmits, defineProps, nextTick, ref, defineExpose } from 'vue';
import moment from 'moment-timezone';
import { useToast } from 'primevue/usetoast';
import { getClientBase } from '@/services/http';
import TabelaReservaExamesAtendidos from '../../../dialog-reserva-horario/components/TabelaReserva/components/TabelaReservaExamesAtendidos.vue';
import { tabelaReservaExpose } from './TabelaReserva';

const toast = useToast();
const emit = defineEmits(['update:formData']);
const props = defineProps({
    formData: {
        type: Object,
        required: true
    },
    configFuncionario: {
        type: Object,
        required: true
    },
    configFuncionarioIndex: {
        type: Number,
        required: true
    },
    configFuncionariosOriginal: {
        type: Array
    }
});

const agendamento = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const configFuncionarios = computed({
    get() {
        return props.formData.configFuncionarios || [];
    },
    set(value) {
        agendamento.value.configFuncionarios = value;
    }
});

const minDate = computed(() => {
    return new Date(agendamento.value?.dataSugestaoInicial);
});

const maxDate = computed(() => {
    return new Date(agendamento.value?.dataSugestaoFinal);
});

const configInvalida = ref(false);

async function getProcedimentosFromSoc(idFuncionario) {
    let procedimentosFromSoc = [];
    if (configInvalida.value) {
        toast.add({
            severity: 'warn',
            summary: 'Erro',
            detail: 'Preencha todos os campos corretamente',
            life: 3000
        });
        await nextTick(() => {
            configInvalida.value = false;
        });
        finalizarLoadingFuncionario(idFuncionario);
        return;
    }
    const horariosValidos = await validarHorariosDisponiveisNaAgenda(idFuncionario);
    if (!horariosValidos) {
        toast.add({
            severity: 'info',
            summary: 'Um dos períodos selecionados não está mais disponível. Por favor, selecione outro período.',
            life: 8000
        });
        await nextTick(() => {
            configInvalida.value = false;
        });
        finalizarLoadingFuncionario(idFuncionario);
        return;
    }
    const funcionario = agendamento.value.funcionarios.find((p) => p.id === idFuncionario);
    const configFuncionario = agendamento.value.configFuncionarios.find((p) => p.idFuncionario === funcionario.id);

    if (configFuncionario) {
        const arrayExamesAtendidos = configFuncionario.examesAtendidos?.map((ambiente) => {
            return {
                prestadorId: ambiente.prestador.id,
                examesIds: ambiente.procedimentos?.map((proc) => proc.id),
                diasAntecedencia: ambiente.antecedencia,
                prestadorInformado: true,
                horarioSemPrestador: null,
                itens: ambiente.hora?.itens,
                exameParticular: ambiente?.exameParticular
            };
        });

        const arrayExamesNaoAtendidos = configFuncionario.examesNaoAtendidos?.length
            ? configFuncionario.examesNaoAtendidos?.map((exame) => {
                  const [hora, minuto] = exame.hora.split(':');
                  const horarioSemPrestador = moment(exame.data).add(hora, 'hours').add(minuto, 'minutes');
                  return {
                      prestadorId: exame.prestador?.id,
                      examesIds: [exame.id],
                      diasAntecedencia: exame.antecedencia,
                      prestadorInformado: false,
                      horarioSemPrestador,
                      itens: exame.hora?.itens,
                      exameParticular: exame?.exameParticular
                  };
              })
            : [];

        procedimentosFromSoc = [...arrayExamesAtendidos, ...arrayExamesNaoAtendidos];
    }

    return procedimentosFromSoc;
}

function iniciarLoadingFuncionario(idFuncionario) {
    configFuncionarios.value = configFuncionarios.value.map((funcionario) => {
        if (funcionario.idFuncionario === idFuncionario) {
            funcionario.loading = true;
            funcionario.submitted = true;
        }
        return funcionario;
    });
}

function finalizarLoadingFuncionario(idFuncionario) {
    configFuncionarios.value = configFuncionarios.value.map((funcionario) => {
        if (funcionario.idFuncionario === idFuncionario) {
            delete funcionario.loading;
        }
        return funcionario;
    });
}

function retirarFuncionarioDaTela(success, idFuncionario) {
    if (success) {
        agendamento.value.funcionarios = agendamento.value.funcionarios.filter((funcionario) => funcionario.id !== idFuncionario);
        configFuncionarios.value = configFuncionarios.value.filter((funcionario) => funcionario.idFuncionario !== idFuncionario);
    }
}

function validarConfigFuncionario() {
    if (props.configFuncionario.examesAtendidos.length) {
        const examesAtendidosValidos = props.configFuncionario.examesAtendidos.every((exame) => {
            return exame.data && exame.hora;
        });
        if (!examesAtendidosValidos) {
            configInvalida.value = true;
        }
    }
    if (props.configFuncionario.examesNaoAtendidos.length) {
        const examesNaoAtendidosValidos = props.configFuncionario.examesNaoAtendidos.every((exame) => {
            return (exame.prestador && exame.data && exame.hora) || (exame.exameParticular && exame.data);
        });
        if (!examesNaoAtendidosValidos) {
            configInvalida.value = true;
        }
    }
}

async function validarHorariosDisponiveisNaAgenda(idFuncionario) {
    try {
        const configFuncionario = agendamento.value.configFuncionarios.find((p) => p.idFuncionario === idFuncionario);
        let horarioDisponivel = true;
        for (const exame of configFuncionario.examesAtendidos) {
            const dto = {
                prestadorId: exame.prestador.id,
                funcionarioId: props.configFuncionario?.idFuncionario,
                ambienteId: exame?.ambienteID,
                examesIds: exame?.procedimentos.map((p) => p.id),
                dataInicial: exame?.data,
                dataFinal: exame?.data,
                periodo: props.formData?.periodo,
                isPCD:
                    props.formData.funcionarios.find((funcionario) => funcionario.id === props.configFuncionario?.idFuncionario).pcd ||
                    procedimentoLaudoPCD(),
                tipoExameId: props.formData.tipoExame.id
            };
            const { data } = await getClientBase().post('agendamentos/new-faixas-disponiveis', dto);
            horarioDisponivel = data.some(
                (horario) => horario.horarioChegada === exame.hora.horarioChegada && horario.horarioSaida === exame.hora.horarioSaida
            );

            if (!horarioDisponivel) {
                exame.data = undefined;
                exame.hora = undefined;
                break;
            }
        }
        return horarioDisponivel;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error?.response?.data?.message || error?.message,
            life: 3000
        });
    }
}

function procedimentoLaudoPCD() {
    const procedimentos = props.formData.funcionarios.find((funcionario) => funcionario.id === props.configFuncionario.idFuncionario)?.procedimentos;
    return !!procedimentos?.some((p) => p.pcd);
}
defineExpose(
    tabelaReservaExpose(
        iniciarLoadingFuncionario,
        validarConfigFuncionario,
        getProcedimentosFromSoc,
        retirarFuncionarioDaTela,
        finalizarLoadingFuncionario
    )
);
</script>

<style lang="scss" scoped>
:deep(.p-message-wrapper) {
    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: start;
}
</style>
