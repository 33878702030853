<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <Timeline v-else :value="agendamentoStatus" class="customized-timeline">
            <template #marker="slotProps">
                <span :style="{ backgroundColor: slotProps.item.hexColor }" class="custom-marker shadow-2">
                    <i :class="slotProps.item.iconName"></i>
                </span>
            </template>
            <template #opposite="slotProps">
                <div class="flex flex-column">
                    <small class="p-text-secondary">{{ $filters.formatDate(slotProps.item.data) }}</small>
                    <small class="p-text-secondary">{{ slotProps.item.name }}</small>
                </div>
            </template>
            <template #content="slotProps">
                <div class="flex flex-column">
                    <div class="mb-1">{{ slotProps.item.descricao }}</div>
                    <small class="p-text-secondary mb-3">{{ slotProps.item.observacao }}</small>
                </div>
            </template>
        </Timeline>
    </div>
</template>

<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';
import BaseService from '@/services/BaseService';
import { useGlobalFilters } from '../../../../utils/useGlobalFilters';

const props = defineProps({
    agendamentoId: {
        type: Number,
        required: true
    }
});

const agendamentoStatus = ref([]);
const loading = ref(false);
const $filters = useGlobalFilters();

onBeforeMount(async () => {
    await load();
});

async function load() {
    loading.value = true;
    const service = new BaseService(`/timeline/externa/${props.agendamentoId}`);
    const { data } = await service.findAll({});
    agendamentoStatus.value = data;
    loading.value = false;
}
</script>

<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>
