export function GestaoSesmtQuestionario() {
    return [
        {
            id: 'step0',
            text: '<h3>Bem-vindo a tela de questionários Sesmt!</h3></br> A partir desse guia iremos mostrar as principais funções! ',
            attachTo: { element: document.querySelector('.step0'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step1',
            text: `Aqui você pode pesquisar por um <b>questionário Sesmt</b> digitando o seu nome e clicando na tecla <b>ENTER</b>.`,
            attachTo: { element: document.querySelector('.step1'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }, 
         { 
            id: 'step3',
            text: `Para cadastrar um novo questionário, utilize o botão <b>+Novo</b>.`,
            attachTo: { element: document.querySelector('.step3'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        } ,
        {
            id: 'step4',
            text: `<h5>Acompanhe todos os questionários a partir da listagem.</h5> 
            <p> Neste item, você encontrará a tabela de questionários, contendo todas as informações relevantes a respeito de cada uma.`,
            attachTo: { element: document.querySelector('.step4'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        },
        {
            id: 'step5',
            text: `<p>Utilize o botão <b>(Ações)</b>, para gerenciar o cadastro de um questionário, nesta ação é possível:</p>
            <p><b>Alterar:</b> Alterar os dados do cadastro de um questionário Sesmt.<p>
            <p><b>Excluir:</b> Remover o cadastro de um questionário Sesmt.<p>`,
            attachTo: { element: document.querySelector('.step5'), on: 'bottom' },
            classes: 'example-step-extra-class',
            buttons: [
                { text: 'X', action: this.tour.hide },
                { text: 'Próximo', action: this.tour.next }
            ]
        }
    ]
}
