<template>
    <div>
        <DialogLinkAutoagendamento v-model:token="tokenAutoagendamento" />
        <Dialog
            :visible="props.modelValue"
            :showHeader="false"
            :modal="true"
            :style="{ width: '300px' }"
            @update:visible="$emit('update:modelValue', $event)"
        >
            <div class="flex flex-column align-items-center my-4">
                <div class="bg-primary w-min border-circle p-3">
                    <i class="pi pi-link" style="font-size: 40px; font-weight: bolder"></i>
                </div>
                <h2 class="mt-2">Reenviar Link</h2>
                <div class="w-full text-left mt-3">
                    <label for="tipoExame"> Enviar por: </label>
                    <div class="flex flex-row mt-2">
                        <div class="flex align-items-center">
                            <RadioButton v-model="formaEnvio" inputId="whatsapp" name="envio" :value="formasDeEnvio.WHATSAPP" />
                            <label for="whatsapp" class="ml-2">Whatsapp</label>
                        </div>
                        <div class="flex align-items-center ml-6">
                            <RadioButton v-model="formaEnvio" inputId="email" name="envio" :value="formasDeEnvio.EMAIL" />
                            <label for="email" class="ml-2">Email</label>
                        </div>
                    </div>
                    <div v-if="formaEnvio === formasDeEnvio.WHATSAPP" class="mt-4">
                        <label for="telefone"> Número do Telefone <span class="p-error">*</span> </label>
                        <InputMask
                            v-model="telefone"
                            :autoClear="false"
                            :class="{ 'p-invalid': $v?.telefone.$error }"
                            class="w-full mt-2"
                            type="tel"
                            mask="(99) 99999-9999"
                        />
                        <small v-if="$v?.telefone.$error" class="p-error">
                            {{ $v?.telefone.$errors[0].$message }}
                        </small>
                    </div>
                    <div v-if="formaEnvio === formasDeEnvio.EMAIL" class="mt-4">
                        <label for="telefone"> Endereço de Email <span class="p-error">*</span> </label>
                        <InputText v-model="email" :class="{ 'p-invalid': $v?.email.$error }" class="w-full mt-2" type="mail" />
                        <small v-if="$v?.email.$error" class="p-error">
                            {{ $v?.email.$errors[0].$message }}
                        </small>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="grid">
                    <div class="col-6 py-0">
                        <Button class="w-full" label="Cancelar" @click="$emit('update:modelValue', false)" />
                    </div>
                    <div class="col-6 py-0">
                        <Button class="w-full" label="Reenviar" @click="validarFormulario()" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>
<script setup>
import { useToast } from 'primevue/usetoast';
import { computed, defineProps, defineEmits, ref, watch } from 'vue';
import { helpers, requiredIf, email as validateEmail } from '@vuelidate/validators';
import { getClientBase } from '@/services/http';
import useVuelidate from '@vuelidate/core';
import EnumFormaEnvio from '@/enums/EnumFormaEnvio';
import DialogLinkAutoagendamento from './DialogLinkAutoagendamento.vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    agendamento: {
        type: Object,
        required: true
    }
});

const formasDeEnvio = EnumFormaEnvio;

const email = ref('');
const telefone = ref('');
const formaEnvio = ref(formasDeEnvio.EMAIL);
const tokenAutoagendamento = ref('');
const toast = useToast();
const isLoading = ref(false);

const regrasDeValidacaoDosCampos = computed(() => ({
    telefone: {
        requiredIf: helpers.withMessage('Campo obrigatório', requiredIf(formaEnvio.value === formasDeEnvio.WHATSAPP)),
        regex: helpers.withMessage('Digite um telefone válido', (value) => /^\(\d{2}\) \d{5}-\d{4}$/.test(value))
    },
    email: {
        requiredIf: helpers.withMessage('Campo obrigatório', requiredIf(formaEnvio.value === formasDeEnvio.EMAIL)),
        email: helpers.withMessage('Digite um email válido', validateEmail)
    }
}));
const $v = useVuelidate(regrasDeValidacaoDosCampos, { telefone, email });

watch(
    () => props.modelValue,
    (valor) => {
        email.value = valor ? props.agendamento.funcionario?.email : '';
        telefone.value = valor ? props.agendamento.funcionario?.phone : '';
    }
);

async function reenviarLink() {
    isLoading.value = true;
    try {
        const { data: token } = await getClientBase().post(`/autoagendamento/enviar-link-autoagendamento-colaborador`, {
            phone: telefone.value,
            email: email.value,
            formaEnvio: formaEnvio.value,
            cpf: props.agendamento.funcionario.cpf,
            agendamentoId: props.agendamento.id
        });

        tokenAutoagendamento.value = token;
        emit('update:modelValue', false);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao gerar token!',
            detail: error?.response?.data?.message,
            life: 3000
        });
    }
    isLoading.value = false;
}
async function validarFormulario() {
    const valido = await $v.value.$validate();
    if (valido) reenviarLink();
}
</script>
