import BaseService from '../../../services/BaseService';
import { PrestadorAgendamentoDto } from '../../../pages/gestao-medica-v3/agendamentos/dialog-reserva-horario/utils/PrestadorAgendamentoDto';

export class PrestadorAgendamento {
    prestadorId = -1;
    prestador = new PrestadorAgendamentoDto({});
    $toast = null;

    constructor($toast) {
        this.$toast = $toast;
    }

    async carregarDados(prestadorId) {
        try {
            const { data } = await new BaseService('/suppliers').findById(prestadorId);
            this.prestador = new PrestadorAgendamentoDto(data);
        } catch (error) {
            this.$toast.add({
                severity: 'error',
                summary: `Não foi possível buscar agenda de horários do prestador. ${error?.message}`,
                life: 5000
            });
        }
    }

    getDiasNaoAtendidos(dayNamesShort = [], diaSemanaInicial = null) {
        const disabledDays = [];
        let diaSemanaAtual = diaSemanaInicial;
        dayNamesShort.forEach((value, index) => {
            const horario = this.prestador.openingHours.find((element) => element.weekDay.toLowerCase() === value);
            if (horario && (horario.atendimentoMatutino || horario.atendimentoVespertino)) {
                if (diaSemanaAtual && diaSemanaAtual + 1 === index) {
                    diaSemanaAtual = index;
                }
                return;
            }
            disabledDays.push(index);
        });
        return disabledDays;
    }

    verificarPrestadorNaoPossuiHorario(openingHours, disabledDays) {
        if (openingHours?.length === 0 || disabledDays?.length > 6) {
            this.$toast.add({
                severity: 'info',
                summary: 'Prestador escolhido não contém horários de atendimento disponíveis ou cadastrados.',
                life: 5000
            });
            return true;
        }
        return false;
    }
}
