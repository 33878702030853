<template>
    <DataTable :value="configFuncionario.examesNaoAtendidos">
        <Column v-for="col of examesPrestadorColumns" :field="col.field" :key="col.field" :style="col.style" header-class="hidden">
            <template #body="funcionarioSlotProps">
                <template v-if="col.field === 'prestador'">
                    <DropdownPrestadorCliente
                        :input-style="{ width: '0.1rem' }"
                        class="w-full"
                        id="prestador"
                        v-model="funcionarioSlotProps.data.prestador"
                        :autoLoad="true"
                        :disabled="funcionarioSlotProps.data.exameParticular"
                        :filtrosExtras="{
                            agruparPrestadores: true
                        }"
                        :idExame="funcionarioSlotProps.data.id"
                        :idCidade="agendamento.cidade?.id"
                        :idCliente="agendamento.funcionarios?.map((e) => e.customerId)"
                        :groupKey="funcionarioSlotProps.index"
                        @update:model-value="atualizarValorAntecedencia(funcionarioSlotProps.data, funcionarioSlotProps.index)"
                        :class="{
                            'p-invalid':
                                configFuncionario?.submitted && !funcionarioSlotProps.data.prestador && !funcionarioSlotProps.data.exameParticular
                        }"
                    />
                </template>
                <template v-else-if="col.field === 'procedimentos'">
                    <span>{{ getNomesProcedimentos(funcionarioSlotProps.data) }}</span>
                </template>
                <template v-else-if="col.field === 'data'">
                    <CalendarioPrestadorContext :prestadorId="funcionarioSlotProps?.data?.prestador?.id" v-slot="{ disabledDays }">
                        <Calendar
                            :max-date="getMaxDateNaoAtendido()"
                            v-model="funcionarioSlotProps.data.data"
                            :disabled="!funcionarioSlotProps.data.prestador && !funcionarioSlotProps.data.exameParticular"
                            :disabledDays="disabledDays"
                            :class="{ 'p-invalid': configFuncionario?.submitted && !funcionarioSlotProps.data.data }"
                        />
                    </CalendarioPrestadorContext>
                </template>
                <template v-else-if="col.field === 'hora'">
                    <span class="flex justify-content-between gap-3">
                        <InputMask
                            style="width: 50%"
                            mask="99:99"
                            v-model="funcionarioSlotProps.data.hora"
                            :disabled="!funcionarioSlotProps.data.prestador"
                            :class="{
                                'p-invalid':
                                    configFuncionario?.submitted && !funcionarioSlotProps.data.hora && !funcionarioSlotProps.data.exameParticular
                            }"
                        />
                        <span class="flex align-items-center gap-2">
                            <Checkbox
                                :id="`particular${funcionarioSlotProps.index}`"
                                v-model="funcionarioSlotProps.data.exameParticular"
                                binary
                                @input="(value) => handleParticular(value, funcionarioSlotProps.index)"
                            />
                            <label :for="`particular${funcionarioSlotProps.index}`"> Particular</label>
                        </span>
                    </span>
                </template>
                <template v-else>
                    <span>{{ funcionarioSlotProps.data[col.field] }}</span>
                </template>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import { computed, defineProps } from 'vue';
import { getClientBase } from '../../../../../../../services/http';
import dayjs from 'dayjs';
import DropdownPrestadorCliente from '../../../../../../prestadores/components/DropdownPrestadorCliente.vue';
import { tabelaReservaExamesPrestadorColumns } from '../TabelaReserva';
import CalendarioPrestadorContext from '../../../../../../../components/prestador/calendario-prestador-context/CalendarioPrestadorContext.vue';

const props = defineProps({
    configFuncionario: {
        type: Object,
        required: true
    },
    configFuncionariosOriginal: {
        type: Object
    },
    agendamento: {
        type: Object,
        required: true
    }
});
function getNomesProcedimentos(procedimentos) {
    const procedimentosArray = Array.isArray(procedimentos) ? procedimentos : [procedimentos];
    return procedimentosArray.map(({ name }) => name).join('; ');
}
async function atualizarValorAntecedencia(exameNaoAtendido, index) {
    exameNaoAtendido.data = undefined;
    exameNaoAtendido.hora = undefined;
    if (!exameNaoAtendido?.prestador) {
        exameNaoAtendido.antecedencia = props.configFuncionariosOriginal[props.configFuncionarioIndex].examesNaoAtendidos[index].antecedencia;
        return;
    }
    const { data } = await getClientBase().get(
        `prestador-exames/find-by-prestador-e-procedimento/${exameNaoAtendido.prestador.id}/${exameNaoAtendido.id}`
    );
    exameNaoAtendido.antecedencia = data.diasAntecedencia;
}
const maxDate = computed(() => {
    return new Date(props.agendamento?.intervaloSugerido?.[1]);
});
function getMaxDateNaoAtendido() {
    const dataClinico = props.configFuncionario?.examesAtendidos?.at(-1)?.data;
    if (!dataClinico) return maxDate.value;
    return dayjs(dataClinico).toDate();
}

function handleParticular(particularValue, index) {
    if (particularValue) {
        delete props.agendamento.configFuncionarios.value[props.configFuncionarioIndex].examesNaoAtendidos[index].prestador;
        delete props.agendamento.configFuncionarios.value[props.configFuncionarioIndex].examesNaoAtendidos[index].hora;
    }
}

const examesPrestadorColumns = tabelaReservaExamesPrestadorColumns;
</script>
