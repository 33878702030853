<template>
    <AppMultiselect
        :class="$attrs.class"
        :style="$attrs.style"
        :disabled="disabled"
        v-model="value"
        optionLabel="name"
        dataKey="id"
        placeholder="Selecione o exame..."
        :service="$service"
        @change="change"
        ref="appMultiselect"
    >
    </AppMultiselect>
    <Dialog
        v-if="dialogObservacaoProcedimento.show"
        v-model:visible="dialogObservacaoProcedimento.show"
        :closable="false"
        :modal="true"
        class="p-fluid"
        header="Atenção!"
        :style="{ width: '20vw' }"
    >
        <div class="flex justify-content-center flex-wrap">
            <div class="p-5">
                <i class="pi pi-info-circle mr-2" style="font-size: 2rem" />
                <span style="font-size: 14px"> {{ dialogObservacaoProcedimento.mensagem }} </span>
            </div>
        </div>
        <template #footer>
            <Button
                label="Estou ciente"
                icon="pi pi-check"
                @click="escondeObservacaoAgendamentoConsulta"
                class="p-button-outlined p-button-success"
            />
        </template>
    </Dialog>
</template>
<script>
import BaseService from '../../../services/BaseService';
export default {
    props: {
        modelValue: {
            type: Object || Array
        },
        itemSize: {
            type: Number,
            default: 20
        },
        change: {
            type: Function
        },
        habilitaDialogObservacao: {
            type: Boolean || undefined
        },
        disabled: {
            type: Boolean || undefined
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null,
            dialogObservacaoProcedimento: {
                show: false,
                mensagem: null,
                dropdownHideFx: () => {}
            }
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(examesProcedimentos) {
                const isAdicionandoProcedimento = examesProcedimentos.length > this.modelValue.length;
                if (isAdicionandoProcedimento) {
                    const adicionado = examesProcedimentos[examesProcedimentos.length - 1];
                    this.verificarObservacaoAgendamentoConsulta(adicionado);
                }
                this.$emit('update:modelValue', examesProcedimentos);
            }
        }
    },
    methods: {
        mantemDropdownAberto() {
            if (this.$refs?.appMultiselect?.$refs?.primeMultiselect) {
                this.dialogObservacaoProcedimento.dropdownHideFx = this.$refs?.appMultiselect?.$refs?.primeMultiselect.hide;
                this.$refs.appMultiselect.$refs.primeMultiselect.hide = () => {};
            }
        },
        restauraDropdown() {
            setTimeout(() => {
                if (this.$refs?.appMultiselect?.$refs?.primeMultiselect) {
                    this.$refs.appMultiselect.$refs.primeMultiselect.hide = this.dialogObservacaoProcedimento.dropdownHideFx;
                }
            }, 100);
        },
        verificarObservacaoAgendamentoConsulta(exameProcedimento) {
            if (exameProcedimento?.observacaoAgendamentoConsulta && this.habilitaDialogObservacao) {
                this.dialogObservacaoProcedimento.mensagem = exameProcedimento?.observacaoAgendamentoConsulta;
                this.mantemDropdownAberto();
                this.dialogObservacaoProcedimento.show = true;
            }
        },
        escondeObservacaoAgendamentoConsulta() {
            this.dialogObservacaoProcedimento.show = false;
            this.restauraDropdown();
        }
    },
    async created() {
        this.$service = new BaseService('/procedures');
    }
};
</script>
