import AreaService from '@/services/AreaMedicaService';
import { showError, showSuccess } from '@/utils/Toast';
import { getClientBase } from '@/services/http';

export default class PreSolicitacaoService {
    constructor(toast) {
        this.$toast = toast;
        this.$service = new AreaService('/pre-solicitacoes');
    }

    async save(form, funcionario) {
        const preSolicitacao = {};

        preSolicitacao.quemAgenda = form.quemAgenda;
        preSolicitacao.quemAvisa = form.quemAvisa;
        preSolicitacao.nomeSolicitante = form.nome ?? form.nomeSolicitante;
        preSolicitacao.procedimentos = funcionario.procedimentos;
        preSolicitacao.riscos = funcionario.riscos;
        preSolicitacao.nomeFuncionario = funcionario.name;
        preSolicitacao.funcionario = funcionario;
        preSolicitacao.cliente = funcionario.customerId;
        preSolicitacao.status = { id: 1 };
        preSolicitacao.pcd = funcionario.pcd;
        preSolicitacao.resideForaDoPais = !!form.resideForaDoPais;
        preSolicitacao.telefoneFuncionario = funcionario.phone;
        preSolicitacao.emailFuncionario = funcionario.email;
        preSolicitacao.dataSugestaoInicial = form.intervaloSugerido[0];
        preSolicitacao.dataSugestaoFinal = form.intervaloSugerido[1];
        preSolicitacao.tipoExame = form.tipoExame;
        preSolicitacao.prestador = form.prestador;
        preSolicitacao.cidade = form.cidade;
        preSolicitacao.emailSolicitante = form.email ?? form.emailSolicitante;
        preSolicitacao.telefoneSolicitante = form.telefone ?? form.telefoneSolicitante;
        preSolicitacao.periodo = form.periodo;
        preSolicitacao.dataHomologacao = funcionario.dataHomologacao;
        preSolicitacao.detalheConsulta = funcionario.detalheConsulta;
        preSolicitacao.dataLiberacao = funcionario.dataLiberacao;
        preSolicitacao.tipoRetorno = funcionario.tipoRetorno;
        preSolicitacao.questionarioRisco = funcionario.questionarioRisco;
        preSolicitacao.questionarioHierarquiaId = funcionario.questionarioHierarquiaId;
        preSolicitacao.informacoesPcd = JSON.stringify(funcionario.informacoesDeficienciasPcd);
        preSolicitacao.horarioMarcadoPrestador = form.horarioMarcado;
        preSolicitacao.observacao = form.observacao;
        preSolicitacao.asoEmDia = funcionario?.asoEmDia;
        preSolicitacao.telefoneSolicitante = preSolicitacao.telefoneSolicitante
            ? preSolicitacao.telefoneSolicitante.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;
        preSolicitacao.telefoneFuncionario = preSolicitacao.telefoneFuncionario
            ? preSolicitacao.telefoneFuncionario.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;

        try {
            const { data } = await this.$service.save(preSolicitacao);

            if (funcionario?.anexos && funcionario?.anexos.length) {
                const anexosAppendUpload = funcionario?.anexos.map((anexo) => {
                    const formData = new FormData();
                    formData.append('file', anexo.arquivo);
                    formData.append('origem', 'PRE_SOLICITACAO');
                    formData.append('origemId', data.id);
                    formData.append('tipo', anexo.tipo);
                    return formData;
                });
                for (const anexo of anexosAppendUpload) {
                    await getClientBase().post('/anexo', anexo);
                }
            }

            showSuccess(this.$toast, `Pré-solicitação do colaborador ${funcionario.name} salva com sucesso`);

            if (data.erroSoc) {
                showError(this.$toast, data.erroSoc, `Problemas ao sincronizar procedimentos do colaborador ${funcionario.name} com o SOC!`);
            }

            return true;
        } catch (error) {
            showError(this.$toast, error, `Erro ao salvar pré solicitação do colaborador ${funcionario.name}`);

            return false;
        }
    }
}
