<template>
    <div class="p-fluid align-content-center align-items-center">
        <div class="flex flex-column justify-content-center row-gap-2">
            <MultiselectQuestionarioRisco
                v-model="questionarioRisco"
                :class="{ 'p-invalid': v$.$invalid && v$.$dirty }"
                :modelValue="questionarioRisco"
                @update:modelValue="setFuncionarioRisco"
                class="w-full"
            />
            <small v-if="v$.$invalid && v$.$dirty" class="p-error">
                {{ v$.required?.$message }}
            </small>
        </div>
    </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import MultiselectQuestionarioRisco from '@/pages/hierarquias/components/MultiSelectQuestionarioRisco.vue';
import { defineProps, defineEmits, ref, defineExpose } from 'vue';

const emit = defineEmits(['update']);

const props = defineProps({
    funcionarioId: {
        type: Number,
        required: true
    }
});

const questionarioRisco = ref(null);
const rules = {
    required: helpers.withMessage('O questionário deve ser informado', required),
    $lazy: true
};
const v$ = useVuelidate(rules, questionarioRisco);

function setFuncionarioRisco() {
    emit('update', { funcionarioId: props.funcionarioId, questionarioRisco });
}

defineExpose({
    async validate() {
        return await v$.value.$validate();
    }
});
</script>
